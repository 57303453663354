import { WithRouterProps } from '../../../Components/Common/withRouter'
import { ScheduledCourseItem, TAssignment } from '../../../sharedTypes'

export interface AssignmentsListProps extends WithRouterProps {
  schedule: ScheduledCourseItem
  start: string | null
  entityName: string
  code: string
  fetchAssignmentsList: boolean
}

export interface SelectedAssignment {
  data: TAssignment | null
  type: ASSIGNMENT_ACTIONS
}

export enum ASSIGNMENT_ACTIONS {
  VIEW = 'view',
  DELETE = 'delete',
  VIEW_CERTIFICATE = 'certificate',
  OVERRIDE = 'override',
  COURSE_OVERRIDE = 'courseOverride',
  EDIT = 'edit',
  UPLOAD_TEST = 'uploadTest',
  TEST_PREVIEW = 'testPreview',
  GENERATE_TEST = 'generateTest',
}
