import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col } from 'reactstrap'
import _ from 'lodash'

import {
  ASSIGNMENT_STATUS,
  COURSE_TYPES,
  CourseTypesOptions,
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
  TDepartment,
  TGrouped,
} from '../../../sharedTypes'
import {
  getAgencies,
  getDepartments,
  getOnlineCourses,
  getPositions,
} from '../../../helpers/api_helper'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { FilterOption } from '../../../sharedTypes'
import { handleError } from '../../../helpers/toast_helper'
import Flatpickr from 'react-flatpickr'
import { FILTERS, SelectedFilters } from './types'
import { formatRange } from '../../UserManagment/Filters'
import { useAppSelector } from '../../../hooks/redux'
import { Option } from '../../../helpers/facility'
import FacilitiesMultiSelectDropdown from '../../../Components/Common/FacilitiesMultiSelectDropdown'
import { useNavigate } from 'react-router-dom'
import { useUrlParams } from '../../../utils/urlParamsParser'
import AgenciesMultiSelectDropdown from '../../../Components/Common/AgenciesMultiSelectDropdown'
import { TAgency } from '../../../sharedTypes/models/agency'

export interface IFilters {
  facility: number[]
  agencies: number[]
  group: number[]
  position: number[]
  department: number[]
  course: number[]
  completedDate: Date[]
  status: ASSIGNMENT_STATUS[]
  courseTypes: COURSE_TYPES[]
}

interface FiltersProps {
  setFilters: (filter: IFilters) => void
  visible: boolean
  setSelectedFilters: (filters: SelectedFilters) => void
  filterBy: {
    status: boolean
    courseType: boolean
  }
}

const AssignmentOptions = Object.keys(ASSIGNMENT_STATUS)
  .filter(
    key =>
      ASSIGNMENT_STATUS[key as keyof typeof ASSIGNMENT_STATUS] !==
      ASSIGNMENT_STATUS.EXPIRED,
  )
  .map(key => ({
    value: ASSIGNMENT_STATUS[key as keyof typeof ASSIGNMENT_STATUS],
    label: ASSIGNMENT_STATUS[key as keyof typeof ASSIGNMENT_STATUS],
  }))

const Filters = ({
  visible,
  setFilters,
  setSelectedFilters,
  filterBy,
}: FiltersProps) => {
  const { groupOptions, selectedFacilityId, selectedGroupId } = useAppSelector(
    state => state.FacilityOptions,
  )
  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))
  const {
    updateUrlParams,
    getUrlParamsAsArray,
    getDataUrlParamsAsArray,
    getFacilityUrlParamsAsArray,
  } = useUrlParams()

  const selectedValue = useMemo(() => {
    if (user?.groupId && !selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(
        option => option.value === `group:${user?.groupId}`,
      )
    }

    if (!selectedFacilityId && !selectedGroupId) {
      return groupOptions.filter(option => option.level === 0)
    }

    if (selectedFacilityId) {
      return groupOptions.filter(
        option => option.value === `facility:${selectedFacilityId}`,
      )
    }

    return groupOptions.filter(
      option => option.value === `group:${selectedGroupId}`,
    )
  }, [selectedFacilityId, selectedGroupId, groupOptions, user])

  const navigate = useNavigate()
  const [positions, setPositions] = useState<TGrouped[]>([])
  const [departments, setDepartments] = useState<TGrouped[]>([])
  const [courses, setCourses] = useState<TGrouped[]>([])

  const [selectedOptions, setSelectedOptions] =
    useState<Option[]>(selectedValue)
  const [selectedPositions, setSelectedPositions] = useState<string[]>([])
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([])
  const [selectedCourses, setSelectedCourses] = useState<string[]>([])
  const [selectedDates, setSelectedDates] = useState<Date[]>([])
  const [facility, setFacility] = useState<string[]>([])
  const [selectedCourseTypes, setSelectedCourseTypes] = useState<
    COURSE_TYPES[]
  >([])
  const datePickerRef = useRef<Flatpickr>(null)
  const [selectedStatuses, setSelectedStatuses] = useState<ASSIGNMENT_STATUS[]>(
    [],
  )
  const [selectedAgencyOptions, setSelectedAgencyOptions] = useState<Option[]>(
    [],
  )
  const [agencyOptions, setAgencyOptions] = useState<Option[]>([])

  useEffect(() => {
    getPositions()
      .then(res => {
        const grouped: TGrouped[] = _.map(
          _.groupBy(res.data.positions, 'name'),
          (group: TDepartment[], key) => {
            return {
              name: key,
              values: group.map(g => g.id),
            }
          },
        )
        setPositions(grouped)
      })
      .catch(e => {
        handleError(e)
      })

    getDepartments({})
      .then(res => {
        const grouped: TGrouped[] = _.map(
          _.groupBy(res.data.departments, 'name'),
          (group: TDepartment[], key) => {
            return {
              name: key,
              values: group.map(g => g.id),
            }
          },
        )
        setDepartments(grouped)
      })
      .catch(e => {
        handleError(e)
      })

    getAgencies({}).then(res => {
      setAgencyOptions([
        {
          id: -2,
          label: 'In House',
          value: '2',
          level: 0,
          hasChildren: false,
          disabled: false,
          companyId: user?.companyId as number,
        },
        ...res.data.agencies.map(agency => ({
          id: agency.id,
          label: agency.name,
          value: agency.name,
          level: 1,
          hasChildren: false,
          disabled: true,
          companyId: user?.companyId as number,
        })),
      ])
    })

    getOnlineCourses({
      page: 1,
      limit: 500,
      statuses: [ONLINE_COURSE_STATUS.PUBLISHED],
    })
      .then(res => {
        const grouped: TGrouped[] = _.map(
          _.groupBy(res.courses, 'translations[0].content.name'),
          (group: OnlineCourseItem[], key) => {
            return {
              name: key,
              values: group.map(g => g.id),
            }
          },
        )
        setCourses(grouped)
        updateFilters()
      })
      .catch(e => {
        handleError(e)
      })
  }, [])

  const updateFilters = () => {
    setSelectedStatuses(getUrlParamsAsArray<ASSIGNMENT_STATUS>(FILTERS.STATUS))
    setSelectedPositions(getUrlParamsAsArray<string>(FILTERS.POSITION))
    setSelectedDepartments(getUrlParamsAsArray<string>(FILTERS.DEPARTMENT))
    setSelectedCourses(getUrlParamsAsArray<string>(FILTERS.COURSE))
    setFacility(getFacilityUrlParamsAsArray(FILTERS.FACILITY))
    setSelectedDates(getDataUrlParamsAsArray(FILTERS.COMPLETED_DATE))
  }

  const handleStatusChange = (options: FilterOption[]) => {
    const statuses = options.map(option => option.label) as ASSIGNMENT_STATUS[]
    setSelectedStatuses(statuses)
    navigate(`?${updateUrlParams('status', statuses.toString())}`)
  }

  const handleDataChange = (dates: Date[]) => {
    if (dates.length === 2) {
      setSelectedDates(dates)
    }
    navigate(
      `?${updateUrlParams(
        FILTERS.COMPLETED_DATE,
        String(dates[0]).split(' ').slice(0, 4).join('-') +
          ' ' +
          String(dates[1]).split(' ').slice(0, 4).join('-'),
      )}`,
    )
  }

  const onSelectGroupItem = (
    name: 'department' | 'position' | 'course',
    groupNames: string[],
  ) => {
    switch (name) {
      case 'position':
        setSelectedPositions(groupNames)
        navigate(`?${updateUrlParams(FILTERS.POSITION, groupNames.toString())}`)
        break
      case 'department':
        setSelectedDepartments(groupNames)
        navigate(
          `?${updateUrlParams(FILTERS.DEPARTMENT, groupNames.toString())}`,
        )
        break
      case 'course':
        setSelectedCourses(groupNames)
        navigate(`?${updateUrlParams(FILTERS.COURSE, groupNames.toString())}`)
        break
    }
  }

  useEffect(() => {
    const _selectedOptions =
      facility.length !== 0
        ? facility.map(option => {
            const [type, id] = option.split(':')
            return { type, id: +id }
          })
        : selectedOptions.map(option => {
            const [type, id] = option.value.split(':')
            return { type, id: +id }
          })

    const _selectedAgencyOptions = selectedAgencyOptions
      .filter(option => option.id !== -1)
      .map(option => {
        return { id: option.id ? +option.id : 0, label: option.label }
      })

    setFilters({
      position: _.flatten(
        positions
          .filter(p => selectedPositions.includes(p.name))
          .map(p => p.values),
      ),
      department: _.flatten(
        departments
          .filter(d => selectedDepartments.includes(d.name))
          .map(d => d.values),
      ),
      course: _.flatten(
        courses
          .filter(c => selectedCourses.includes(c.name))
          .map(c => c.values),
      ),
      agencies: _selectedAgencyOptions.map(option => option.id),
      completedDate: selectedDates,
      status: selectedStatuses,
      courseTypes: selectedCourseTypes,
      facility: _selectedOptions
        .filter(option => option.type === 'facility')
        .map(option => option.id),
      group: _selectedOptions
        .filter(option => option.type === 'group')
        .map(option => option.id),
    })

    setSelectedFilters({
      positions: selectedPositions,
      departments: selectedDepartments,
      courses: selectedCourses,
    })
  }, [
    selectedDepartments,
    selectedPositions,
    selectedCourses,
    selectedDates,
    selectedStatuses,
    selectedCourseTypes,
    selectedOptions,
    selectedAgencyOptions,
  ])

  if (!visible) {
    return null
  }

  return (
    <>
      {(!user?.isFacilityAdmin || user?.hasAccessToMultipleFacilities) && (
        <Col className='filter-col mt-0 p-0'>
          <FacilitiesMultiSelectDropdown
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            updateUrlParams={updateUrlParams}
          />
        </Col>
      )}

      {!user?.agencyId && (
        <Col className='filter-col mt-0 p-0'>
          <AgenciesMultiSelectDropdown
            selectedAgencyOptions={selectedAgencyOptions}
            setSelectedAgencyOptions={setSelectedAgencyOptions}
            agencyOptions={agencyOptions}
            updateUrlParams={updateUrlParams}
          />
        </Col>
      )}

      {positions.length > 0 && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Position
            </label>

            <MultiSelect
              name='position'
              id='position'
              isMulti
              options={positions.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select position'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'position',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={positions
                .filter(obj => selectedPositions.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      {departments.length > 0 && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='department' className='form-label'>
              Filter by Department
            </label>

            <MultiSelect
              name='department'
              id='department'
              isMulti
              options={departments.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select department'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'department',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={departments
                .filter(obj => selectedDepartments.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      {courses.length > 0 && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Course
            </label>

            <MultiSelect
              name='course'
              id='course'
              isMulti
              options={courses.map(obj => ({
                value: obj.values,
                label: obj.name,
              }))}
              isSearchable={true}
              placeholder='Select course'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                onSelectGroupItem(
                  'course',
                  selectedOptions.map(option => option.label) as string[],
                )
              }}
              defaultValue={courses
                .filter(obj => selectedCourses.includes(obj.name))
                .map(obj => ({
                  value: obj.values,
                  label: obj.name,
                }))}
            />
          </div>
        </Col>
      )}

      <Col className='filter-col mt-0 p-0'>
        <div className='w-100 filter-by-date'>
          <label htmlFor='type' className='form-label'>
            Filter by Date Completed
          </label>

          <div className='form-icon right'>
            <Flatpickr
              className='form-control fs-11'
              placeholder='--/--/---- - --/--/---'
              onChange={handleDataChange}
              ref={datePickerRef}
              value={selectedDates}
              options={{
                mode: 'range',
                dateFormat: 'm/d/Y',
                locale: {
                  rangeSeparator: ' - ',
                },
                formatDate: formatRange(selectedDates || []),
              }}
            />
            <i
              className='ri-calendar-2-line fs-20 text-primary input-button'
              onClick={() => {
                datePickerRef.current?.flatpickr.open()
              }}
            ></i>
            <i
              className='ri-close-line fs-16 text-danger'
              onClick={() => {
                datePickerRef.current?.flatpickr.clear()
                setSelectedDates([])
              }}
            ></i>
          </div>
        </div>
      </Col>

      {filterBy.status && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='type' className='form-label'>
              Course status
            </label>

            <MultiSelect
              name='courseStatus'
              id='courseStatus'
              isMulti
              options={AssignmentOptions}
              isSearchable={true}
              placeholder='Select status'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={handleStatusChange}
              defaultValue={Object.values(ASSIGNMENT_STATUS)
                .filter(courseStatus => selectedStatuses.includes(courseStatus))
                .map(courseStatus => ({
                  value: courseStatus,
                  label: courseStatus,
                }))}
            />
          </div>
        </Col>
      )}

      {filterBy.courseType && (
        <Col className='filter-col mt-0 p-0'>
          <div>
            <label htmlFor='type' className='form-label'>
              Filter by Type
            </label>

            <MultiSelect
              name='type'
              id='type'
              isMulti
              options={CourseTypesOptions}
              isSearchable={true}
              placeholder='Select type'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                setSelectedCourseTypes(
                  _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as COURSE_TYPES[]),
                )
              }}
              defaultValue={selectedCourseTypes.map(type => ({
                value: type,
                label: type,
              }))}
            />
          </div>
        </Col>
      )}
    </>
  )
}

export default Filters
