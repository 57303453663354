import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Form,
  Row,
} from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'
import { useSearchParams } from 'react-router-dom'

import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { logInUser, setAuthorization } from '../../helpers/api_helper'
import { setLoginUser } from '../../slices/auth/user/reducer'
import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import axios from 'axios'
import { CREDENTIALS_TYPE } from '../../sharedTypes'
import NotAdminBlock from '../../Components/Common/NotAdminBlock'

type LockScreenProps = WithRouterProps

const LockScreen = (props: LockScreenProps) => {
  document.title =
    'Lock Screen |  Mastered - Premium Admin & Dashboard Platform'
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const user = useAppSelector(state => state.User.user)
  const [passwordShow, setPasswordShow] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string>('')
  const emailParam = searchParams.get('email')
  const email = emailParam ? decodeURIComponent(emailParam) : null

  if (!email) {
    return <Navigate to={{ pathname: '/login' }} />
  }

  const loginUser = async (password: string) => {
    setLoading(true)
    setError('')
    if (!email) {
      return props.router.navigate('/login')
    }

    const payload = {
      password,
      login: email,
    }
    try {
      const { user, access_token, permissionSet } = await logInUser(
        payload,
      ).then(res => res.data)
      const userData = {
        user,
        accessToken: access_token,
        permissions: permissionSet,
      }
      setAuthorization(access_token)
      sessionStorage.setItem('authUser', JSON.stringify(userData))
      dispatch(setLoginUser(userData))
      props.router.navigate('/')
    } catch (error: any) {
      let message = error.message
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message || message
      }
      setError(message)
    }
    setLoading(false)
  }

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: values => {
      loginUser(values.password)
    },
  })

  return (
    <React.Fragment>
      <div className='auth-page-content'>
        <div className='auth-page-wrapper'>
          <ParticlesAuth>
            <div className='auth-page-content'>
              <Container>
                <ParticlesAuthHeader marginTop='mt-0' paddingTop={'pt-3'} />

                <Row className='justify-content-center'>
                  <Col md={8} lg={6} xl={5}>
                    <Card className='mt-4'>
                      <CardBody className='p-4'>
                        <div className='text-center mt-2'>
                          <h5 className='text-primary'>Welcome back!</h5>
                          <p className='text-muted'>Log in to your account</p>
                        </div>
                        <div className='user-thumb text-center'>
                          {user?.photo && (
                            <img
                              src={user.photo}
                              className='rounded-circle img-thumbnail avatar-lg'
                              alt='thumbnail'
                            />
                          )}
                          <h5 className='font-size-15 mt-3'>
                            {user?.firstName} {user?.lastName}
                          </h5>
                        </div>
                        <Alert
                          className='alert-borderless alert-success mt-4 mx-2'
                          role='alert'
                        >
                          Your password was reset. You can login using your new
                          password.
                        </Alert>
                        {isError ? (
                          <Alert color='danger alert-borderless mx-2'>
                            {isError}
                          </Alert>
                        ) : null}
                        <div className='p-2 mt-4'>
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              form.handleSubmit()
                              return false
                            }}
                            action='#'
                          >
                            <div className='mb-3'>
                              <label
                                className='form-label'
                                htmlFor='userpassword'
                              >
                                Password
                              </label>
                              <div className='position-relative auth-pass-inputgroup mb-3'>
                                <Input
                                  name='password'
                                  value={form.values.password || ''}
                                  type={passwordShow ? 'text' : 'password'}
                                  className='form-control pe-5'
                                  placeholder='Enter password'
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  invalid={
                                    !!(
                                      form.touched.password &&
                                      form.errors.password
                                    )
                                  }
                                />
                                {form.touched.password &&
                                form.errors.password ? (
                                  <FormFeedback type='invalid'>
                                    {form.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                                  type='button'
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  id='password-addon'
                                >
                                  <i
                                    className={`${
                                      !passwordShow
                                        ? 'ri-eye-line'
                                        : 'ri-eye-off-line'
                                    } align-middle`}
                                  ></i>
                                </button>
                              </div>
                            </div>
                            {/*<div className='form-check'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id='auth-remember-check'
                              />
                              <Label
                                className='form-check-label'
                                htmlFor='auth-remember-check'
                              >
                                Remember me
                              </Label>
                            </div>*/}
                            <div className='mb-2 mt-4'>
                              <Button
                                color='primary'
                                className='w-100'
                                type='submit'
                                disabled={
                                  !(form.isValid && form.dirty) || isLoading
                                }
                              >
                                Log In
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                    <div className='mt-4 text-center'>
                      <p className='mb-0 fw-normal'>
                        Wrong account?{' '}
                        <Link
                          to='/login'
                          className='text-primary text-decoration-underline fw-normal'
                        >
                          Sign in here
                        </Link>
                      </p>
                    </div>
                    <NotAdminBlock />
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(LockScreen)
