import React, { Fragment, useMemo } from 'react'
import { Button, Col, Input, Spinner, Table } from 'reactstrap'
import _ from 'lodash'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'
import { Step2Props } from './types'
import Highlighter from 'react-highlight-words'
import ShowMoreWithPopover from '../../Common/ShowMoreWithPopover'
import Filters from './Filters'

const Columns = [
  {
    name: 'Name',
    style: { width: '55%' },
  },
  {
    name: 'Disciplines',
    style: { width: '35%' },
  },
  {
    name: 'Code',
    style: { width: '10%' },
  },
]

const AssignPackages = ({
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
  packagesData,
  isLoading,
  isValid,
  filters,
  setFilters,
  onCancel,
  onNext,
}: Step2Props) => {
  const onSelect = (packageItem: TCoursePackage): void => {
    const existingValues = values.data || []

    const itemExists = existingValues.some(item => item.id === packageItem.id)

    const updatedValues = itemExists
      ? existingValues.filter(item => item.id !== packageItem.id)
      : [...existingValues, packageItem]

    setFieldValue('packages.data', updatedValues)
  }

  const isSelectedPackage = (packageId: number) => {
    return !!(values.data || []).find(item => item.id === packageId)
  }

  const data = useMemo(() => {
    const selected = values.data?.map(item => item)
    const _data = packagesData.data

    const selectedNotInList = _.differenceBy(selected, _data, 'id')

    return [...selectedNotInList, ..._data]
  }, [packagesData, values])

  return (
    <>
      <Filters
        onFiltersChange={setFilters}
        filters={filters}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
      />

      <div className='mt-3'>
        {!isLoading ? (
          <div className='choose-courses-container rounded'>
            <Table className='align-middle table-nowrap mb-0 choose-courses'>
              <thead className='rounded'>
                <tr className='text-muted fs-14'>
                  {Columns.map(column => (
                    <th
                      scope='col'
                      className={`fw-semibold align-middle packages-header`}
                      style={column?.style}
                      key={column.name}
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((packageItem, i) => (
                  <Fragment key={i}>
                    <tr className={`fs-14 fw-light packages-item`}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <Input
                            className='form-check-input me-2 my-0 cursor-pointer'
                            type='checkbox'
                            name={`package-${packageItem.id}`}
                            id={`package-${packageItem.id}`}
                            checked={isSelectedPackage(packageItem.id)}
                            onChange={() => {
                              onSelect(packageItem)
                            }}
                          />

                          <label
                            className='fw-light mb-0 py-0 cursor-pointer'
                            htmlFor={`package-${packageItem.id}`}
                          >
                            <Highlighter
                              highlightClassName='text-highlight'
                              searchWords={[filters.key || '']}
                              highlightTag={'span'}
                              autoEscape={true}
                              textToHighlight={_.get(packageItem, 'name', '-')}
                            />
                          </label>
                        </div>
                      </td>
                      <td>
                        <ShowMoreWithPopover
                          limit={2}
                          data={packageItem.disciplines || []}
                          className='text-body'
                          id={packageItem.id}
                        />
                      </td>
                      <td className={`text-muted packages-code`}>
                        <Highlighter
                          highlightClassName='text-highlight'
                          searchWords={[filters.key || '']}
                          highlightTag={'span'}
                          autoEscape={true}
                          textToHighlight={packageItem.code}
                        />
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className='my-5 text-center'>
            <Spinner />
          </div>
        )}
      </div>

      <div className='row g-3 pt-4'>
        <Col lg={12}>
          <div className={'hstack gap-2 justify-content-between'}>
            <Button
              className='btn-soft-primary align-middle'
              color='secondary'
              onClick={onCancel}
            >
              Back
            </Button>
            <Button
              className='align-middle'
              color='primary'
              disabled={!isValid}
              onClick={onNext}
            >
              Next
            </Button>
          </div>
        </Col>
      </div>
    </>
  )
}

export default AssignPackages
