import ExportConfirmation from '../../../Components/Modals/PrimaryConfirmationModal'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { GetUserReportDTO } from '../../../sharedTypes/api/userReport'
import { getUserReport } from '../../../helpers/api/users'
import { handleError } from '../../../helpers/toast_helper'
import { EXPORT_TYPES } from '../CourseHistory/types'
import { jsPDF } from 'jspdf'
import { renderToString } from 'react-dom/server'
import ExportUserReportPdf from './ExportUserReportPdf'
import html2canvas from 'html2canvas'
import { addImageToPdf, generateXLSOrCSV } from '../../../helpers/export'
import { SelectedFilters } from './types'
import moment from 'moment'
import { generateFileName } from '../../../helpers/getExportFileName'
import { Progress } from 'reactstrap'

interface ExportUserReportProps {
  exportType: EXPORT_TYPES
  setExportType: Dispatch<SetStateAction<EXPORT_TYPES | null>>
  setExportIsLoading?: Dispatch<SetStateAction<boolean>>
  count: number
  query: GetUserReportDTO.Request
  selectedFilters: SelectedFilters
}

const ROWS_PER_PAGE = 9

const fileHeaders = [
  'ID',
  'User',
  'Facility',
  'Position',
  'Department',
  'Hire Date',
  'Registration Date',
  'Courses Not Started',
  'Courses In Progress',
  'Courses Completed in Past 12 Months',
  'Courses Overdue',
  'Status',
  'Active',
  'Registered',
]

const ExportUserReport = ({
  exportType,
  setExportType,
  setExportIsLoading,
  count,
  query,
  selectedFilters,
}: ExportUserReportProps) => {
  const [data, setData] = useState<GetUserReportDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    usersReport: [],
  })

  const [dataGetSuccess, setDataGetSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const dataToExport = useMemo(() => {
    return data.usersReport.map(row => ({
      id: row.id,
      user: `${row.lastName} ${row.firstName}`,
      facility: row.facility?.name || '',
      position: row.position?.name || '',
      department: row.departments?.[0]?.name || '',
      hireDate: row.hireDate ? moment(row.hireDate).format('MM/DD/YYYY') : '',
      signupDate: row.signupDate
        ? moment(row.signupDate).format('MM/DD/YYYY')
        : '',
      coursesNotStarted: row.reportData?.coursesNotStarted || 0,
      coursesInProgress: row.reportData?.coursesInProgress || 0,
      coursesCompleted: row.reportData?.coursesCompletedInPast12Months || 0,
      coursesOverdue: row.reportData?.coursesOverdue || 0,
      status: row.status,
      active: row.courseStatus,
      registered: row.isSignedUp ? 'Yes' : 'No',
    }))
  }, [data.usersReport])

  const getFilteredUserReport = () => {
    setIsLoading(true)
    if (setExportIsLoading) {
      setExportIsLoading(true)
    }
    const { limit, ...userReportQuery } = query
    getUserReport(userReportQuery)
      .then(res => {
        setData(res.data)
        setDataGetSuccess(true)
      })
      .catch(e => {
        handleError(e)
      })
  }

  useEffect(() => {
    if (dataGetSuccess) {
      exportData()
    }
  }, [data, dataGetSuccess])

  useEffect(() => {
    if (exportType && count <= 100) {
      getFilteredUserReport()
    }
  }, [exportType, count])

  const exportData = () => {
    if (exportType === EXPORT_TYPES.PDF) {
      generatePDF()
    } else {
      // XLS or CSV
      generateXLSOrCSV(
        exportType,
        fileHeaders,
        dataToExport,
        generateFileName('User_Report'),
      )
      setIsLoading(false)
      if (setExportIsLoading) {
        setExportIsLoading(false)
      }
      setExportType(null)
    }
  }

  const generatePDF = () => {
    const pdf = new jsPDF({
      unit: 'px',
      format: 'a4',
      orientation: 'landscape',
    })

    setProgress(0)
    const totalPages = Math.ceil(data.usersReport.length / ROWS_PER_PAGE)

    addContentToPDF(0, pdf, totalPages)
  }

  const addContentToPDF = (
    currentPageIndex: number,
    pdf: jsPDF,
    totalPages: number,
  ) => {
    const pdfContent = document.createElement('div')

    const startIndex = currentPageIndex * ROWS_PER_PAGE
    const endIndex = Math.min(
      startIndex + ROWS_PER_PAGE,
      data.usersReport.length,
    )
    const chunk = data.usersReport.slice(startIndex, endIndex)
    pdfContent.innerHTML = renderToString(
      <ExportUserReportPdf
        userReport={chunk}
        page={currentPageIndex + 1}
        // selectedOptionName={selectedOptionName as string}
        selectedFilters={{
          ...selectedFilters,
          hireDate: query.hireDate,
          signupDate: query.signupDate,
          status: query.status,
          coursesNotStarted: query.coursesNotStarted,
          coursesInProgress: query.coursesInProgress,
          coursesCompleted: query.coursesCompleted,
          coursesOverdue: query.coursesOverdue,
          registeredStatuses: query.registeredStatuses,
        }}
      />,
    )

    document.body.appendChild(pdfContent)
    requestAnimationFrame(() => {
      html2canvas(pdfContent, { scale: 1, logging: false, useCORS: true }).then(
        function (canvas) {
          addImageToPdf(canvas, pdf, currentPageIndex, 'FAST')

          const progress = Math.round(
            ((currentPageIndex + 1) / totalPages) * 100,
          )
          setProgress(progress)

          if (currentPageIndex + 1 < totalPages) {
            addContentToPDF(currentPageIndex + 1, pdf, totalPages)
          } else {
            const pdfBlob = new Blob([pdf.output('blob')], {
              type: 'application/pdf',
            })
            const pdfUrl = URL.createObjectURL(pdfBlob)
            const link = document.createElement('a')
            link.href = pdfUrl
            link.download = `${generateFileName('User_Report')}.pdf`
            link.click()

            setIsLoading(false)
            if (setExportIsLoading) {
              setExportIsLoading(false)
            }
            setExportType(null)
          }
        },
      )

      if (document.body.contains(pdfContent)) {
        document.body.removeChild(pdfContent)
      }
    })
  }

  return (
    <>
      {exportType && count > 100 && (
        <ExportConfirmation
          onConfirm={getFilteredUserReport}
          onClose={() => setExportType(null)}
          isOpen={!!exportType}
          message={`You have ${count} results in this export. Go back to filter or continue to export?`}
          title={'Warning'}
          additionalData={
            isLoading && exportType === EXPORT_TYPES.PDF ? (
              <Progress className='mt-2' value={progress} />
            ) : undefined
          }
          confirmButtonLabel={'Export'}
          cancelLabel={'Go Back'}
          icon={'ri-error-warning-line'}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default ExportUserReport
