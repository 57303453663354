import { TUser } from './user'
import { TCompanyNews } from './companyNews'

export enum AttachmentsTypesEnum {
  AUDIO = 'Audio',
  VIDEO = 'Video',
  IMAGE = 'Image',
  DOCUMENT = 'Document',
}

export enum AttachmentsAccessEnum {
  'MASTERED' = 'Mastered',
  'PRIVATE' = 'Private',
  'PUBLIC' = 'Public',
}

export const BadgeColorByAttachmentType = {
  [AttachmentsTypesEnum.VIDEO]: 'green',
  [AttachmentsTypesEnum.DOCUMENT]: 'purple',
  [AttachmentsTypesEnum.IMAGE]: 'yellow',
  [AttachmentsTypesEnum.AUDIO]: 'blue',
}

export const BadgeColorByAttachmentAccess = {
  [AttachmentsAccessEnum.PRIVATE]: 'green',
  [AttachmentsAccessEnum.MASTERED]: 'purple',
  [AttachmentsAccessEnum.PUBLIC]: 'yellow',
}

export type TAttachment = {
  id: number
  name: string
  path: string
  userId: number
  uploadedBy?: TUser
  size: number
  properties: object
  companyId: number | null
  access: AttachmentsAccessEnum
  type: AttachmentsTypesEnum
  news: TCompanyNews[] | null
  canEdit?: boolean
  canDelete?: boolean
  createdAt: string
  updatedAt: string
  mimetype: AttachmentsMimeTypeEnum
}
export enum AttachmentsMimeTypeEnum {
  IMAGE_JPG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_SVG = 'image/svg+xml',
  VIDEO_MP4 = 'video/mp4',
  AUDIO_MP3 = 'audio/mpeg',
  PDF = 'application/pdf',
  MSWORD = 'application/msword',
  PPT = 'application/vnd.ms-powerpoint',
}

export type MediaModalDetails = {
  isOpen: boolean
  mediaProperty: 'newsCover' | 'newsMedia' | 'cover'
  type: AttachmentsTypesEnum[]
  mimetype?: AttachmentsMimeTypeEnum[]
}
