import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Spinner,
} from 'reactstrap'

interface PrimaryConfirmationModalProps {
  onClose: () => void
  onConfirm: () => void
  isOpen: ModalProps['isOpen']
  message: string | JSX.Element
  title: string
  confirmButtonLabel: string
  additionalData?: string | JSX.Element
  cancelLabel?: string
  icon?: string
  isLoading?: boolean
  className?: string
}

const PrimaryConfirmationModal = ({
  onClose,
  onConfirm,
  isOpen,
  message,
  additionalData,
  title,
  confirmButtonLabel,
  cancelLabel = 'Cancel',
  icon = 'ri-external-link-line',
  isLoading = false,
  className = '',
}: PrimaryConfirmationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered
      style={{ width: 420 }}
      className={className}
    >
      <ModalHeader toggle={onClose}></ModalHeader>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div className='avatar-xl mx-auto'>
            <div className={`avatar-title rounded text-light-purple`}>
              <i className={icon} style={{ fontSize: 62 }}></i>
            </div>
          </div>
          <div>
            <h3 className='mt-4 fw-semibold'>{title}</h3>
            <span className='text-muted fs-14'>{message}</span>
            <span className='text-muted fs-14'>{additionalData}</span>
          </div>
        </div>
        <div className='hstack gap-2 justify-content-between mt-4'>
          <Button color='soft-primary' className={'w-50'} onClick={onClose}>
            {cancelLabel}
          </Button>
          <Button
            color='primary'
            className={'w-50'}
            onClick={onConfirm}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size={'sm'} /> : confirmButtonLabel}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PrimaryConfirmationModal
