import React, { useState } from 'react'

export interface ShowMoreWithPopoverProps {
  limit: number
  data: string[]
  className?: string
  id?: number
  dir?: 'horizontal' | 'vertical'
}

const ShowMoreBadgeWithPopover = ({
  limit = 5,
  data,
  dir = 'vertical',
}: ShowMoreWithPopoverProps) => {
  const [showMore, setShowMore] = useState(false)
  const handleShowMore = () => setShowMore(!showMore)
  return (
    <div className='hstack'>
      <span
        className={`text-body d-flex ${
          dir === 'vertical' ? 'flex-column' : ''
        } align-items-baseline`}
      >
        {data.slice(0, showMore ? data.length : limit).map(item => (
          <span
            className={`badge selected-course-badge text-muted fs-12 fw-normal me-1
             mt-1 text-truncate`}
            key={item}
          >
            {item}
          </span>
        ))}

        {data.length > limit && (
          <span
            className='badge selected-course-badge text-primary cursor-pointer ps-1 mt-1'
            id={'viewMore'}
            onClick={handleShowMore}
          >
            {showMore ? 'Show Less' : `+${data.length - limit} more`}
          </span>
        )}
      </span>
    </div>
  )
}

export default ShowMoreBadgeWithPopover
