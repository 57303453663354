import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Row,
} from 'reactstrap'
import React, { useEffect, useRef, useState } from 'react'
import { Option, Step3Props } from './types'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import {
  GetScheduledCourseOptionsDTO,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../../../sharedTypes'
import {
  getFacilityTourCourse,
  getScheduledCourseOptions,
} from '../../../helpers/api/courses'
import AsyncSelect from 'react-select/async'
import { handleError } from '../../../helpers/toast_helper'

const Confirmation = ({
  errors,
  values,
  setFieldValue,
  facilityId,
  isValid,
  onNext,
  isLoading,
  onCancel,
}: Step3Props) => {
  const datePickerRef = useRef<Flatpickr>(null)
  const timePickerRef = useRef<Flatpickr>(null)

  const selectDatePickerRef = useRef<Flatpickr>(null)
  const selectStartTimePickerRef = useRef<Flatpickr>(null)
  const selectEndTimePickerRef = useRef<Flatpickr>(null)

  const [addNewDate, setAddNewDate] = useState(false)
  const [scheduleOptions, setScheduleOptions] = useState<Option[]>([])

  useEffect(() => {
    setFieldValue('confirmation.facilityTourSchedule', null)
    setFieldValue('confirmation.facilityTourDate', undefined)
    setFieldValue('confirmation.facilityTourTime', undefined)

    if (facilityId) {
      getFacilityTourCourse()
        .then(facilityTourCourse => {
          if (facilityTourCourse) {
            const query: GetScheduledCourseOptionsDTO.Request = {
              facilityId,
              type: ScheduledCourseTypes.IN_PERSON,
              entityType: ScheduledEntitiesTypes.COURSE,
              daysLimit: 10,
            }

            getScheduledCourseOptions(facilityTourCourse.id, query)
              .then(({ data }) => {
                setScheduleOptions(
                  data.map(i => ({
                    value: i.id,
                    label: moment(i.date).format('dddd, MMMM D - ha'),
                  })),
                )
              })
              .catch(handleError)
          }
        })
        .catch(handleError)
    }
  }, [facilityId])

  const loadScheduleOptions = (): Promise<Option[]> => {
    return Promise.resolve(scheduleOptions)
  }

  return (
    <div className='px-3'>
      <p className='text-muted py-2'>
        Save these assignments and send them to users.
      </p>
      <Row>
        <Col sm={6}>
          <Card>
            <CardBody className='vstack gap-3'>
              <div className='d-flex gap-2 align-items-center'>
                <Input
                  className='form-check-input cursor-pointer m-0'
                  type='checkbox'
                  name={'scheduleOnboarding'}
                  id={'scheduleOnboarding'}
                  onChange={e => {
                    setFieldValue(
                      'confirmation.scheduleOnboarding',
                      e.target.checked,
                    )
                  }}
                  checked={!!values.scheduleOnboarding}
                />
                <span>
                  Schedule users to take onboarding courses in the facility
                </span>
              </div>
              <div>
                <label htmlFor='date' className='form-label'>
                  Scheduled Date
                </label>
                <div className='form-icon right'>
                  <Flatpickr
                    data-enable-time={false}
                    className={`form-control form-control-icon ${
                      errors.onboardingDate ? ' is-invalid' : ''
                    }`}
                    ref={datePickerRef}
                    id='date'
                    name='confirmation.onboardingDate'
                    placeholder='mm/dd/yyyy'
                    onChange={option => {
                      setFieldValue('confirmation.onboardingDate', option[0])
                      setFieldValue('confirmation.scheduleOnboarding', true)
                    }}
                    value={values.onboardingDate}
                    options={{
                      dateFormat: 'MM-DD-YYYY',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                      minDate: Date.now(),
                      allowInvalidPreload: true,
                    }}
                  />
                  <i
                    className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
                    onClick={() => {
                      datePickerRef.current?.flatpickr.open()
                    }}
                  ></i>
                </div>
              </div>
              <div>
                <label htmlFor='date' className='form-label'>
                  Schedule Time
                </label>
                <div className='form-icon right'>
                  <Flatpickr
                    className={`form-control form-control-icon ${
                      errors.onboardingTime ? ' is-invalid' : ''
                    }`}
                    ref={timePickerRef}
                    id='date'
                    name='confirmation.onboardingTime'
                    placeholder='--:--'
                    onChange={option => {
                      setFieldValue('confirmation.onboardingTime', option[0])
                      setFieldValue('confirmation.scheduleOnboarding', true)
                    }}
                    value={values.onboardingTime}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'h:i K',
                      minTime:
                        values.onboardingDate &&
                        moment(values.onboardingDate).isSame(
                          moment().startOf('day'),
                        )
                          ? Date.now()
                          : undefined,
                      allowInvalidPreload: true,
                    }}
                  />
                  <i
                    className='ri-time-line fs-20 text-primary cursor-pointer'
                    onClick={() => {
                      timePickerRef.current?.flatpickr.open()
                    }}
                  ></i>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={6}>
          <Card>
            <CardBody className='vstack gap-3'>
              <div>
                <div className='d-flex gap-2 align-items-center'>
                  <Input
                    className='form-check-input cursor-pointer m-0'
                    type='checkbox'
                    name={'scheduleFacilityTour'}
                    id={'scheduleFacilityTour'}
                    onChange={e => {
                      setFieldValue(
                        'confirmation.scheduleFacilityTour',
                        e.target.checked,
                      )
                    }}
                    checked={!!values.scheduleFacilityTour}
                  />
                  <span>Schedule in person facility tour</span>
                </div>
                {errors.scheduleFacilityTour ? (
                  <FormFeedback type='invalid' className='d-block'>
                    {errors.scheduleFacilityTour}
                  </FormFeedback>
                ) : null}
              </div>
              {values.scheduleFacilityTour && (
                <>
                  {scheduleOptions.length ? (
                    <div>
                      <label htmlFor='facilityTour' className='form-label'>
                        Facility tour
                      </label>
                      <AsyncSelect
                        className='select2-container'
                        key={facilityId}
                        classNamePrefix='select2-selection form-select'
                        isSearchable={false}
                        isMulti={false}
                        placeholder={'Select a facility tour'}
                        defaultOptions
                        loadOptions={loadScheduleOptions}
                        onChange={option => {
                          if (option) {
                            setFieldValue(
                              'confirmation.facilityTourDate',
                              moment(
                                option?.label,
                                'dddd, MMMM D - ha',
                              ).toDate(),
                            )
                            setFieldValue(
                              'confirmation.facilityTourTime',
                              undefined,
                            )
                            setFieldValue(
                              'confirmation.facilityTourSchedule',
                              option,
                            )
                            setAddNewDate(false)
                          }
                        }}
                        value={
                          values.facilityTourSchedule
                            ? { ...values.facilityTourSchedule }
                            : null
                        }
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  ) : null}

                  <div>
                    <Button
                      color='ghost-primary py-0'
                      className='text-light-purple'
                      onClick={() => {
                        setAddNewDate(true)
                      }}
                    >
                      <i className='ri-add-line me-1 fs-16'></i>
                      Add New Date
                    </Button>
                  </div>

                  {addNewDate && (
                    <>
                      <div>
                        <label htmlFor='date' className='form-label'>
                          Select Date
                        </label>
                        <div className='form-icon right'>
                          <Flatpickr
                            data-enable-time={false}
                            className={`form-control form-control-icon ${
                              errors.facilityTourDate ? ' is-invalid' : ''
                            }`}
                            ref={selectDatePickerRef}
                            id='date'
                            name='confirmation.facilityTourDate'
                            placeholder='mm/dd/yyyy'
                            onChange={option => {
                              setFieldValue(
                                'confirmation.facilityTourDate',
                                option[0],
                              )
                            }}
                            value={values.facilityTourDate}
                            options={{
                              dateFormat: 'MM-DD-YYYY',
                              formatDate: date =>
                                moment(date).format('MM/DD/YYYY'),
                              minDate: Date.now(),
                              allowInvalidPreload: true,
                            }}
                          />
                          <i
                            className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
                            onClick={() => {
                              selectDatePickerRef.current?.flatpickr.open()
                            }}
                          ></i>
                        </div>
                      </div>
                      <Row>
                        <Col className='form-icon right'>
                          <label htmlFor='date' className='form-label'>
                            Select Start Time
                          </label>
                          <div className='form-icon right'>
                            <Flatpickr
                              className={`form-control form-control-icon ${
                                errors.facilityTourStartTime
                                  ? ' is-invalid'
                                  : ''
                              }`}
                              ref={selectStartTimePickerRef}
                              id='date'
                              name='confirmation.facilityTourStartTime'
                              placeholder='--:--'
                              onChange={option => {
                                setFieldValue(
                                  'confirmation.facilityTourStartTime',
                                  option[0],
                                )
                              }}
                              value={values.facilityTourStartTime}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'h:i K',
                                minTime:
                                  values.facilityTourDate &&
                                  moment(values.facilityTourDate).isSame(
                                    moment().startOf('day'),
                                  )
                                    ? Date.now()
                                    : undefined,
                                allowInvalidPreload: true,
                              }}
                            />
                            <i
                              className='ri-time-line fs-20 text-primary cursor-pointer'
                              onClick={() => {
                                selectStartTimePickerRef.current?.flatpickr.open()
                              }}
                            ></i>
                          </div>
                        </Col>
                        <Col className='form-icon right'>
                          <label htmlFor='date' className='form-label'>
                            Select End Time
                          </label>
                          <div className='form-icon right'>
                            <Flatpickr
                              className={`form-control form-control-icon ${
                                errors.facilityTourEndTime ? ' is-invalid' : ''
                              }`}
                              ref={selectEndTimePickerRef}
                              id='date'
                              name='confirmation.facilityTourEndTime'
                              placeholder='--:--'
                              onChange={option => {
                                setFieldValue(
                                  'confirmation.facilityTourEndTime',
                                  option[0],
                                )
                              }}
                              value={values.facilityTourEndTime}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'h:i K',
                                minTime:
                                  values.facilityTourDate &&
                                  moment(values.facilityTourDate).isSame(
                                    moment().startOf('day'),
                                  )
                                    ? Date.now()
                                    : undefined,
                                allowInvalidPreload: true,
                              }}
                            />
                            <i
                              className='ri-time-line fs-20 text-primary cursor-pointer'
                              onClick={() => {
                                selectEndTimePickerRef.current?.flatpickr.open()
                              }}
                            ></i>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12} className='my-1'>
          <div className='hstack gap-2 justify-content-between'>
            <Button
              className='btn-soft-primary align-middle'
              color='secondary'
              onClick={onCancel}
            >
              Back
            </Button>
            <Button
              className='align-middle'
              color='primary'
              onClick={onNext}
              disabled={!isValid || isLoading}
            >
              Save and Send
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Confirmation
