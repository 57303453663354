import BreadCrumb from '../../Components/Common/BreadCrumb'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import { SearchInput } from '../../Components/Common/SearchInput'
import {
  CompaniesSortBy,
  CompanyManagementPermissions,
  GetCompaniesDTO,
  OrderType,
} from '../../sharedTypes'
import ColumnSortIcon from '../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../Components/Common/NoResultTableWrapper'
import { handleError } from '../../helpers/toast_helper'
import moment from 'moment'
import { Pagination } from '../../Components/Common/Pagination'
import _ from 'lodash'
import { getCompanies } from '../../helpers/api/company'
import Highlighter from 'react-highlight-words'
import { ActionsDropDown } from './ActionsDropdown'
import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { BadgeColorByStatus } from './types'
import { switchToPortal } from '../../helpers/company'
import { usePermissions } from '../../hooks/usePermissions'
import ItemsPerPageDropdown from '../../Components/Common/ItemsPerPageDropdown'
import { setSwitchedToPortal } from '../../slices/auth/user/reducer'

const Columns = [
  {
    name: 'Name',
    sortBy: CompaniesSortBy.NAME,
    style: { width: 220 },
  },
  {
    name: 'Logo',
    sortBy: CompaniesSortBy.LOGO,
  },
  {
    name: 'Status',
    sortBy: CompaniesSortBy.STATUS,
  },
  {
    name: 'Activation Date',
    sortBy: CompaniesSortBy.ACTIVATION_DATE,
  },
  {
    name: 'Number of \n Active Users',
    sortBy: CompaniesSortBy.NUM_OF_USERS,
  },
  {
    name: 'Number of \n Admins',
    sortBy: CompaniesSortBy.NUM_OF_ADMINS,
  },
  {
    name: 'Number of \n Active Facilities',
    sortBy: CompaniesSortBy.NUM_OF_FACILITIES,
  },
  {
    name: 'Actions',
  },
]

const CompanyManagement = ({ router }: WithRouterProps) => {
  document.title = 'Company Management | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  const { admin, switchedToPortal } = useAppSelector(state => ({
    admin: state.User.user,
    switchedToPortal: state.User.switchedToPortal,
  }))

  const permissions = {
    viewCompanies: usePermissions(
      CompanyManagementPermissions.VIEW_COMPANY_MANAGEMENT,
    ),
    addCompany: usePermissions(CompanyManagementPermissions.ADD_COMPANY),
    editCompany: usePermissions(CompanyManagementPermissions.EDIT_COMPANY),
    switchToPortal: usePermissions(
      CompanyManagementPermissions.SWITCH_TO_VIEW_COMPANY_PORTAL,
    ),
  }

  useEffect(() => {
    if (switchedToPortal && admin && !admin.companyId) {
      dispatch(setSwitchedToPortal(false))
    }
  }, [switchedToPortal, admin])

  const [query, setQuery] = useState<GetCompaniesDTO.Request>({
    page: 1,
    limit: 10,
    sortBy: CompaniesSortBy.NAME,
    orderBy: OrderType.ASC,
    permission: CompanyManagementPermissions.VIEW_COMPANY_MANAGEMENT,
  })

  const [data, setData] = useState<GetCompaniesDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    companies: [],
  })

  useEffect(() => {
    if (permissions.viewCompanies) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      getCompanies(_query as GetCompaniesDTO.Request)
        .then(res => {
          setData(res)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, permissions.viewCompanies])

  const handleSort = useCallback((column: CompaniesSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const columns = useMemo(() => {
    if (!permissions.editCompany && !permissions.switchToPortal) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [permissions])

  const onSwitch = (companyId: number) => {
    if (admin) {
      switchToPortal(admin, companyId, dispatch).catch(handleError)
    }
  }

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Company Management'}
            items={[
              {
                active: true,
                title: 'Company Management',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                {permissions.viewCompanies && (
                  <CardHeader>
                    <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                      <SearchInput
                        style={{ maxWidth: 400 }}
                        onChange={key => {
                          setQuery(prev => ({ ...prev, key, page: 1 }))
                        }}
                        value={query.key || ''}
                      />

                      {permissions.addCompany && (
                        <div className='d-flex flex-shrink-0 gap-3'>
                          <Button
                            color={'primary'}
                            onClick={() => {
                              router.navigate('/company-manage')
                            }}
                            className='btn btn-primary align-middle'
                          >
                            <i className='ri-add-line me-1 fs-16'></i>Add New
                            Company
                          </Button>
                        </div>
                      )}
                    </div>
                  </CardHeader>
                )}

                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-auto'>
                      <Table className='align-middle mb-0'>
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.name}
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <span className='header-name'>
                                      {column.name}
                                    </span>
                                    {!!column.sortBy && (
                                      <ColumnSortIcon<CompaniesSortBy>
                                        sortOrder={query.orderBy}
                                        sortedColumn={query.sortBy}
                                        column={column.sortBy}
                                        handleSort={handleSort}
                                      />
                                    )}{' '}
                                  </div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.companies.map((company, i) => (
                            <tr
                              key={i}
                              className='fs-14'
                              onClick={() => onSwitch(company.id)}
                            >
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  className={'text-muted cursor-pointer'}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={company.name}
                                />
                              </td>

                              <td>
                                <div>
                                  {company.logoId ? (
                                    <i className='ri-check-line text-green-500'></i>
                                  ) : (
                                    <i className='ri-close-line text-danger'></i>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    BadgeColorByStatus[company.status]
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  {company.status}
                                </span>
                              </td>
                              <td>
                                {company.activationDate
                                  ? moment(company.activationDate).format(
                                      'MM/DD/YYYY',
                                    )
                                  : '-'}
                              </td>
                              <td>{company.usersCount}</td>
                              <td>{company.adminsCount}</td>
                              <td>{company.facilitiesCount}</td>
                              {(permissions.editCompany ||
                                permissions.switchToPortal) && (
                                <td
                                  onClick={e => {
                                    e.stopPropagation()
                                  }}
                                >
                                  <span className='d-flex gap-2 text-muted'>
                                    <ActionsDropDown
                                      company={company}
                                      onEdit={() => {
                                        router.navigate(
                                          `/company-manage/${company.id}`,
                                        )
                                      }}
                                      onSwitch={() => onSwitch(company.id)}
                                      permissions={permissions}
                                    />
                                  </span>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='mx-3 my-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ?? 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={(page: number) => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default withRouter(CompanyManagement)
