import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Col, FormFeedback, Row } from 'reactstrap'
import { SearchInput } from '../../Common/SearchInput'
import { FilterOption } from '../../../sharedTypes'
import { FormValues, Step2Filters } from './types'
import MultiSelect from '../../Common/MultiSelect'
import { getCoursePositions } from '../../../helpers/api/coursePositions'
import _ from 'lodash'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik'

export interface FiltersProps {
  filters: Step2Filters
  onFiltersChange: Dispatch<SetStateAction<Step2Filters>>
  touched: FormikTouched<FormValues['packages']>
  errors: FormikErrors<FormValues['packages']>
  values: FormValues['packages']
  setFieldValue: FormikHelpers<FormValues>['setFieldValue']
  setFieldTouched: FormikHelpers<FormValues>['setFieldTouched']
}

const FiltersBlock = ({
  filters,
  onFiltersChange,
  touched,
  errors,
  values,
  setFieldValue,
  setFieldTouched,
}: FiltersProps) => {
  const [positions, setPositions] = useState<FilterOption[]>([])

  const datePickerRef = useRef<Flatpickr>(null)

  useEffect(() => {
    getCoursePositions().then(res => {
      const coursePositions = res.coursePositions.map(position => ({
        label: position.name,
        value: position.id,
      }))
      setPositions(coursePositions)
    })
  }, [])

  return (
    <div className='assign-courses mt-3'>
      <Row className='mb-3 mt-0 mx-0'>
        <Col className='mt-0 p-0' md='4'>
          <div>
            <label htmlFor='disciplines' className='form-label'>
              Disciplines
            </label>
            <MultiSelect
              name='disciplines'
              id='disciplines'
              isMulti
              options={positions}
              isSearchable={true}
              placeholder='Select disciplines'
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              hideSelectedOptions={false}
              onChange={(option: FilterOption[]) => {
                onFiltersChange(prev => ({
                  ...prev,
                  disciplineIds: _.isEmpty(option)
                    ? []
                    : (_.map(option, 'value') as number[]),
                }))
              }}
            />
          </div>
        </Col>
        <Col md='4'>
          <label htmlFor='date' className='form-label'>
            Expiration Date
          </label>
          <div
            className={`form-icon right ${
              touched.expirationDate && errors.expirationDate
                ? ' is-invalid'
                : ''
            }`}
          >
            <Flatpickr
              className={`form-control form-control-icon ${
                errors.expirationDate ? ' is-invalid' : ''
              }`}
              id='date'
              name='packages.expirationDate'
              placeholder='--/--/----'
              ref={datePickerRef}
              onChange={option => {
                setFieldValue('packages.expirationDate', option[0])
              }}
              onBlur={() => {
                setFieldTouched('packages.expirationDate', true)
              }}
              value={values.expirationDate}
              options={{
                dateFormat: 'mm/dd/yyyy',
                formatDate: date => moment(date).format('L'),
                minDate: moment().toDate(),
                allowInvalidPreload: true,
              }}
            />
            <i
              className='ri-calendar-2-line fs-20 text-primary cursor-pointer'
              onClick={() => {
                datePickerRef.current?.flatpickr.open()
              }}
            ></i>
          </div>
          {errors.expirationDate ? (
            <FormFeedback type='invalid' className='d-block'>
              {errors.expirationDate}
            </FormFeedback>
          ) : null}
        </Col>
        <Col md='4' className='mt-0 p-0'>
          <label htmlFor='disciplines' className='form-label'>
            Search
          </label>
          <SearchInput
            onChange={key => {
              onFiltersChange(prev => ({ ...prev, key, page: 1 }))
            }}
            value={filters.key}
            background={'#fff'}
          />
        </Col>
      </Row>
    </div>
  )
}

export default FiltersBlock
