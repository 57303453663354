import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'

import {
  MATCH_USER_ACCOUNT_ACTION,
  TempUserData,
  TUser,
} from '../../../../sharedTypes'
import { getHostedData, matchUserAccount } from '../../../../helpers/api/users'
import UserCardList from './UserAccountDataCard'
import {
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../../../Components/Modals/DeleteConfirmation'
import DetailsInfoModal from '../../../../Components/Modals/DetailsInfoModal'
import EditRequestModal from '../../../Authentication/UserProfile/EditRequestModal'

const MatchUserAccount = () => {
  document.title = 'Match User Accounts | Mastered - Admin & Dashboard'

  const location = useLocation()
  const navigate = useNavigate()

  const [masteredAccount, setMasteredAccount] = useState<TUser | null>(
    location.state?.user || null,
  )
  const [hostedAccount, setHostedAccount] = useState<TempUserData | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [editRequest, setEditRequest] = useState(false)
  const [confirmMatchAction, setConfirmMatchAction] =
    useState<MATCH_USER_ACCOUNT_ACTION | null>(null)
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const masteredRef = useRef<HTMLDivElement | null>(null)
  const hostedRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (
      masteredAccount &&
      hostedAccount &&
      masteredRef.current &&
      hostedRef.current &&
      (masteredRef.current.offsetHeight > 68 ||
        hostedRef.current.offsetHeight > 68)
    ) {
      const masteredHeight = masteredRef.current.offsetHeight
      const hostedHeight = hostedRef.current.offsetHeight
      setMaxHeight(Math.max(masteredHeight, hostedHeight))
    }
  }, [masteredAccount, hostedAccount, isLoading])

  useEffect(() => {
    if (masteredAccount) {
      getHostedData(masteredAccount.id)
        .then(response => {
          setHostedAccount(response.data)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [masteredAccount])

  const manageUserAccount = (action: MATCH_USER_ACCOUNT_ACTION) => {
    if (hostedAccount) {
      matchUserAccount({ action }, hostedAccount.id)
        .then(() => {
          toast(
            `The match is ${
              action === MATCH_USER_ACCOUNT_ACTION.APPROVE
                ? 'approved'
                : 'rejected '
            } successfully`,
            successToastOptions,
          )
          navigate('/user-creation-log')
        })
        .catch(handleError)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Match User Accounts'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                active: true,
                title: 'Match User Accounts',
              },
            ]}
          />
          <Row sm='12' className='pb-2'>
            <span style={{ width: '90%' }}>
              All Hosted data will be saved, and any additional phone numbers or
              email addresses entered in Mastered. If any Hosted data is
              incorrect, please complete an{' '}
              <a
                onClick={() => setEditRequest(true)}
                className='cursor-pointer'
                style={{ color: '#0039c7' }}
              >
                Edit Request
              </a>{' '}
              to update. Updates will be processed within one week. All course
              data from both accounts will be merged.
            </span>
          </Row>
          <Row sm='12'>
            <Col>
              <Card>
                <CardHeader>
                  <div className='d-flex justify-content-between align-items-center gap-3'>
                    <div className='d-flex align-items-center gap-2'>
                      <h4 className='d-flex fs-20'>Mastered Account Data</h4>
                      <DetailsInfoModal
                        id='mastered-account-data'
                        name='mastered-account-data'
                        info='Data added when manually creating the user'
                        className={'mb-2'}
                        onHover={true}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody
                  innerRef={masteredRef}
                  style={{ height: maxHeight ? `${maxHeight}px` : 'auto' }}
                >
                  {masteredAccount && !isLoading ? (
                    <UserCardList
                      mastered={true}
                      user={masteredAccount}
                      matchedUser={
                        hostedAccount
                          ? {
                              ...hostedAccount,
                              departments: hostedAccount.department
                                ? [hostedAccount.department]
                                : undefined,
                            }
                          : null
                      }
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>
                  <div className='d-flex justify-content-between align-items-center gap-3'>
                    <div className='d-flex gap-2 align-items-center'>
                      <h4 className='d-flex fs-20 text-primary'>
                        Hosted Account Data
                      </h4>
                      <DetailsInfoModal
                        id={'hosted-account-data'}
                        name='hosted-account-data'
                        info='Data imported from HR System'
                        className={'mb-2'}
                        onHover={true}
                      />
                    </div>

                    <div>
                      <span className='badge bg-primary text-white fs-14'>
                        *This data will be saved
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardBody
                  innerRef={hostedRef}
                  style={{ height: maxHeight ? `${maxHeight}px` : 'auto' }}
                >
                  {hostedAccount && !isLoading ? (
                    <UserCardList
                      user={{
                        ...hostedAccount,
                        departments: hostedAccount.department
                          ? [hostedAccount.department]
                          : undefined,
                      }}
                      matchedUser={masteredAccount}
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Col
            sm='12'
            className='settings-navigation-container w-100'
            style={{ margin: 0 }}
          >
            <div className='d-flex justify-content-between px-2'>
              <Link to='/user-creation-log'>
                <Button color='light' className='btn'>
                  Cancel
                </Button>
              </Link>
              <div className='hstack gap-3 px-2'>
                <Button
                  color='soft-danger'
                  className='btn'
                  disabled={isLoading}
                  onClick={() => {
                    setConfirmMatchAction(MATCH_USER_ACCOUNT_ACTION.REJECT)
                  }}
                >
                  Reject Match
                  <DetailsInfoModal
                    id={'reject-match'}
                    name='reject-match'
                    info='These are not the same users.'
                    onHover={true}
                  />
                </Button>
                <Button
                  color='primary'
                  className='btn'
                  disabled={isLoading}
                  onClick={() => {
                    setConfirmMatchAction(MATCH_USER_ACCOUNT_ACTION.APPROVE)
                  }}
                >
                  Approve Match
                  <DetailsInfoModal
                    id={'approve-match'}
                    name='approve-match'
                    info='These are the same users, merge data.'
                    onHover={true}
                  />
                </Button>
              </div>
            </div>
          </Col>
          <ConfirmationModal
            isOpen={!!confirmMatchAction}
            onClose={() => {
              setConfirmMatchAction(null)
            }}
            title='Warning'
            confirmIcon='ri-error-warning-line'
            confirmLabel='Yes'
            confirmBtnType='primary'
            message={`Are you sure you want to ${confirmMatchAction} this match?`}
            onDelete={() =>
              manageUserAccount(confirmMatchAction as MATCH_USER_ACCOUNT_ACTION)
            }
          />
          {masteredAccount && (
            <EditRequestModal
              onClose={() => {
                setEditRequest(false)
              }}
              isOpen={editRequest}
              userId={masteredAccount.id}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MatchUserAccount
