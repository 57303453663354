import React, { ChangeEvent, useCallback, useRef } from 'react'
import { TAttachment } from '../../sharedTypes'

const LogoUploader = ({
  file,
  onChange,
}: {
  file: string | Blob | undefined | TAttachment
  onChange: (file: File) => void
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const createPreview = (file: Blob) => URL.createObjectURL(file)

  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current?.click()
    }
  }, [])

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target

      const fileObj = input.files && input.files[0]
      if (!fileObj) {
        return
      }

      onChange(fileObj)
    },
    [],
  )
  return (
    <div
      className='rounded-circle position-relative cursor-pointer'
      style={{ border: '3px solid #CACCD8', height: '90px', width: '90px' }}
      onClick={handleClick}
    >
      <span
        className={`position-absolute ${
          file ? 'bottom-0 end-0' : 'top-50 start-50 translate-middle'
        }`}
        style={{
          background: 'transparent',
          width: 32,
          height: 32,
          borderRadius: 50,
          lineHeight: '32px',
          textAlign: 'center',
        }}
      >
        <i
          style={{ fontSize: file ? 15 : 28 }}
          className='ri-camera-fill text-muted'
        ></i>
      </span>
      <input
        id='profile-foreground-img-file-input'
        ref={inputRef}
        onChange={handleFileChange}
        type='file'
        className='d-none'
        accept='image/*'
      />
      <div
        className='d-flex rounded-circle align-items-center justify-content-center position-absolute bottom-0 end-0'
        style={{ background: '#E5EDFF', height: '28px', width: '28px' }}
      >
        <i
          className={`${file ? 'ri-pencil-line' : 'ri-add-line'} text-primary`}
        />
      </div>
      <section className='w-100 h-100 rounded-circle'>
        {file && (
          <img
            className='w-100 h-100 rounded-circle'
            src={
              (file as TAttachment).path
                ? (file as TAttachment).path
                : typeof file === 'string'
                ? file
                : createPreview(file as Blob)
            }
          />
        )}
      </section>
    </div>
  )
}

export default LogoUploader
