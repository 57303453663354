import React, { useEffect, useMemo } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { USER_VERIFICATION_STATUS } from '../../../sharedTypes'
import DetailsInfoModal from '../../../Components/Modals/DetailsInfoModal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUrlParams } from '../../../utils/urlParamsParser'
import { FILTERS } from '../../Reporting/UserReport/types'
import { v4 as uuidv4 } from 'uuid'

interface FilterTabsProps {
  navTab?: USER_VERIFICATION_STATUS
  navToggle: (tab?: USER_VERIFICATION_STATUS) => void
  style?: React.CSSProperties
}
const tabs = [
  { label: 'All', value: undefined },
  {
    label: 'Verified',
    value: USER_VERIFICATION_STATUS.VERIFIED,
    info: 'User has been matched with a user imported from the HR system.',
  },
  {
    label: 'Pending Matching',
    value: USER_VERIFICATION_STATUS.PENDING_MATCHING,
    info: 'A partial match has been identified for this user. Click the match icon next to the user to review.',
  },
  {
    label: 'No Match',
    value: USER_VERIFICATION_STATUS.NO_MATCH,
    info: 'No imported user has been identified as a match for this user.',
  },
  {
    label: 'Expired',
    value: USER_VERIFICATION_STATUS.EXPIRED,
    info: '14 days have passed since this user was created, and they still have not been matched. They will no longer have the ability to log into the app.',
  },
]

const FilterTabs = ({ navTab, navToggle, style }: FilterTabsProps) => {
  const navigate = useNavigate()
  const { updateUrlParams } = useUrlParams()
  const memoizedTabs = useMemo(() => {
    return tabs.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [])
  const [searchParams] = useSearchParams()
  const tabParam = searchParams.get('tabs')
  useEffect(() => {
    if (tabParam) {
      navToggle(tabParam as USER_VERIFICATION_STATUS)
    }
  }, [])
  return (
    <Nav className='nav-customs-bordered' style={style}>
      {memoizedTabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
              navigate(
                `?${updateUrlParams(FILTERS.TABS, tab.value ? tab.value : '')}`,
              )
            }}
          >
            {tab.label}
            <DetailsInfoModal
              info={tab.info}
              id={tab.id}
              name={'user-break-down'}
              onHover={true}
            />
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
