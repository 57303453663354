import { Input, Table, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'

import { Pagination } from './Pagination'
import ColumnSortIcon from './ColumnSortIcon'
import UserCell from './UserItemCell'
import {
  OrderType,
  TUser,
  USER_COURSE_STATUSES,
  USER_VERIFICATION_STATUS,
  UserManagementPermissions,
  UsersSearchSortBy,
  UsersSortBy,
} from '../../sharedTypes'
import { usePermissions } from '../../hooks/usePermissions'
import { BadgeColorByStatus } from '../../pages/UserManagment/AdminListing'
import UserCellWithDetails from './UserCellWithDetails'
import UserAssociations from './UserAssociations'
import { useAppSelector } from '../../hooks/redux'
import ItemsPerPageDropdown from './ItemsPerPageDropdown'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

export const BadgeColorByCourseStatus = {
  [USER_COURSE_STATUSES.ON_SCHEDULE]: 'success',
  [USER_COURSE_STATUSES.PENDING_COMPLETION]: 'warning',
  [USER_COURSE_STATUSES.NO_COURSES]: 'danger',
  [USER_COURSE_STATUSES.COMPLETE]: 'success',
}

export interface IColumn {
  title: React.ReactNode
  id: string
  tooltip?: string
  component?: React.FC<ColumnBase>
  selector?: string
  format?: (value: string) => string
  sortBy?: UsersSortBy | UsersSearchSortBy
  style?: React.HTMLProps<HTMLTableColElement>['style']
}

interface UsersTableProps {
  columns: IColumn[]
  users: TUser[]
  page: number
  totalPages: number
  totalUsers: number
  itemsPerPage: number
  limit?: number
  onLimitChange: (limit: number) => void
  globalSearch?: string
  onPageChanged: (page: number) => void
  sortOrder?: OrderType
  sortedColumn?: UsersSortBy | UsersSearchSortBy | null
  handleSort: (column: UsersSortBy | UsersSearchSortBy) => void
  id?: string
  showPagination?: boolean
  highlightSearchKey?: boolean
  multiSelect?: {
    onSelect: (user: TUser) => void
    selectedUsers: TUser[]
  }
}

interface ColumnBase {
  user: TUser
  admin: TUser | null
  globalSearch: UsersTableProps['globalSearch']
  column: IColumn
  hasPermission?: boolean
  highlightSearchKey: boolean
  multiSelect?: {
    onSelect: (user: TUser) => void
    selectedUsers: TUser[]
  }
}

export const BaseColumn: React.FC<ColumnBase> = ({
  user,
  globalSearch,
  column,
  highlightSearchKey,
}) => {
  return (
    <td>
      {column.selector && (
        <Highlighter
          highlightClassName='text-highlight'
          searchWords={[highlightSearchKey ? globalSearch || '' : '']}
          highlightTag={'span'}
          autoEscape={true}
          textToHighlight={
            column.format
              ? column.format(_.get(user, column.selector, ''))
              : _.get(user, column.selector, '')
          }
        />
      )}
    </td>
  )
}

export const UserId: React.FC<ColumnBase> = ({
  user,
  column,
  globalSearch,
  multiSelect,
  highlightSearchKey,
}) => {
  const handleRowClick = () => {
    const userProfileLink = `/user-listing/profile/${user.id}`

    if (column.id === 'user-search-id') {
      window.location.replace(userProfileLink)
    } else {
      window.open(userProfileLink, '_blank')
    }
  }

  return (
    <td
      scope='row'
      onClick={!multiSelect ? handleRowClick : undefined}
      style={{ textAlign: 'center' }}
    >
      <Highlighter
        highlightClassName='text-highlight'
        className='text-primary cursor-pointer'
        searchWords={[highlightSearchKey ? globalSearch || '' : '']}
        highlightTag={'span'}
        autoEscape={true}
        textToHighlight={user.id.toString()}
      />
    </td>
  )
}

export const UserFacility: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span style={{ whiteSpace: 'nowrap' }}>{user?.facility?.name}</span>
    </td>
  )
}
export const UserPosition: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span className='two-line-limit'>{user?.position?.name}</span>
    </td>
  )
}

export const UserCourseStatus: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span
        className={`badge badge-soft-${
          BadgeColorByCourseStatus[user.courseStatus]
        } fs-12 fw-normal text-capitalize`}
      >
        {user.courseStatus}
      </span>
    </td>
  )
}

export const CoursesCount: React.FC<ColumnBase> = ({ user, column }) => {
  return (
    <td>
      <span className={`badge badge-soft-grey fs-12 fw-normal`}>
        {_.get(user, column?.selector ?? '', '-')}
      </span>
    </td>
  )
}

export const CreationMethod: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span className={`badge badge-soft-grey fs-12 fw-normal`}>
        {user.verificationStatus ? 'Manual' : 'Hosted'}
      </span>
    </td>
  )
}

export const Status: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span
        className={`badge badge-soft-${
          user.verificationStatus === USER_VERIFICATION_STATUS.EXPIRED
            ? 'danger'
            : 'grey'
        } fs-12 fw-normal text-capitalize`}
      >
        {user.verificationStatus || 'Verified'}
      </span>
    </td>
  )
}

export const UserInfo: React.FC<ColumnBase> = ({ user, globalSearch }) => {
  return <UserCell user={user} globalSearch={globalSearch} hidePhoto={true} />
}

export const Departments: React.FC<ColumnBase> = ({ user, globalSearch }) => {
  return (
    <UserAssociations data={user.departments || []} key={globalSearch || ''} />
  )
}

export const HireOrCreationDate: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      {user.verificationStatus &&
      user.verificationStatus != USER_VERIFICATION_STATUS.VERIFIED
        ? moment(user.createdAt).format('MM/DD/YYYY')
        : user.hireDate
        ? moment(user.hireDate).format('MM/DD/YYYY')
        : ''}
    </td>
  )
}

export const UserDetails: React.FC<ColumnBase> = ({
  user,
  globalSearch,
  column,
  admin,
}) => {
  return (
    <UserCellWithDetails
      hidePhoto={window.innerWidth < 1440}
      user={user}
      globalSearch={globalSearch}
      displayLastNameFirst={column.sortBy === UsersSortBy.LASTNAME}
      displayFacility={
        !admin?.isFacilityAdmin || admin?.hasAccessToMultipleFacilities
      }
    />
  )
}

export const UserStatus: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span
        className={`badge badge-soft-${
          BadgeColorByStatus[user.status]
        } fs-12 fw-normal text-capitalize`}
      >
        {user.status}
      </span>
    </td>
  )
}
export const UserRegistered: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <span
        className={`badge badge-soft-${
          user.isSignedUp ? 'success' : 'danger'
        } fs-12 fw-normal text-capitalize`}
      >
        {user.isSignedUp ? 'Yes' : 'No'}
      </span>
    </td>
  )
}

export const ViewPortal: React.FC<ColumnBase> = ({ user }) => {
  return (
    <td>
      <UncontrolledTooltip placement='top' target={`viewPortal${user.id}`}>
        View Portal
      </UncontrolledTooltip>
      <Link to={`/user-listing/profile/${user.id}`}>
        <i className='ri-eye-line text-muted' id={`viewPortal${user.id}`} />
      </Link>
    </td>
  )
}

const UsersTable = ({
  columns,
  users,
  page,
  totalPages,
  totalUsers,
  limit,
  onLimitChange,
  itemsPerPage,
  globalSearch,
  onPageChanged,
  sortOrder,
  sortedColumn,
  handleSort,
  multiSelect,
  id,
  showPagination = true,
  highlightSearchKey = true,
}: UsersTableProps) => {
  const admin = useAppSelector(state => state.User.user)

  const hasPermission = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  const handleRowClick = (id: number) => {
    window.open(`/user-listing/profile/${id}`, '_blank')
  }

  return (
    <div className='table-card'>
      <div className='overflow-x-auto'>
        <Table className='align-middle mb-0' id={id ?? 'users-table'}>
          <thead className='table-light'>
            <tr className='text-muted fs-14'>
              {multiSelect && (
                <th
                  scope='col'
                  className='fw-light align-middle'
                  style={{ width: '3%' }}
                  key={''}
                />
              )}
              {columns.map(column => (
                <th
                  scope='col'
                  className='align-middle'
                  style={column.style}
                  key={column.id}
                >
                  {!!column.tooltip && (
                    <UncontrolledTooltip
                      placement='top'
                      target={'user-table-column' + column.id}
                      style={{ minWidth: 300 }}
                      autohide={false}
                    >
                      {column.tooltip}
                    </UncontrolledTooltip>
                  )}
                  <div
                    className='d-flex align-items-center gap-1'
                    id={'user-table-column' + column.id}
                  >
                    <span>{column.title}</span>
                    {!!column.sortBy && (
                      <ColumnSortIcon<UsersSortBy | UsersSearchSortBy>
                        sortOrder={sortOrder}
                        sortedColumn={sortedColumn}
                        column={column.sortBy}
                        handleSort={handleSort}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr
                key={user.id}
                className='fs-14'
                onClick={
                  multiSelect && hasPermission
                    ? () => handleRowClick(user.id)
                    : undefined
                }
              >
                {multiSelect && (
                  <td>
                    <div
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <Input
                        className='form-check-input cursor-pointer m-0'
                        type='checkbox'
                        name={`selectUser${user.id}`}
                        id={`selectUser${user.id}`}
                        onChange={() => {
                          multiSelect.onSelect(user)
                        }}
                        checked={
                          !!_.find(multiSelect.selectedUsers, { id: user.id })
                        }
                      />
                    </div>
                  </td>
                )}
                {columns.map(column => {
                  return column.component ? (
                    <column.component
                      column={column}
                      user={user}
                      admin={admin}
                      globalSearch={globalSearch}
                      key={user.id + column.id}
                      hasPermission={hasPermission}
                      multiSelect={multiSelect}
                      highlightSearchKey={highlightSearchKey}
                    />
                  ) : (
                    <BaseColumn
                      user={user}
                      admin={admin}
                      globalSearch={globalSearch}
                      column={column}
                      key={user.id + column.id}
                      highlightSearchKey={highlightSearchKey}
                    />
                  )
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showPagination && (
        <div className='mx-3 my-3'>
          <ItemsPerPageDropdown
            limit={itemsPerPage}
            onChange={limit => onLimitChange(limit ? +limit.value : 10)}
          />
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            totalRecords={totalUsers}
            limit={limit}
            setPage={onPageChanged}
          />
        </div>
      )}
    </div>
  )
}

export default UsersTable
