import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'
import { GetRolesPermissions, TRole } from '../../sharedTypes'
import { getRoles } from '../../helpers/api/roles'
import Select from 'react-select'
import { updateUserRole } from '../../helpers/api/users'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { toast } from 'react-toastify'

interface GrantAdminPortalAccessModalProps {
  onClose: () => void
  onAccessGranted: () => void
  isOpen: ModalProps['isOpen']
  confirmLabel?: string
  confirmIcon?: string
  userId: number
}

export interface Option {
  value: number
  label: string
}

const GrantAdminPortalAccessModal = ({
  onClose,
  onAccessGranted,
  isOpen,
  userId,
  confirmLabel = 'Grant',
  confirmIcon = 'ri-shield-keyhole-line',
}: GrantAdminPortalAccessModalProps) => {
  const [roles, setRoles] = useState<Option[]>([])
  const [selectedRole, setSelectedRole] = useState<Option>()
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  useEffect(() => {
    getRoles({
      permission: GetRolesPermissions.GRAND_ADMIN_PORTAL_ACCESS,
      createAdmin: true,
    })
      .then(res => {
        setRoles(
          res.data.map((role: TRole) => ({ label: role.name, value: role.id })),
        )
      })
      .catch(handleError)
  }, [])

  const onSubmit = () => {
    setIsDisabled(true)
    if (selectedRole?.value) {
      updateUserRole(userId, { roleId: selectedRole.value })
        .then(() => {
          toast('Admin Portal Access successfully granted', successToastOptions)
          setSelectedRole(undefined)
          onAccessGranted()
          onClose()
        })
        .catch(handleError)
    }
  }

  const onChange = (option: Option) => {
    setSelectedRole(option)
    setIsDisabled(false)
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-primary text-primary'>
              <i className={confirmIcon} style={{ fontSize: 62 }}></i>
            </div>
          </div>
          <div className='p-4'>
            <h3 className='mt-4'>Grant Admin Portal Access</h3>
            <span className='text-muted fw-light mx-3'>
              This option allows you to grant access to the administrator portal
            </span>
          </div>
        </div>

        <div>
          <label htmlFor='role' className='form-label'>
            Role *
          </label>
          <>
            <Select
              name='role'
              id='role'
              className='select2-container'
              classNamePrefix='select2-selection form-select'
              onChange={option => (option ? onChange(option) : undefined)}
              options={roles}
              value={selectedRole}
              placeholder='Select a role'
            />
          </>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-danger w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            className='w-100'
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {confirmLabel}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default GrantAdminPortalAccessModal
