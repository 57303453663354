import React, { useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks/redux'
import { getUserInitials } from '../../helpers/user'
import { usePermissions } from '../../hooks/usePermissions'
import { FacilityManagementPermissions } from '../../sharedTypes'

const ProfileDropdown = () => {
  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))

  const hasPermissionToViewFacilityDetails = usePermissions(
    FacilityManagementPermissions.VIEW_FACILITY_DETAILS,
  )

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  if (!user) {
    return null
  }

  const [facilityId, setFacilityId] = useState<number | null>(null)
  useEffect(() => {
    if (user) {
      const id = user.adminFacilities?.[0]?.id
      setFacilityId(id || null)
    }
  }, [user])

  const facilityUrl = `${window.location.origin}/facility-management/facility/${facilityId}`

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'
      >
        <DropdownToggle tag='button' type='button' className='btn'>
          <span className='d-flex align-items-center'>
            {user?.photo ? (
              <img
                src={user.photo}
                alt='Header Avatar'
                className='rounded-circle header-profile-user'
              />
            ) : (
              <div>
                <div className='avatar-title rounded-circle bg-white text-primary p-2'>
                  {getUserInitials(user)}
                </div>
              </div>
            )}
            <span className='text-start ms-xl-2'>
              <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                {user.firstName} {user.lastName}
              </span>
              <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                {user.rawPosition}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <h6 className='dropdown-header'>Welcome {user.firstName}!</h6>
          <DropdownItem className='p-0'>
            <Link to={'/profile'} className='dropdown-item'>
              <i className='ri-account-circle-fill text-muted fs-16 align-middle me-1'></i>
              <span className='align-middle'>Profile</span>
            </Link>
          </DropdownItem>
          {facilityId &&
            user?.isFacilityAdmin &&
            !user?.hasAccessToMultipleFacilities &&
            hasPermissionToViewFacilityDetails && (
              <DropdownItem className='p-0'>
                <a
                  href={facilityUrl}
                  className='dropdown-item'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='ri-external-link-line text-muted fs-16 align-middle me-1'></i>
                  <span className='align-middle'>Facility Info</span>
                </a>
              </DropdownItem>
            )}
          <DropdownItem className='p-0'>
            <Link to={'/logout'} className='dropdown-item'>
              <i className='ri-login-circle-line text-muted fs-16 align-middle me-1'></i>{' '}
              <span className='align-middle' data-key='t-logout'>
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileDropdown
