import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UserInfo from './UserInfo'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { deactivateUserById, getUserById } from '../../../helpers/api/users'
import { TUser } from '../../../sharedTypes'
import { getUserDisplayName } from '../../../helpers/user'
import DeactivateModal from '../../../Components/Modals/DeactivateModal'
import { successToastOptions, handleError } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import axios from 'axios'
import CourseHistory from './CourseHistory'
import useIsMasteredAdmin from '../../../hooks/useIsMasteredAdmin'
import { switchToPortal } from '../../../helpers/company'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'

type UserProfileProps = WithRouterProps

const UserProfile = ({ router }: UserProfileProps) => {
  document.title = 'User profile | Mastered - Admin & Dashboard'

  const [user, setUser] = useState<TUser | undefined>()
  const [showDeactivate, setShowDeactivate] = useState(false)
  const dispatch = useAppDispatch()

  const isMasteredAdmin = useIsMasteredAdmin()
  const admin = useAppSelector(state => state.User.user)

  const handleUserGet = (data: TUser) => {
    if (isMasteredAdmin && admin && data.companyId) {
      switchToPortal(
        admin,
        data.companyId,
        dispatch,
        `/user-listing/profile/${data.id}`,
      ).catch(handleError)
    } else {
      setUser(data)
    }
  }

  useEffect(() => {
    if (
      !router.params.userId ||
      Number.isNaN(+router.params.userId) ||
      typeof +router.params.userId !== 'number'
    ) {
      return router.navigate('/404')
    }

    getUserById(parseInt(router.params.userId))
      .then(res => {
        handleUserGet(res.data)
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          router.navigate('/404')
        }
      })
  }, [router.params.userId])

  const onDeactivate = () => {
    if (!user) {
      return
    }
    deactivateUserById(user?.id)
      .then(res => {
        toast(
          user.deactivated
            ? 'Successfully activated'
            : 'Successfully deactivated',
          successToastOptions,
        )
        setUser(res.data)
      })
      .catch(handleError)
      .finally(() => {
        setShowDeactivate(false)
      })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='User Profile'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                linkTo: '/user-listing',
                title: 'User Listing',
              },
              {
                active: true,
                title: getUserDisplayName(user) || 'User Profile',
              },
            ]}
          />
          {user && (
            <Row>
              <UserInfo
                user={user}
                onDeactivatePress={() => setShowDeactivate(true)}
              />
              <CourseHistory user={user} />
            </Row>
          )}
        </Container>
      </div>
      {user && (
        <DeactivateModal
          onClose={() => setShowDeactivate(v => !v)}
          isOpen={showDeactivate}
          user={user}
          onDeactivate={onDeactivate}
          isActivate={user.deactivated}
        />
      )}
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
