import ExportConfirmation from '../../../Components/Modals/PrimaryConfirmationModal'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { GetUserReportDTO } from '../../../sharedTypes/api/userReport'
import { getUserReport } from '../../../helpers/api/users'
import { handleError } from '../../../helpers/toast_helper'
import { EXPORT_TYPES } from '../CourseHistory/types'
import { jsPDF } from 'jspdf'
import { renderToString } from 'react-dom/server'
import ExportUserReportPdf from './ExportUserReportPdf'
import html2canvas from 'html2canvas'
import { addImageToPdf, generateXLSOrCSV } from '../../../helpers/export'
import { SelectedFilters } from './types'
import moment from 'moment'
import { generateFileName } from '../../../helpers/getExportFileName'
import { USER_CREATION_METHOD } from '../../../sharedTypes'

interface ExportUserReportProps {
  exportType: EXPORT_TYPES
  setExportType: Dispatch<SetStateAction<EXPORT_TYPES | null>>
  count: number
  query: GetUserReportDTO.Request
  selectedFilters: SelectedFilters
}

const ROWS_PER_PAGE = 9

const fileHeaders = [
  'ID',
  'User',
  'Facility',
  'Department',
  'Position',
  'Creation Date',
  'Creation Method',
  'Status',
  'Onboarding Date',
  'Facility Tour Date',
]

const ExportUserReport = ({
  exportType,
  setExportType,
  count,
  query,
  selectedFilters,
}: ExportUserReportProps) => {
  const [data, setData] = useState<GetUserReportDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    usersReport: [],
  })

  const [dataGetSuccess, setDataGetSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dataToExport = useMemo(() => {
    return data.usersReport.map(row => ({
      id: row.id,
      user: `${row.lastName} ${row.firstName}`,
      facility: row.facility?.name || '',
      department: row.departments?.[0]?.name || '',
      position: row.position?.name || '',
      creationDate: row.createdAt
        ? moment(row.createdAt).format('MM/DD/YYYY')
        : '',
      creationMethod: row.verificationStatus
        ? USER_CREATION_METHOD.MANUAL
        : USER_CREATION_METHOD.HOSTED,
      status: row.verificationStatus,
      onboardingDate: row.onboardingDate,
      facilityTourDate: row.facilityTourDate,
    }))
  }, [data.usersReport])

  const getFilteredUserReport = () => {
    setIsLoading(true)
    const { limit, ...userReportQuery } = query
    getUserReport(userReportQuery)
      .then(res => {
        setData(res.data)
        setDataGetSuccess(true)
      })
      .catch(e => {
        handleError(e)
      })
  }

  useEffect(() => {
    if (dataGetSuccess) {
      exportData()
    }
  }, [data, dataGetSuccess])

  useEffect(() => {
    if (exportType && count <= 100) {
      getFilteredUserReport()
    }
  }, [exportType, count])

  const exportData = () => {
    if (exportType === EXPORT_TYPES.PDF) {
      generatePDF()
    } else {
      // XLS or CSV
      generateXLSOrCSV(
        exportType,
        fileHeaders,
        dataToExport,
        generateFileName('New_Users_Report'),
      )
      setIsLoading(false)
      setExportType(null)
    }
  }

  const generatePDF = () => {
    const pdf = new jsPDF({
      unit: 'px',
      format: 'a4',
      orientation: 'landscape',
    })

    const totalPages = Math.ceil(data.usersReport.length / ROWS_PER_PAGE)

    addContentToPDF(0, pdf, totalPages)
  }

  const addContentToPDF = (
    currentPageIndex: number,
    pdf: jsPDF,
    totalPages: number,
  ) => {
    const pdfContent = document.createElement('div')

    const startIndex = currentPageIndex * ROWS_PER_PAGE
    const endIndex = Math.min(
      startIndex + ROWS_PER_PAGE,
      data.usersReport.length,
    )
    const chunk = data.usersReport.slice(startIndex, endIndex)
    pdfContent.innerHTML = renderToString(
      <ExportUserReportPdf
        userReport={chunk}
        page={currentPageIndex + 1}
        selectedFilters={{
          ...selectedFilters,
          creationDate: query.selectedDates,
          creationMethod: query.creationMethod
            ? [query.creationMethod]
            : undefined,
          status: query.verificationStatus
            ? [query.verificationStatus]
            : undefined,
          onboarding: query.onboarding ? [query.onboarding] : undefined,
          facilityTour: query.facilityTour ? [query.facilityTour] : undefined,
        }}
      />,
    )

    document.body.appendChild(pdfContent)
    html2canvas(pdfContent).then(function (canvas) {
      addImageToPdf(canvas, pdf, currentPageIndex)

      if (totalPages > 1) {
        currentPageIndex = currentPageIndex + 1
        totalPages = totalPages - 1
        addContentToPDF(currentPageIndex, pdf, totalPages)
      } else {
        const pdfBlob = new Blob([pdf.output('blob')], {
          type: 'application/pdf',
        })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = `${generateFileName('New_Users_Report')}.pdf`
        link.click()
        setIsLoading(false)
        setExportType(null)
      }
    })

    if (document.body.contains(pdfContent)) {
      document.body.removeChild(pdfContent)
    }
  }

  return (
    <>
      {exportType && count > 100 && (
        <ExportConfirmation
          onConfirm={getFilteredUserReport}
          onClose={() => setExportType(null)}
          isOpen={!!exportType}
          message={`You have ${count} results in this export. Go back to filter or continue to export?`}
          title={'Warning'}
          confirmButtonLabel={'Export'}
          cancelLabel={'Go Back'}
          icon={'ri-error-warning-line'}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default ExportUserReport
