import { API } from '../api_helper'
import {
  RAW_TRANSLATIONS,
  LESSONS_TRANSLATIONS,
  SUBMIT_COURSE_TRANSLATION,
  COURSE_TRANSLATIONS,
  SUBMIT_LESSONS_TRANSLATIONS,
} from '../url_helper'
import { TCourseRawTranslation } from '../../sharedTypes'
import {
  SubmitCourseTranslationsDto,
  SubmitLessonsTranslationsDto,
  UpdateCourseTranslationsDto,
  UpdateLessonsTranslationDto,
} from '../../sharedTypes/api/rawTranslations'

export const getTranslationsByLanguage = (
  courseId: number,
  languageId: number,
) => {
  return API.get<TCourseRawTranslation>(
    `${RAW_TRANSLATIONS}/${courseId}/${languageId}`,
  ).then(d => d.data)
}

export const getLessonsTranslationsByLanguage = (
  courseId: number,
  languageId: number,
) => {
  return API.get(`${LESSONS_TRANSLATIONS}/${courseId}/${languageId}`).then(
    d => d.data,
  )
}

export const updateCourseTranslations = (
  id: number,
  data: UpdateCourseTranslationsDto.Request,
) => {
  return API.patch<TCourseRawTranslation>(COURSE_TRANSLATIONS + `/${id}`, data)
}

export const updateLessonTranslations = (
  courseId: number,
  data: UpdateLessonsTranslationDto.Request,
) => {
  return API.patch(LESSONS_TRANSLATIONS + `/${courseId}`, data)
}

export const submitCourseTranslations = (
  id: number,
  data: SubmitCourseTranslationsDto.Request,
) => {
  return API.post<TCourseRawTranslation>(
    SUBMIT_COURSE_TRANSLATION + `/${id}`,
    data,
  )
}

export const submitLessonsTranslations = (
  data: SubmitLessonsTranslationsDto.Request,
) => {
  return API.post<TCourseRawTranslation>(SUBMIT_LESSONS_TRANSLATIONS, data)
}
