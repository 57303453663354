import React, { useEffect, useState } from 'react'
import {
  BooleanAnswerItem,
  MultiChoiceAnswerItem,
  OnlineCourseItem,
  TLanguage,
} from '../../../../sharedTypes'
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap'
import _ from 'lodash'
import { getLessons } from '../../../../helpers/api/lessons'
import { handleError } from '../../../../helpers/toast_helper'
import { IKnowledgeCheck, KnowledgeCheckTypes } from '../Lessons/types'
import { FormikProps } from 'formik'
import {
  BooleanQuestionTranslation,
  LessonTranslation,
  MultipleChoiceTranslation,
  PauseTranslation,
  ReflectionPointTranslation,
} from '../../../../sharedTypes/api/rawTranslations'

export interface ILessonsTranslationsForm {
  lessons: LessonTranslation[]
}

interface KnowledgeCheckProps {
  course: OnlineCourseItem
  activeLanguage: TLanguage
  form: FormikProps<ILessonsTranslationsForm>
}

interface LessonItem {
  title: string
  id: number
  knowledgeChecks: IKnowledgeCheck[]
}

const KnowledgeCheck = ({
  course,
  activeLanguage,
  form,
}: KnowledgeCheckProps) => {
  const [lessons, setLessons] = useState<LessonItem[]>([])

  useEffect(() => {
    getLessons({ courseId: course.id })
      .then(data => {
        const lessonsData = data.lessons.map(lesson => ({
          title: _.get(lesson, 'translations[0].content.title', ''),
          id: lesson.id,
          knowledgeChecks: lesson.knowledgeChecks,
        }))

        setLessons(lessonsData)
      })
      .catch(handleError)
  }, [])

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <p className='fs-18 fw-medium text-body mb-3'>English</p>
          </Col>
          <Col>
            <p className='fs-18 fw-medium text-body mb-3'>
              {activeLanguage.name}
            </p>
          </Col>
        </Row>
        {lessons.map((lesson, index) => (
          <div key={`lesson-${lesson.id}`} className='mb-2 py-3'>
            <Row className='mb-2'>
              <Col>
                <h4 className='text-primary border-bottom pb-2'>
                  Lesson #{index + 1} - {lesson.title}
                </h4>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col md={6} className='offset-md-6'>
                <div className='d-flex justify-content-end'>
                  <div className='form-check form-switch mb-2'>
                    <Input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id={`approve`}
                      checked={
                        form.values.lessons[index]?.rawTranslation?.title
                          ?.approved
                      }
                      onChange={e => {
                        form.setFieldValue(
                          `lessons[${index}].rawTranslation.title.approved`,
                          e.target.checked,
                        )
                      }}
                    />
                    <Label className='form-check-label' htmlFor={`approve`}>
                      Approve
                    </Label>
                  </div>
                </div>

                <div>
                  <Input
                    className='form-control'
                    name={`lessons[${index}].rawTranslation.title.value`}
                    id='title'
                    placeholder='Enter title'
                    type='text'
                    value={
                      form.values.lessons[index]?.rawTranslation?.title?.value
                    }
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </div>
              </Col>
            </Row>

            {(lesson.knowledgeChecks || []).map((knowledgeCheck, i) => (
              <div
                className='vstack gap-2 border-bottom py-2'
                key={`knowledge-check-${knowledgeCheck.id}`}
              >
                <Row>
                  <Col>
                    <p className='text-muted fs-16 mb-3'>
                      Knowledge check #{i + 1}: {knowledgeCheck.type}
                    </p>
                  </Col>
                  <Col>
                    <div className='d-flex justify-content-between'>
                      <label className='form-check-label fs-14 fw-medium'>
                        Knowledge check #{i + 1}: {knowledgeCheck.type}
                      </label>

                      <div className='form-check form-switch mb-2'>
                        <Input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id={`approve`}
                          checked={
                            form.values.lessons[index]?.knowledgeChecks[i]
                              .rawTranslation?.approved
                          }
                          onChange={e => {
                            form.setFieldValue(
                              `lessons[${index}].knowledgeChecks[${i}].rawTranslation.approved`,
                              e.target.checked,
                            )
                          }}
                        />
                        <Label className='form-check-label' htmlFor={`approve`}>
                          Approve
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>
                {[
                  KnowledgeCheckTypes.MULTIPLE_CHOICE,
                  KnowledgeCheckTypes.TRUE_FALSE,
                ].includes(knowledgeCheck.type) && (
                  <>
                    <Row>
                      <Col md={6}>
                        <div>
                          <Row className='mb-2'>
                            <Col>
                              <div className='vstack gap-3'>
                                <div>
                                  <div className='d-flex align-items-center justify-content-between'></div>
                                  <div className='hstack mb-2'>
                                    <span className='fw-medium fs-16 text-start text-body'>
                                      {_.get(
                                        knowledgeCheck,
                                        'translations[0].content.question',
                                      ) || ''}
                                    </span>
                                  </div>
                                </div>
                                <div></div>
                              </div>

                              {knowledgeCheck.type ===
                                KnowledgeCheckTypes.TRUE_FALSE && (
                                <Row className='pb-2'>
                                  <div className='vstack gap-4'>
                                    <div className='text-green-500 d-flex gap-3 align-items-center'>
                                      <CorrectAnswerIcon />
                                      <span className='fs-14'>
                                        {(
                                          _.get(
                                            knowledgeCheck,
                                            'translations[0].content.answer',
                                            { correct: false },
                                          ) as BooleanAnswerItem
                                        )?.correct
                                          ? 'True'
                                          : 'False'}
                                      </span>
                                    </div>

                                    <div className='text-danger d-flex gap-3 align-items-center'>
                                      <WrongAnswerIcon />
                                      <span className='fs-14'>
                                        {(
                                          _.get(
                                            knowledgeCheck,
                                            'translations[0].content.answer',
                                            { correct: false },
                                          ) as BooleanAnswerItem
                                        )?.correct
                                          ? 'False'
                                          : 'True'}
                                      </span>
                                    </div>
                                  </div>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col>
                        <div className='vstack gap-2'>
                          <div>
                            <Input
                              className='form-control'
                              name={`lessons[${index}].knowledgeChecks[${i}].rawTranslation.question`}
                              id='question'
                              placeholder='Enter question'
                              type='textarea'
                              rows='3'
                              value={
                                (
                                  form.values.lessons[index]?.knowledgeChecks[i]
                                    .rawTranslation as
                                    | MultipleChoiceTranslation
                                    | BooleanQuestionTranslation
                                )?.question
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {knowledgeCheck.type ===
                          KnowledgeCheckTypes.MULTIPLE_CHOICE && (
                          <div className='vstack gap-3'>
                            <div>
                              {(
                                _.get(
                                  knowledgeCheck,
                                  'translations[0].content.answers',
                                  [],
                                ) as MultiChoiceAnswerItem[]
                              ).map((answerItem, i) => (
                                <Row
                                  key={`default-answer-${i}`}
                                  className='pb-2'
                                >
                                  <Col>
                                    {answerItem.correct ? (
                                      <div className='text-green-500 d-flex gap-3 align-items-center'>
                                        <CorrectAnswerIcon />
                                        <span className='fs-14'>
                                          {answerItem.answer}
                                        </span>
                                      </div>
                                    ) : (
                                      <div className='text-danger d-flex gap-3 align-items-center'>
                                        <WrongAnswerIcon />
                                        <span className='fs-14'>
                                          {answerItem.answer}
                                        </span>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col>
                        {knowledgeCheck.type ===
                          KnowledgeCheckTypes.MULTIPLE_CHOICE && (
                          <>
                            {(
                              ((
                                form.values.lessons?.[index]?.knowledgeChecks[i]
                                  .rawTranslation as MultipleChoiceTranslation
                              )?.answers as MultiChoiceAnswerItem[]) || []
                            ).map((answerItem, aIndex) => (
                              <Row key={`answer-${aIndex}`} className='pb-2'>
                                <Col>
                                  <div className='d-flex gap-2'>
                                    <Input
                                      className='form-control'
                                      name={`lessons[${index}].knowledgeChecks[${i}].rawTranslation.answers[${aIndex}].answer`}
                                      id='answer'
                                      placeholder='Enter answer'
                                      type='text'
                                      value={
                                        (
                                          form.values.lessons[index]
                                            .knowledgeChecks[i]
                                            .rawTranslation as MultipleChoiceTranslation
                                        )?.answers[aIndex].answer
                                      }
                                      onChange={form.handleChange}
                                      onBlur={form.handleBlur}
                                    />

                                    {answerItem.correct ? (
                                      <div className='text-green-500 d-flex gap-3 align-items-center'>
                                        <div className='d-flex align-items-center h-100'>
                                          <i
                                            className='ri-check-fill fs-5 bg-green-100 border-green-500
                                 rounded fw-bold px-3 d-flex align-items-center h-100'
                                          ></i>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className='text-danger d-flex gap-3 align-items-center'>
                                        <div className='d-flex align-items-center h-100'>
                                          <i
                                            className='ri-close-line fs-5 bg-soft-danger border-danger
                                 rounded fw-bold px-3 d-flex align-items-center h-100'
                                          ></i>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-muted fs-14'>
                              Explanation
                            </span>
                          </div>
                          <div className='hstack mb-2'>
                            <span className='fw-medium fs-16 text-start text-body'>
                              {_.get(
                                knowledgeCheck,
                                'translations[0].content.explanation',
                              ) || ''}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Input
                            className='form-control'
                            name={`lessons[${index}].knowledgeChecks[${i}].rawTranslation.explanation`}
                            id='question'
                            placeholder='Enter explanation'
                            type='textarea'
                            rows='3'
                            value={
                              (
                                form.values.lessons[index]?.knowledgeChecks[i]
                                  .rawTranslation as
                                  | MultipleChoiceTranslation
                                  | BooleanQuestionTranslation
                              )?.explanation
                            }
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                {[
                  KnowledgeCheckTypes.PAUSE,
                  KnowledgeCheckTypes.REFLECTION_POINT,
                ].includes(knowledgeCheck.type) && (
                  <Row>
                    <Col>
                      <div className='vstack gap-3'>
                        <div>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='text-muted fs-14'>
                              Button Text
                            </span>
                          </div>
                          <div className='hstack mb-2'>
                            <span className='fw-medium fs-16 text-start text-body'>
                              {_.get(
                                knowledgeCheck,
                                'translations[0].content.buttonText',
                              ) || '-'}
                            </span>
                          </div>
                        </div>

                        {knowledgeCheck.type ===
                          KnowledgeCheckTypes.REFLECTION_POINT && (
                          <div>
                            <div className='d-flex align-items-center justify-content-between'>
                              <span className='text-muted fs-14'>
                                Description
                              </span>
                            </div>
                            <div className='hstack mb-2'>
                              <span className='fw-medium fs-16 text-start text-body'>
                                {_.get(
                                  knowledgeCheck,
                                  'translations[0].content.description',
                                ) || '-'}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className='vstack gap-3'>
                        <div>
                          <Input
                            className='form-control'
                            name={`lessons[${index}].knowledgeChecks[${i}].rawTranslation.buttonText`}
                            id='buttonText'
                            placeholder='Enter button text'
                            type='text'
                            value={
                              (
                                form.values.lessons[index]?.knowledgeChecks[i]
                                  .rawTranslation as
                                  | PauseTranslation
                                  | ReflectionPointTranslation
                              )?.buttonText
                            }
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                          />
                        </div>
                        {knowledgeCheck.type ===
                          KnowledgeCheckTypes.REFLECTION_POINT && (
                          <div>
                            <Input
                              className='form-control'
                              name={`lessons[${index}].knowledgeChecks[${i}].rawTranslation.description`}
                              id='question'
                              placeholder='Enter description'
                              type='textarea'
                              rows='3'
                              value={
                                (
                                  form.values.lessons[index]?.knowledgeChecks[i]
                                    .rawTranslation as ReflectionPointTranslation
                                )?.description
                              }
                              onChange={form.handleChange}
                              onBlur={form.handleBlur}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
          </div>
        ))}
      </CardBody>
    </Card>
  )
}

const CorrectAnswerIcon = () => {
  return (
    <div className='d-flex align-items-center answer-icon text-green-500'>
      <i className='ri-check-fill fs-14 bg-green-100 rounded-circle fw-bold px-1'></i>
    </div>
  )
}

const WrongAnswerIcon = () => {
  return (
    <div className='d-flex align-items-center answer-icon text-danger'>
      <i className='ri-close-line fs-14 bg-soft-danger rounded-circle fw-bold px-1'></i>
    </div>
  )
}

export default KnowledgeCheck
