import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { AccordionItem, UncontrolledAccordion } from 'reactstrap'
import _ from 'lodash'
import {
  BooleanQuestion,
  IKnowledgeCheck,
  KNOWLEDGE_CHECK_HEADERS,
  KnowledgeChecksProps,
  KnowledgeCheckTypes,
  MultipleChoice,
  ReflectionPoint,
} from './types'
import { formattedDurationTime } from '../../../../helpers/course'

const KnowledgeCheck = ({
  item,
  i,
  onDelete,
  onEdit,
}: KnowledgeChecksProps) => {
  const [knowledgeCheckData, setKnowledgeCheckData] =
    useState<IKnowledgeCheck | null>(null)

  useEffect(() => {
    setKnowledgeCheckData(getInitialValues())
  }, [item])

  const getInitialValues = () => {
    const { translations, ...restData } = item as any

    if (item.type === KnowledgeCheckTypes.TRUE_FALSE) {
      return {
        ...restData,
        timestamp: formattedDurationTime(restData.timestamp),
        data: {
          question: _.get(item, 'translations[0].content.question', ''),
          explanation: _.get(item, 'translations[0].content.explanation', ''),
          answer: _.get(item, 'translations[0].content.answer', {
            correct: false,
          }),
        },
      }
    }

    if (item.type === KnowledgeCheckTypes.REFLECTION_POINT) {
      return {
        ...restData,
        timestamp: formattedDurationTime(restData.timestamp),
        data: {
          description: _.get(item, 'translations[0].content.description', ''),
          buttonText: _.get(item, 'translations[0].content.buttonText', ''),
        },
      }
    }

    if (item.type === KnowledgeCheckTypes.MULTIPLE_CHOICE) {
      return {
        ...restData,
        timestamp: formattedDurationTime(restData.timestamp),
        data: {
          question: _.get(item, 'translations[0].content.question', ''),
          explanation: _.get(item, 'translations[0].content.explanation', ''),
          answers: _.get(item, 'translations[0].content.answers', []),
        },
      }
    }

    return {
      ...restData,
      timestamp: formattedDurationTime(restData.timestamp),
      data: {
        buttonText: _.get(item, 'translations[0].content.buttonText', ''),
      },
    }
  }

  const headerText = useMemo(() => {
    if (knowledgeCheckData) {
      if (
        [
          KnowledgeCheckTypes.MULTIPLE_CHOICE,
          KnowledgeCheckTypes.TRUE_FALSE,
        ].includes(knowledgeCheckData.type)
      ) {
        return (
          (knowledgeCheckData.data as MultipleChoice | BooleanQuestion)
            ?.question || ''
        )
      }

      if (knowledgeCheckData.type === KnowledgeCheckTypes.REFLECTION_POINT) {
        return (knowledgeCheckData.data as ReflectionPoint)?.description || ''
      }

      if (knowledgeCheckData?.type === KnowledgeCheckTypes.MULTIPLE_CHOICE) {
        return (knowledgeCheckData.data as MultipleChoice)?.question || ''
      }
    }

    return ''
  }, [knowledgeCheckData])

  return (
    <div className='mb-2'>
      <UncontrolledAccordion flush>
        <AccordionItem className='my-0'>
          <h2 className='py-0 m-0 lesson-header'>
            <div
              className={classnames('accordion-button w-100 shadow-none py-0')}
              id={`knowledge-check-heading-${i}`}
              onClick={() => {
                onEdit(knowledgeCheckData as IKnowledgeCheck)
              }}
            >
              <div className='d-flex justify-content-between w-100'>
                <div className='d-flex align-items-center w-md gap-2 py-2'>
                  <span className='knowledge-check-type rounded px-3 py-1'>
                    {_.get(
                      KNOWLEDGE_CHECK_HEADERS,
                      item.type,
                      KnowledgeCheckTypes.PAUSE,
                    )}
                  </span>
                  <span className='text-muted ms-2'>
                    {knowledgeCheckData?.timestamp}
                  </span>
                  <span className='text-muted text-truncate'>{headerText}</span>
                </div>
                <div className='d-flex align-items-center justify-content-between text-muted gap-2'>
                  <i
                    className={`ri-pencil-line text-muted fs-5 rounded cursor-pointer`}
                    onClick={() => {
                      onEdit(knowledgeCheckData as IKnowledgeCheck)
                    }}
                  />
                  <i
                    className={`ri-delete-bin-2-line text-danger fs-5 rounded cursor-pointer`}
                    onClick={e => {
                      e.stopPropagation()
                      onDelete({
                        item: knowledgeCheckData,
                        index: i,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </h2>
        </AccordionItem>
      </UncontrolledAccordion>
    </div>
  )
}

export default KnowledgeCheck
