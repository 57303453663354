import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'

interface DeleteConfirmationModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  title: string
  message: string | JSX.Element
  onDelete: () => void
  confirmLabel?: string
  confirmIcon?: string
  customImage?: JSX.Element | null
  optionalContent?: JSX.Element | null
  confirmBtnType?: string
}

const DeleteRoleModal = ({
  onClose,
  isOpen,
  title,
  message,
  onDelete,
  confirmLabel = 'Delete',
  confirmIcon = 'ri-delete-bin-2-line',
  customImage = null,
  optionalContent = null,
  confirmBtnType = 'danger',
}: DeleteConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              {customImage ?? (
                <i className={confirmIcon} style={{ fontSize: 62 }}></i>
              )}
            </div>
          </div>

          <h3 className='mt-4'>{title}</h3>
          <span className='text-muted fw-light'>{message}</span>
          {optionalContent && <div className='mt-3'>{optionalContent}</div>}
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button color={confirmBtnType} className='w-100' onClick={onDelete}>
            {confirmLabel}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteRoleModal
