import { Col, Row } from 'reactstrap'
import _ from 'lodash-es'
import React from 'react'
import { Step2PassedProps } from './types'
import ShowMoreBadgeWithPopover from '../../Common/ShowMoreBadgeWithPopover'

const Step2Passed = ({ packages }: Step2PassedProps) => {
  return (
    <div className='me-1'>
      <Row className='pt-3 text-start bg-body rounded px-3'>
        {!_.isEmpty(packages) && (
          <Col sm={12}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>Courses: </span>
              <span className='text-muted fs-14 d-flex align-items-baseline'>
                <ShowMoreBadgeWithPopover
                  limit={5}
                  data={packages.map(item => item.name)}
                  dir='horizontal'
                />
              </span>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Step2Passed
