import React, { useEffect, useMemo, useState } from 'react'
import { TCourseRawTranslation } from '../../../../sharedTypes'
import AdditionalCourseInfo from './AdditionalCourseInfo'
import { Button, Col, Row } from 'reactstrap'
import GeneralInformation from './GeneralInformation'
import {
  getLessonsTranslationsByLanguage,
  getTranslationsByLanguage,
  submitCourseTranslations,
  updateLessonTranslations,
  updateCourseTranslations,
  submitLessonsTranslations,
} from '../../../../helpers/api/translations'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { useFormik } from 'formik'
import CompetencyTest from './CompetencyTest'
import { toast } from 'react-toastify'
import Preview from './Preview'
import UnsavedDataModal from '../../../../Components/Course/UnsavedDataModal'
import { useNavigate } from 'react-router-dom'
import {
  ALL_FIELDS_APPROVED_MESSAGE,
  GeneralInformationProps,
  IForm,
  propertiesToRender,
  TRANSLATION_TABS,
} from './types'
import _ from 'lodash'
import KnowledgeCheck, { ILessonsTranslationsForm } from './KnowledgeCheck'
import { TLesson } from '../../../../sharedTypes/api/lessons'
import {
  courseTranslationsSchema,
  lessonsTranslationsSchema,
} from '../../../../schemas'
import axios from 'axios'
import { LessonTranslation } from '../../../../sharedTypes/api/rawTranslations'

const LanguageTabContent = ({
  course,
  activeTab,
  activeLanguage,
  isLive,
  fetchData,
  setFetchData,
  changeTabs,
  setKnowledgeCheckTabStatus,
}: GeneralInformationProps) => {
  const navigate = useNavigate()

  const [rawTranslation, setRawTranslation] =
    useState<TCourseRawTranslation | null>(null)
  const [knowledgeCheckRawTranslation, setKnowledgeCheckRawTranslation] =
    useState<LessonTranslation[]>()

  const [unsavedDataModal, setUnsavedDataModal] = useState<boolean>(false)

  const onSubmit = () => {
    if (rawTranslation && course) {
      const questions = form.values.content.questions
      const tabsWithErrors: TRANSLATION_TABS[] = []

      if (
        questions &&
        Object.values(questions).some(question => !question.approved)
      ) {
        form.setFieldError('questions', ALL_FIELDS_APPROVED_MESSAGE)
        tabsWithErrors.push(TRANSLATION_TABS.COMPETENCY_TEST)
      }

      if (
        propertiesToRender
          .filter(property => property.contentKey !== 'resources')
          .some(
            property =>
              !_.get(form.values, `content[${property.contentKey}].approved`),
          )
      ) {
        tabsWithErrors.push(TRANSLATION_TABS.GENERAL_INFORMATION)
      }

      if (!_.isEmpty(tabsWithErrors)) {
        toast(ALL_FIELDS_APPROVED_MESSAGE, errorToastOptions)
        changeTabs(tabsWithErrors)
        return
      }

      submitCourseTranslations(rawTranslation.id as number, {
        ...form.values.content,
        courseId: course.id,
      })
        .then(() => {
          toast('Success! Translation has been submitted!', successToastOptions)
          changeTabs([])
          setFetchData(true)
        })
        .catch(e => {
          if (axios.isAxiosError(e) && e.response?.status === 400) {
            toast('All text must have a translation in order to submit')
          } else {
            handleError(e)
          }
        })
    }
  }

  const onLessonsTranslationSubmit = () => {
    const { lessons } = lessonsTranslationsForm.values
    const tabsWithErrors: TRANSLATION_TABS[] = []

    if (!_.isEmpty(lessons)) {
      const allLessonsAndKnowledgeChecksApproved = lessons.every(lesson => {
        const isLessonTitleApproved = lesson.rawTranslation?.title.approved

        const areKnowledgeChecksApproved =
          _.isEmpty(lesson.knowledgeChecks) ||
          lesson.knowledgeChecks.every(
            knowledgeCheck => knowledgeCheck.rawTranslation?.approved,
          )

        return isLessonTitleApproved && areKnowledgeChecksApproved
      })

      if (!allLessonsAndKnowledgeChecksApproved) {
        form.setFieldError('lessons', ALL_FIELDS_APPROVED_MESSAGE)
        tabsWithErrors.push(TRANSLATION_TABS.KNOWLEDGE_CHECK)
      }
    }

    if (!_.isEmpty(tabsWithErrors)) {
      toast(ALL_FIELDS_APPROVED_MESSAGE, errorToastOptions)
      changeTabs(tabsWithErrors)
      return
    }

    if (course?.id) {
      submitLessonsTranslations({
        ...lessonsTranslationsForm.values,
        languageId: activeLanguage.id,
        courseId: course.id,
      })
        .then(() => {
          toast('Success! Translation has been submitted!', successToastOptions)
          changeTabs([])
          setKnowledgeCheckTabStatus(true)
        })
        .catch(handleError)
    }
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      content: {
        name: {
          value: '',
          approved: false,
        },
        description: {
          value: '',
          approved: false,
        },
        objective: {
          value: '',
          approved: false,
        },
        questions: {},
      },
    },
    validationSchema: courseTranslationsSchema,
    onSubmit,
  })

  const lessonsTranslationsForm = useFormik<ILessonsTranslationsForm>({
    enableReinitialize: true,
    initialValues: {
      lessons: [],
    },
    validationSchema: lessonsTranslationsSchema,
    onSubmit: onLessonsTranslationSubmit,
  })

  useEffect(() => {
    if (course && activeTab !== TRANSLATION_TABS.PREVIEW) {
      getTranslationsByLanguage(course.id, activeLanguage.id)
        .then(res => {
          setRawTranslation(res)
          form.setValues(res)
        })
        .catch(e => {
          if (e.response.status === 404) {
            navigate('/404')
          } else {
            handleError(e)
          }
        })
    }
  }, [course])

  useEffect(() => {
    if (course && activeTab === TRANSLATION_TABS.KNOWLEDGE_CHECK) {
      getLessonsTranslationsByLanguage(course.id, activeLanguage.id)
        .then(res => {
          const { translations, isLive } = res
          const lessonsTranslationsData = translations.map(
            (lesson: TLesson) => ({
              id: lesson.id,
              rawTranslation: _.get(lesson, 'rawTranslation')
                ? _.get(lesson, 'rawTranslation.content')
                : {},
              knowledgeChecks: lesson.knowledgeChecks.map(knowledgeCheck => ({
                id: knowledgeCheck.id,
                type: knowledgeCheck.type,
                rawTranslation: _.get(knowledgeCheck, 'rawTranslation')
                  ? _.get(knowledgeCheck, 'rawTranslation.content')
                  : {},
              })),
            }),
          )
          setKnowledgeCheckTabStatus(isLive as boolean)

          setKnowledgeCheckRawTranslation(lessonsTranslationsData)
          lessonsTranslationsForm.setValues({
            lessons: lessonsTranslationsData,
          })
        })
        .catch(e => {
          if (e.response.status === 404) {
            navigate('/404')
          } else {
            handleError(e)
          }
        })
    }
  }, [course?.id, activeTab])

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(rawTranslation),
    [rawTranslation, form.values],
  )

  const renderTab = () => {
    if (course) {
      switch (activeTab) {
        case TRANSLATION_TABS.GENERAL_INFORMATION:
          return (
            <GeneralInformation
              course={course}
              form={form}
              activeLanguage={activeLanguage}
            />
          )
        case TRANSLATION_TABS.COMPETENCY_TEST:
          return <CompetencyTest course={course} form={form} />
        case TRANSLATION_TABS.PREVIEW:
          return <Preview course={course} activeLanguage={activeLanguage} />
        case TRANSLATION_TABS.KNOWLEDGE_CHECK:
          return (
            <KnowledgeCheck
              course={course}
              activeLanguage={activeLanguage}
              form={lessonsTranslationsForm}
            />
          )
      }
    }
  }

  const handleSubmit = () => {
    if (activeTab === TRANSLATION_TABS.KNOWLEDGE_CHECK) {
      lessonsTranslationsForm.submitForm()
    } else {
      form.submitForm()
    }
  }

  const onSave = () => {
    if (
      rawTranslation &&
      course &&
      activeTab !== TRANSLATION_TABS.KNOWLEDGE_CHECK
    ) {
      updateCourseTranslations(rawTranslation.id as number, {
        ...form.values.content,
        courseId: course.id,
      })
        .then(() => {
          toast('Translations are successfully updated', successToastOptions)
          if (isLive) {
            setFetchData(!fetchData)
          }
        })
        .catch(handleError)
    }

    if (activeTab === TRANSLATION_TABS.KNOWLEDGE_CHECK && course?.id) {
      updateLessonTranslations(course?.id, {
        ...lessonsTranslationsForm.values,
        languageId: activeLanguage.id,
      })
        .then(() => {
          toast('Translations are successfully updated', successToastOptions)
        })
        .catch(handleError)
    }
  }

  const onClose = () => {
    if (hasUnsavedData) {
      setUnsavedDataModal(true)
    } else {
      navigateToCourseListing()
    }
  }

  const navigateToCourseListing = () => {
    navigate('/courses')
  }

  return (
    <>
      <Row>
        {course && activeTab !== TRANSLATION_TABS.KNOWLEDGE_CHECK && (
          <Col md={3}>
            <AdditionalCourseInfo course={course} />
          </Col>
        )}
        <Col>{renderTab()}</Col>
      </Row>
      <Row className='sticky-row course-navigation'>
        <div className='d-flex justify-content-between'>
          <Button color='light' onClick={onClose}>
            Close
          </Button>
          {activeTab !== TRANSLATION_TABS.PREVIEW && (
            <div className='hstack gap-3'>
              <Button
                color='ghost-primary'
                className='text-light-purple'
                onClick={onSave}
              >
                Save
              </Button>
              {!isLive && (
                <Button
                  color='primary'
                  onClick={handleSubmit}
                  className='btn-label right px-3'
                >
                  Submit
                </Button>
              )}
            </div>
          )}
        </div>
      </Row>

      <UnsavedDataModal
        isOpen={unsavedDataModal}
        onClose={() => {
          setUnsavedDataModal(false)
        }}
        onConfirm={navigateToCourseListing}
        message='You have unsaved changes.  Press Cancel to remain on the page, press OK to go back to the Course listing and lose unsaved changes.'
        confirmBtnLabel='OK'
        cancelBtnLabel='Cancel'
        confirmBtnColor='primary'
      />
    </>
  )
}

export default LanguageTabContent
