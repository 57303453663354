import React, { useEffect, useRef } from 'react'
import { Input } from 'reactstrap'
import { FormikProps } from 'formik'

interface CodeFormValues {
  first: string
  second: string
  third: string
  fourth: string
}

interface CodeInputProps {
  validation: FormikProps<CodeFormValues>
}

const CodeInput: React.FC<CodeInputProps> = ({ validation }) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value.slice(0, 1)
    validation.setFieldValue(e.target.name, value)

    if (value && index < inputRefs.current.length - 1) {
      const nextInputValue =
        validation.values[inputFields[index + 1] as keyof CodeFormValues]
      if (!nextInputValue) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
      e.preventDefault()
      inputRefs.current[index - 1].focus()
    }
    if (e.key === 'ArrowLeft' && index > 0) {
      const prevInput = inputRefs.current[index - 1]
      if (prevInput) {
        prevInput.focus()
        inputRefs.current[index - 1].type = 'text'
        timeoutRef.current = setTimeout(() => {
          prevInput.setSelectionRange(
            prevInput.value.length,
            prevInput.value.length,
          )
          inputRefs.current[index - 1].type = 'number'
        }, 0)
      }
    }
    if (e.key === 'ArrowRight' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const inputFields = ['first', 'second', 'third', 'fourth']

  return (
    <>
      {inputFields.map((name, index) => (
        <Input
          key={name}
          name={name}
          className='form-control'
          style={{ width: '40px', marginRight: '5px' }}
          placeholder='0'
          type='number'
          onChange={e => handleChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          onBlur={validation.handleBlur}
          value={validation.values[name as keyof CodeFormValues] || ''}
          innerRef={el => {
            if (el) {
              inputRefs.current[index] = el as HTMLInputElement
            }
          }}
          maxLength={1}
        />
      ))}
    </>
  )
}

export default CodeInput
