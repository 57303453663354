import React, { useCallback, useState } from 'react'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { toast } from 'react-toastify'
import { createCourseRevision } from '../../helpers/api_helper'
import CourseRevisionModal from '../../Components/Modals/CourseRevisionModal'

export const useCourseRevision = (afterSubmit?: (courseId: number) => void) => {
  const [revisionCourseId, setRevisionCourseId] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const onCourseRevisionCreate = useCallback(async () => {
    if (revisionCourseId) {
      try {
        setIsLoading(true)
        setRevisionCourseId(null)
        const res = await createCourseRevision(revisionCourseId)
        toast('Success - Course revision has been created', successToastOptions)
        setIsLoading(false)
        if (afterSubmit) {
          afterSubmit(res.id)
        }
      } catch (e) {
        handleError(e)
        setIsLoading(false)
      }
    }
  }, [revisionCourseId, afterSubmit])

  const renderCourseRevisionModal = useCallback(() => {
    return (
      <CourseRevisionModal
        onClose={() => setRevisionCourseId(null)}
        isOpen={!!revisionCourseId}
        isLoading={isLoading}
        onCourseRevisionCreate={onCourseRevisionCreate}
      />
    )
  }, [revisionCourseId, onCourseRevisionCreate])

  return { renderCourseRevisionModal, setRevisionCourseId }
}
