import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
} from 'reactstrap'
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'

import withRouter, { WithRouterProps } from '../../Components/Common/withRouter'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import ParticlesAuthHeader from '../../Components/Common/ParticlesAuthHeader'
import { sendCode } from '../../helpers/api_helper'
import axios from 'axios'
import registrationVerification from '../../assets/images/img-verification.png'
import { Link } from 'react-router-dom'
import NotAdminBlock from '../../Components/Common/NotAdminBlock'

type RegisterProps = WithRouterProps

const Register = (props: RegisterProps) => {
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string>('')
  const [showEmail, setshowEmail] = useState(false)

  const sendVerification = async (login: string) => {
    setLoading(true)
    setError('')
    try {
      await sendCode(login)
      props.router.navigate(`/code?login=${login}`)
    } catch (error: any) {
      let message = error.message
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message || message
      }
      setError(message)
    }
    setLoading(false)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      phone: '',
    },

    validationSchema: Yup.object()
      .shape({
        email: Yup.string().email('Email not valid'),
        phone: Yup.string().matches(
          /^\d{10}$/,
          'Mobile number must be exactly 10 digits',
        ),
      })
      .test(
        'email-or-phone',
        'Please enter either email or phone number',
        values => {
          return !!(values.email || values.phone)
        },
      ),

    onSubmit: values =>
      sendVerification(values.email ? values.email : values.phone),
  })

  document.title = 'Register | Mastered - Premium Admin & Dashboard Platform'

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-register'>
          <Container>
            <ParticlesAuthHeader marginTop='mt-0' paddingTop={'pt-3'} />

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='d-flex align-items-center justify-content-center pt-2'>
                      <img src={registrationVerification} alt='NO IMAGE' />
                    </div>
                    <div className='text-center mt-4'>
                      <h5 className='text-primary fw-normal'>Registration</h5>
                      {showEmail ? (
                        <p className='text-muted fw-light fs-14 ff-base mb-2'>
                          Enter your email to receive a verification code
                        </p>
                      ) : (
                        <p className='text-muted fw-light fs-14 ff-base mb-2'>
                          Enter your phone number to receive a verification code
                        </p>
                      )}
                    </div>
                    {isError ? (
                      <Alert color='danger alert-borderless mx-2'>
                        {isError}
                      </Alert>
                    ) : null}
                    <div className='p-2'>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action='#'
                      >
                        {showEmail ? (
                          <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                              Your Email
                            </Label>
                            <Input
                              name='email'
                              className='form-control'
                              placeholder='Enter email'
                              type='email'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={
                                !!(
                                  validation.touched.email &&
                                  validation.errors.email
                                )
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        ) : (
                          <div className='mb-3'>
                            <Label htmlFor='email' className='form-label'>
                              Your Phone Number
                            </Label>
                            <Input
                              name='phone'
                              className='form-control'
                              placeholder='Enter phone'
                              type='text'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ''}
                              invalid={
                                !!(
                                  validation.touched.phone &&
                                  validation.errors.phone
                                )
                              }
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        )}

                        <div className='mt-4'>
                          <Button
                            color='primary'
                            disabled={
                              !(validation.isValid && validation.dirty) ||
                              isLoading
                            }
                            className='btn btn-primary w-100'
                            type='submit'
                          >
                            Send Code
                          </Button>
                        </div>
                        <div className='text-center mt-4'>
                          <a
                            className='fw-normal text-purple mb-2 cursor-pointer'
                            onClick={() => {
                              setshowEmail(prev => !prev)
                              setError('')
                            }}
                          >
                            {showEmail
                              ? 'Register with phone number'
                              : 'Register with email'}
                          </a>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className='text-center'>
                  <p className='fw-normal'>
                    Wait, I have an account... <Link to={'/login'}>Log in</Link>
                  </p>
                </div>
                <NotAdminBlock />
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Register)
