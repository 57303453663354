import React from 'react'

const ParticlesAuth = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Fragment>
      <div className='auth-page-wrapper pt-5'>
        <div
          className='auth-one-bg-position auth-one-bg'
          id='auth-particles'
        ></div>

        {/* pass the children */}
        {children}

        <footer className='footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='text-center'>
                  <p className='mb-0 text-muted'>
                    &copy; {new Date().getFullYear()} Mastered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default ParticlesAuth
