import React from 'react'
import {
  CreationMethod,
  IColumn,
  Status,
  UserDetails,
  UserId,
} from '../../../Components/Common/UsersTable'
import { UsersSortBy } from '../../../sharedTypes'
import moment from 'moment'

export const Columns: IColumn[] = [
  {
    title: 'ID',
    id: 'id',
    sortBy: UsersSortBy.ID,
    component: UserId,
    style: { width: 80 },
  },
  {
    title: 'User',
    id: 'user',
    sortBy: UsersSortBy.LASTNAME,
    component: UserDetails,
    style: { width: 220 },
  },
  {
    title: 'Facility',
    id: 'facility',
    selector: 'facility.name',
    sortBy: UsersSortBy.FACILITY_NAME,
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    title: 'Department',
    id: 'department',
    selector: 'departments.[0].name',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    title: 'Position',
    id: 'position',
    selector: 'position.name',
    sortBy: UsersSortBy.POSITION_NAME,
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    title: 'Creation Date',
    id: 'creation-date',
    sortBy: UsersSortBy.CREATION_DATE,
    selector: 'createdAt',
    style: { width: 100 },
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    component: CreationMethod,
    title: 'Creation Method',
    id: 'creation-method',
    selector: 'verificationStatus',
    sortBy: UsersSortBy.VERIFICATION_STATUS,
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    component: Status,
    title: 'Status',
    id: 'status',
    selector: 'verificationStatus',
    sortBy: UsersSortBy.VERIFICATION_STATUS,
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    title: 'Onboarding Date',
    id: 'onboarding-date',
    sortBy: UsersSortBy.ONBOARDING_DATE,
    selector: 'onboardingDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    title: 'Facility Tour Date',
    id: 'facility-tour-date',
    sortBy: UsersSortBy.FACILITY_TOUR_DATE,
    selector: 'facilityTourDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
]

export interface SelectedFilters {
  positions: string[]
  departments: string[]
  facilities?: string[]
  agencies: string[]
}

export enum FILTERS {
  CREATION_METHOD = 'creationMethod',
  CREATION_DATE = 'creationDate',
  TABS = 'tabs',
}
