import {
  AccordionItem,
  Button,
  Col,
  FormFeedback,
  Input,
  Progress,
  Row,
  UncontrolledAccordion,
  UncontrolledCollapse,
} from 'reactstrap'
import classnames from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormikHelpers, useFormik } from 'formik'
import { DocumentFile } from '../../../../sharedTypes'
import { lessonSchema } from '../../../../schemas'
import { ReactComponent as CheckinIcon } from '../../../../assets/images/svg/checkin-in.svg'
import {
  createKnowledgeCheck,
  deleteKnowledgeCheck,
  getKnowledgeCheckByLessonId,
  updateKnowledgeCheck,
  updateLesson,
} from '../../../../helpers/api/lessons'
import {
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import {
  IKnowledgeCheck,
  ILesson,
  KnowledgeCheckToDelete,
  KnowledgeCheckTypes,
  LessonProps,
  UpdateLessonDto,
} from './types'
import KnowledgeCheck from './KnowledgeCheck'
import DeleteConfirmation from '../../../../Components/Modals/DeleteConfirmation'
import VideoPlayer from './VideoPlayer'
import { CourseContext } from '../../../../context/CourseContext'
import { formattedDurationTime } from '../../../../helpers/course'
import _ from 'lodash'
import KnowledgeCheckModal from '../../../../Components/Modals/KnowledgeCheckModal'

const Lesson = ({ i, lesson, onDelete }: LessonProps) => {
  const playedRef = useRef<string | number>(0)

  const [initialValue, setInitialValue] = useState<ILesson | null>(lesson)
  const [knowledgeChecks, setKnowledgeChecks] = useState<IKnowledgeCheck[]>([])
  const [knowledgeCheckToDelete, setKnowledgeCheckToDelete] =
    useState<KnowledgeCheckToDelete>({
      item: null,
      index: 0,
    })
  const [openKnowledgeCheckModal, setOpenKnowledgeCheckModal] =
    useState<boolean>(false)
  const [knowledgeCheckData, setKnowledgeCheckData] =
    useState<IKnowledgeCheck | null>(null)

  const {
    setUploadedCourseLessonsData,
    uploadedCourseLessonsData,
    lessonProgress,
    lessonFiles,
  } = useContext(CourseContext)

  useEffect(() => {
    setKnowledgeChecks(lesson.knowledgeChecks)
  }, [lesson.knowledgeChecks])

  const onSubmit = () => {
    const dataToSend: UpdateLessonDto = {}

    if (form.values.title !== '') {
      dataToSend.title = form.values.title
    }

    if (form.values.duration) {
      dataToSend.duration = form.values.duration
    }
    if (form.values.order) {
      dataToSend.order = form.values.order
    }

    updateLesson(form.values.id as number, dataToSend)
      .then(() => {
        setInitialFormValues()
      })
      .catch(handleError)
  }

  const form = useFormik<ILesson>({
    enableReinitialize: true,
    initialValues: lesson,
    validationSchema: lessonSchema,
    onSubmit,
  })

  useEffect(() => {
    if (lesson.id) {
      const lessonFileId = lessonFiles[lesson.id]
      if (lessonFileId && !form.values.fileId) {
        handleCreatedFile({ fileId: lessonFileId })
      }
    }
  }, [lessonFiles, form.values.fileId, lesson.id])

  useEffect(() => {
    if (lesson.id) {
      const progress = lessonProgress[lesson.id]
      if (progress != form.values.progress) {
        form.setFieldValue('progress', progress)
      }
    }
  }, [lessonProgress, lesson.id])

  const disableActions = useMemo(
    () =>
      uploadedCourseLessonsData.length
        ? uploadedCourseLessonsData.some(data => data.fileId === null)
        : false,
    [uploadedCourseLessonsData],
  )

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(initialValue),
    [initialValue, form.values],
  )

  const handlePlayedChange = (played: string) => {
    playedRef.current = played
  }

  const getStyle = (style: any, snapshot: any) => {
    if (!snapshot.isDropAnimating) {
      return style
    }

    return {
      ...style,
      transitionDuration: `0.001s`,
    }
  }

  const setInitialFormValues = useCallback(() => {
    setInitialValue(form.values as ILesson)
  }, [form.values])

  const handleDuration = (duration: number) => {
    form.setFieldValue('duration', duration)
  }

  const handleCreatedFile = ({ fileId }: { fileId: number }) => {
    if (form.values.id) {
      const dataToSend: UpdateLessonDto = { fileId }

      if (form.values.duration) {
        dataToSend.duration = form.values.duration
      }

      if (form.values.title !== '') {
        dataToSend.title = form.values.title
      }

      if (_.isEmpty(dataToSend)) {
        return
      }

      updateLesson(form.values.id, dataToSend)
        .then(() => {
          toast('Lesson has been uploaded successfully', successToastOptions)
          form.setFieldValue('fileId', fileId)
          setUploadedCourseLessonsData(prev =>
            prev.map(item =>
              item.id === form.values.id ? { ...item, fileId } : item,
            ),
          )
        })
        .catch(handleError)
    }
  }

  const saveLesson = () => {
    if (!hasUnsavedData) {
      return false
    }

    form.submitForm()
  }

  const addKnowledgeCheck = () => {
    if (playedRef.current) {
      setOpenKnowledgeCheckModal(true)
      setKnowledgeCheckData({
        timestamp: playedRef.current as string,
        type: KnowledgeCheckTypes.MULTIPLE_CHOICE,
        isOpen: true,
        data: {
          buttonText: '',
        },
      })
    }
  }

  const editKnowledgeCheck = (knowledgeCheckData: IKnowledgeCheck) => {
    setOpenKnowledgeCheckModal(true)
    setKnowledgeCheckData(knowledgeCheckData)
  }

  const handleKnowledgeCheckDelete = useCallback(async () => {
    try {
      if (knowledgeCheckToDelete.item) {
        const dataToDelete = knowledgeCheckToDelete.item
        if (dataToDelete?.id) {
          await deleteKnowledgeCheck(dataToDelete.id)
        }
        toast(
          `Knowledge check has been successfully deleted!`,
          successToastOptions,
        )
        setKnowledgeCheckToDelete({
          item: null,
          index: 0,
        })

        const updatedData = [...knowledgeChecks]
        updatedData.splice(knowledgeCheckToDelete.index, 1)
        setKnowledgeChecks(updatedData)
      }
    } catch (e) {
      handleError(e)
    }
  }, [knowledgeChecks, knowledgeCheckToDelete])

  const timeToSeconds = (time: string) => {
    const [hours, minutes, seconds] = time.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds
  }

  const getKnowledgeChecks = () => {
    if (lesson.id) {
      getKnowledgeCheckByLessonId({ lessonId: lesson.id })
        .then(data => {
          setKnowledgeChecks(data)
        })
        .catch(handleError)
    }
  }

  const onKnowledgeCheckSubmit = (
    values: IKnowledgeCheck,
    form: FormikHelpers<IKnowledgeCheck>,
  ) => {
    const dataToSend = {
      ...values,
      timestamp: timeToSeconds(values.timestamp as string),
      lessonId: lesson.id as number,
    }

    if (values.id) {
      updateKnowledgeCheck(values.id as number, dataToSend)
        .then(() => {
          onSave()
        })
        .catch(handleError)
        .finally(() => {
          form.setSubmitting(false)
        })
    } else {
      createKnowledgeCheck(dataToSend)
        .then(() => {
          onSave()
        })
        .catch(handleError)
        .finally(() => {
          form.setSubmitting(false)
        })
    }
  }

  const onSave = () => {
    getKnowledgeChecks()
    setOpenKnowledgeCheckModal(false)
    setKnowledgeCheckData(null)
    toast('Knowledge check saved successfully', successToastOptions)
  }

  const _onCloseModal = () => {
    setOpenKnowledgeCheckModal(false)
    setKnowledgeCheckData(null)
  }

  return (
    <Draggable draggableId={`lesson-${i}`} index={i} key={i}>
      {(provided: any, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={getStyle(provided.draggableProps.style, snapshot)}
        >
          <div>
            <UncontrolledAccordion>
              <AccordionItem>
                <h2 className='py-0 m-0 lesson-header'>
                  <div
                    className={classnames(
                      'accordion-button w-100 shadow-none py-2',
                      {
                        collapsed: form.values.isOpen,
                      },
                    )}
                    id={`lesson-heading-${i}`}
                    onClick={() => {
                      form.setFieldValue('isOpen', !form.values.isOpen)
                    }}
                  >
                    <div className='d-flex justify-content-between w-100'>
                      <div className='d-flex align-items-center w-100 gap-2'>
                        {!disableActions && (
                          <i
                            className='ri-draggable text-muted fs-5  drag-icon'
                            {...provided.dragHandleProps}
                          ></i>
                        )}

                        <span className='lesson-number text-white rounded px-3 py-2'>
                          {i + 1}
                        </span>
                        <span className='text-body ms-2'>
                          {form.values.title}
                        </span>
                        <span>
                          {form.values.progress !== undefined &&
                            !form.values.fileId && (
                              <div className='mt-2 w-100'>
                                <Progress
                                  className='progress'
                                  color='primary'
                                  value={form.values.progress}
                                />
                              </div>
                            )}
                        </span>
                      </div>
                      <div className='d-flex align-items-center justify-content-between text-muted'>
                        <div className='d-flex align-items-center gap-2 me-2'>
                          <i
                            className={`ri-time-line text-muted fs-4 rounded`}
                          />
                          <span>
                            {formattedDurationTime(
                              form.values.duration as number,
                            )}
                          </span>
                        </div>
                        <div className='d-flex align-items-center me-4'>
                          <CheckinIcon
                            style={{ width: 38, height: 21 }}
                            className='lesson-duration'
                          />
                          <span>{knowledgeChecks.length}</span>
                        </div>
                        {!disableActions && (
                          <i
                            className={`ri-delete-bin-2-line text-muted fs-3 rounded bg-white p-2 me-2 cursor-pointer`}
                            onClick={() => {
                              onDelete(lesson)
                            }}
                          />
                        )}

                        <i
                          className={
                            'ri-arrow-down-s-line text-muted fs-3 rounded bg-white p-2 me-2 cursor-pointer'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </h2>
                <UncontrolledCollapse
                  toggler={`#lesson-heading-${i}`}
                  defaultOpen={form.values.isOpen}
                  className='accordion-content mb-2 pt-2'
                >
                  <Row
                    id={`lesson-${i}`}
                    className='accordion-collapse collapse show'
                    aria-labelledby={`lesson-heading-${i}`}
                  >
                    <Col
                      className='accordion-collapse collapse show'
                      aria-labelledby={`lesson-heading-${i}`}
                      md={3}
                    >
                      <div className='vstack gap-3'>
                        <div className='accordion-body text-body p-2 position-relative'>
                          {form.values.file && (
                            <div>
                              <VideoPlayer
                                file={form.values.file as DocumentFile}
                                handleDuration={handleDuration}
                                onPlayedChange={handlePlayedChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={9} className='vstack gap-3 pe-4'>
                      <div>
                        <label htmlFor='title' className='form-label'>
                          Title*
                        </label>
                        <Input
                          className='form-control'
                          id='title'
                          name={'title'}
                          placeholder='Enter title'
                          type='text'
                          invalid={!!form.errors.title}
                          value={form.values.title}
                          onChange={form.handleChange}
                          onBlur={e => {
                            form.handleBlur(e)
                            saveLesson()
                          }}
                        />
                        {form.touched.title && form.errors.title ? (
                          <FormFeedback type='invalid'>
                            {form.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div>
                        {knowledgeChecks.map((knowledgeCheck, i) => (
                          <KnowledgeCheck
                            item={knowledgeCheck}
                            key={i}
                            i={i}
                            onDelete={setKnowledgeCheckToDelete}
                            onEdit={editKnowledgeCheck}
                          />
                        ))}
                      </div>

                      <Button
                        color='ghost-primary'
                        disabled={form.values.title === ''}
                        className='text-light-purple w-fit-content'
                        onClick={addKnowledgeCheck}
                      >
                        <i className='ri-add-line me-1 fs-16' /> Add Knowledge
                        Check
                      </Button>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </AccordionItem>
            </UncontrolledAccordion>
          </div>
          {provided.placeholder}

          {knowledgeCheckToDelete.item && (
            <DeleteConfirmation
              isOpen={!!knowledgeCheckToDelete.item}
              onClose={() => {
                setKnowledgeCheckToDelete({
                  item: null,
                  index: 0,
                })
              }}
              title='Delete knowledge check?'
              message={`Are you sure you want to delete knowledge check?`}
              onDelete={handleKnowledgeCheckDelete}
            />
          )}

          {knowledgeCheckData && (
            <KnowledgeCheckModal
              onClose={_onCloseModal}
              isOpen={openKnowledgeCheckModal}
              data={knowledgeCheckData}
              onSubmit={onKnowledgeCheckSubmit}
            />
          )}
        </div>
      )}
    </Draggable>
  )
}

export default Lesson
