import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'

interface SearchWithDropdownProps {
  placeholder?: string
  value: string
  onChange: (item: string) => void
  onClear: () => void
  onClick: (id: number) => void
  options: { label: string; value: string; id: number }[]
  style?: React.CSSProperties
  background?: React.CSSProperties['background']
}

const SearchWithDropdown: React.FC<SearchWithDropdownProps> = ({
  placeholder,
  value,
  onChange,
  onClear,
  onClick,
  options = [],
  style,
  background = '#EBF0F8',
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget as HTMLElement).className.includes('dropdown-item')
    ) {
      return
    }
    setIsFocused(false)
  }

  const handleOptionClick = (id: number) => {
    onClick(id)
  }

  return (
    <div
      className='search-box flex-1'
      style={style}
      tabIndex={-1}
      onBlur={handleBlur}
    >
      <input
        type='text'
        className='form-control border-0 text-body fw-semibold'
        placeholder={placeholder || 'Search'}
        value={value}
        onChange={e => onChange(e.target.value)}
        autoComplete='off'
        style={{ background }}
        onFocus={handleFocus}
      />
      <i className='ri-search-2-line search-icon'></i>
      {value && <i className='ri-close-line clear-icon' onClick={onClear}></i>}

      {isFocused && options.length > 0 && (
        <div
          className='dropdown-list'
          style={{
            position: 'absolute',
            top: '110%',
            left: 0,
            right: 0,
            boxShadow: '0px 5px 10px 0px #1E20251F',
            backgroundColor: '#fff',
            maxHeight: '200px',
            borderRadius: '4px',
            overflowY: 'auto',
            zIndex: 10,
          }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className='d-flex justify-content-between align-items-center dropdown-item'
              style={{ padding: '8px 12px', cursor: 'pointer' }}
              onMouseDown={e => {
                e.preventDefault()
                handleOptionClick(option.id)
              }}
            >
              <Highlighter
                highlightClassName='text-highlight'
                searchWords={[value || '']}
                highlightTag='span'
                autoEscape={true}
                textToHighlight={option.label}
              />
              <Highlighter
                highlightClassName='text-highlight'
                searchWords={[value || '']}
                className='text-muted'
                highlightTag='span'
                autoEscape={true}
                textToHighlight={option.value}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchWithDropdown
