import React from 'react'
import { Button, Modal, ModalBody, ModalProps, Spinner } from 'reactstrap'
import { ReactComponent as CreateSvgIcon } from '../../assets/images/svg/createRevision.svg'

interface CourseActionModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onCourseRevisionCreate: () => void
  isLoading: boolean
}

const CourseRevisionModal = ({
  onClose,
  isOpen,
  onCourseRevisionCreate,
  isLoading,
}: CourseActionModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center px-4'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-primary text-primary'>
              <CreateSvgIcon />,
            </div>
          </div>

          <h3 className='mt-4'>Create Course Revision</h3>
          <span className='text-muted fw-light'>
            {
              'This course will be cloned and put into Draft mode \n for editing.'
            }
          </span>
        </div>

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-danger w-100'
            onClick={onClose}
            active={false}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            className='w-100'
            onClick={() => onCourseRevisionCreate()}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size={'sm'} /> : 'Create'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CourseRevisionModal
