import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalProps, Table } from 'reactstrap'

import {
  addCoursesToFTag,
  getFTagCourses,
  getOnlineCourses,
} from '../../helpers/api_helper'

import { handleError, successToastOptions } from '../../helpers/toast_helper'
import SearchWithDropdown from '../Common/SearchWithDropDown'
import {
  GetOnlineCoursesDTO,
  ONLINE_COURSE_STATUS,
  OnlineCourseItem,
} from '../../sharedTypes'
import { toast } from 'react-toastify'

interface CourseFTagModalProps {
  onClose: () => void
  onSuccess: () => void
  isOpen: ModalProps['isOpen']
  title: string
  initialValues:
    | {
        ftagId: number | undefined
        courseId: number | undefined
      }
    | undefined
}

const Columns = [
  {
    name: 'Course Name',
    style: { width: '60%' },
  },
  {
    name: 'Code',
    style: { width: '35%' },
  },
  {
    name: '',
  },
]

export type FTagModalForm = {
  id: number
  name: string
  code: string
}

const CourseFTagModal = ({
  onClose,
  onSuccess,
  isOpen,
  title,
  initialValues,
}: CourseFTagModalProps) => {
  const [options, setOptions] = useState<OnlineCourseItem[]>([])
  const [selectedCourses, setSelectedCourses] = useState<OnlineCourseItem[]>([])
  const [fetchData, setFetchData] = useState<boolean>(false)

  const [query, setQuery] = useState<GetOnlineCoursesDTO.Request>({
    page: 1,
    statuses: [ONLINE_COURSE_STATUS.PUBLISHED],
  })

  useEffect(() => {
    getOnlineCourses(query).then(res => setOptions(res.courses))
  }, [query])

  useEffect(() => {
    if (initialValues?.ftagId) {
      getFTagCourses(initialValues?.ftagId)
        .then(res => setSelectedCourses(res))
        .finally(() => setFetchData(false))
    }
  }, [initialValues?.ftagId, fetchData])

  const beforeSave = () => {
    onClose()
  }

  const handleAddCourse = (course: OnlineCourseItem) => {
    if (!selectedCourses.some(c => c.id === course.id)) {
      setSelectedCourses(prev => [...prev, course])
    }
  }

  const handleRemoveCourse = (courseId: number) => {
    setSelectedCourses(prev => prev.filter(course => course.id !== courseId))
  }

  const handleSave = async () => {
    try {
      if (initialValues?.ftagId) {
        await addCoursesToFTag(initialValues?.ftagId, {
          courseIds: selectedCourses.map(course => course.id),
        })
        toast('Successfully updated FTag courses.', successToastOptions)
        onSuccess()
        beforeSave()
      }
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={beforeSave} centered size='lg'>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeSave}
          ></i>
        </div>
        <SearchWithDropdown
          placeholder='Search to add more'
          value={query.key || ''}
          onChange={key => setQuery(prev => ({ ...prev, page: 1, key }))}
          onClear={() => setQuery(prev => ({ ...prev, page: 1, key: '' }))}
          onClick={async (courseId: number) => {
            const selected = options.find(option => option.id === courseId)
            if (selected) {
              handleAddCourse(selected)
            }
          }}
          options={options
            .filter(
              option =>
                !selectedCourses.map(course => course.id).includes(option.id),
            )
            .map(option => ({
              label: option.translations[0].content.name,
              value: option.code,
              id: option.id,
            }))}
        />

        {selectedCourses.length ? (
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <Table className='align-middle mb-0 mt-4'>
              <thead className='table-white' style={{ border: 'none' }}>
                <tr className='fs-14' style={{ color: 'white' }}>
                  {Columns.map(column => (
                    <th
                      style={{
                        background: 'white',
                        border: 'none',
                        ...column.style,
                      }}
                      scope='col'
                      className='align-middle text-muted fw-bold'
                      key={column.name}
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedCourses.map(course => (
                  <tr
                    key={course.id}
                    style={{
                      borderWidth: '1px 0px 1px 0px',
                      borderStyle: 'solid',
                      borderColor: '#F0F4F9',
                    }}
                    className={'fs-14 fw-light my-1'}
                  >
                    <td style={{ border: 'none' }}>
                      {course.translations?.[0]?.content?.name}
                    </td>
                    <td className='text-muted' style={{ border: 'none' }}>
                      {course?.code}
                    </td>
                    <td style={{ border: 'none' }}>
                      <i
                        className='bx  ri-delete-bin-2-line text-danger fs-16 cursor-pointer'
                        onClick={() => handleRemoveCourse(course.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : null}

        <div className='hstack gap-2 justify-content-end mt-4'>
          <Button
            className='btn-soft-primary'
            onClick={beforeSave}
            active={false}
          >
            Cancel
          </Button>
          <Button color='primary' type='submit' onClick={handleSave}>
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CourseFTagModal
