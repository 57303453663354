import { COURSE_FILTER_FORMATS, OnlineCourseItem } from './onlineCourse'
import { COURSE_TYPES } from '../lib/types'

export enum CoursePackageFormatsEnum {
  PACKAGE = 'package',
  TRAINING_DAY = 'trainingDay',
}

export enum CoursePackageStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export type TCoursePackage = {
  id: number
  companyId: number | null
  format: CoursePackageFormatsEnum
  code: string
  name: string
  type: COURSE_TYPES[]
  description: string | null
  disciplineIds: number[]
  disciplines?: string[]
  isDraft: boolean
  publishedDate: Date | null
  createdAt: string
  updatedAt: string
  coursesCount?: number
  courses?: OnlineCourseItem[]
  isAssigned?: boolean
  trainingDaysCount?: number
}

export type PackageCourse = {
  courseId: number
  packageId: number
  courseCode: string
  format: COURSE_FILTER_FORMATS
  createdAt: string
  updatedAt: string
  course: OnlineCourseItem
}
