import {
  AttachmentsMimeTypeEnum,
  COURSE_FILTER_FORMATS,
  GetScheduledCourseOptionsDTO,
  LiveCourseMedia,
  SCHEDULED_COURSE_FORMATS,
  SCHEDULED_COURSE_STATUSES,
  ScheduledCourseTypes,
  ScheduledEntitiesTypes,
} from '../sharedTypes'
import { LiveAssign } from '../Components/Modals/AssignCourseModal/types'
import _ from 'lodash'
import {
  CoursePackageFormatsEnum,
  TCoursePackage,
} from '../sharedTypes/models/coursePackage'
import { getScheduledCourseOptions } from './api/courses'
import moment from 'moment/moment'
import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'

export const BadgeColorByFormat = {
  [SCHEDULED_COURSE_FORMATS.WEBINAR]: 'secondary',
  [SCHEDULED_COURSE_FORMATS.IN_PERSON]: 'warning',
}
export const BadgeColorByStatus = {
  [SCHEDULED_COURSE_STATUSES.PUBLISHED]: 'success',
  [SCHEDULED_COURSE_STATUSES.DRAFT]: 'grey',
  [SCHEDULED_COURSE_STATUSES.INACTIVE]: 'danger',
}

export const BadgeDarkColorByStatus = {
  [SCHEDULED_COURSE_STATUSES.PUBLISHED]: 'green',
  [SCHEDULED_COURSE_STATUSES.DRAFT]: 'soft-grey',
  [SCHEDULED_COURSE_STATUSES.INACTIVE]: 'red',
}

export const BadgeColorByAllFormats = {
  [COURSE_FILTER_FORMATS.DIGITAL]: 'blue',
  [COURSE_FILTER_FORMATS.VIDEO]: 'blue',
  [COURSE_FILTER_FORMATS.WEBINAR]: ' text-light-purple',
  [COURSE_FILTER_FORMATS.IN_PERSON]: 'green',
  [COURSE_FILTER_FORMATS.COMPETENCY_ONLY]: 'yellow',
}

export const tagColors = [
  'badge-outline-primary',
  'badge badge-outline-secondary',
  'badge-outline-success',
  'badge-outline-info',
  'badge-outline-warning',
  'badge-outline-danger',
  'badge-outline-dark',
]

export const getAssignedCode = (item: LiveAssign) => {
  const packageCourseCode = _.get(item, 'package.courseCode')

  if (packageCourseCode) {
    return packageCourseCode
  }

  if (
    (item.course as TCoursePackage)?.format ===
    CoursePackageFormatsEnum.TRAINING_DAY
  ) {
    return item.course.code
  }

  return `${getCourseFormatPrefix(item.format)}-${item.course.code}`
}

export const getCourseFormatPrefix = (format: COURSE_FILTER_FORMATS) => {
  let prefixCode = 40

  switch (format) {
    case COURSE_FILTER_FORMATS.DIGITAL:
    case COURSE_FILTER_FORMATS.VIDEO:
      prefixCode = 10
      break
    case COURSE_FILTER_FORMATS.WEBINAR:
      prefixCode = 20
      break
    case COURSE_FILTER_FORMATS.IN_PERSON:
      prefixCode = 30
      break
  }

  return prefixCode
}

export const loadScheduleOptions = (
  item: LiveAssign,
  facilityId: number | undefined,
): Promise<{ value: number; label: string }[]> => {
  if (
    !facilityId ||
    ![COURSE_FILTER_FORMATS.IN_PERSON, COURSE_FILTER_FORMATS.WEBINAR].includes(
      item.format,
    )
  ) {
    return Promise.resolve([])
  }

  const query: GetScheduledCourseOptionsDTO.Request = {
    facilityId,
    type:
      item.format === COURSE_FILTER_FORMATS.IN_PERSON
        ? ScheduledCourseTypes.IN_PERSON
        : ScheduledCourseTypes.WEBINAR,
    entityType:
      (item.course as TCoursePackage).format ===
      CoursePackageFormatsEnum.TRAINING_DAY
        ? ScheduledEntitiesTypes.TRAINING_DAY
        : ScheduledEntitiesTypes.COURSE,
  }

  return getScheduledCourseOptions(item.course.id, query)
    .then(({ data }) => {
      return data.map(i => ({
        value: i.id,
        label: moment(i.date).format('MM/DD/YYYY, hh:mm A'),
      }))
    })
    .catch(() => {
      return []
    })
}

export const getBadge = (media: LiveCourseMedia) => {
  switch (media.attachment.mimetype) {
    case AttachmentsMimeTypeEnum.PDF:
    case AttachmentsMimeTypeEnum.PPT:
      return (
        <>
          <UncontrolledTooltip
            placement='top'
            target={`media-${media.attachment.id}`}
          >
            {media.attachment.name}
          </UncontrolledTooltip>
          <Link to={media.attachment.path} target='_blank'>
            <Button
              size='sm'
              className='btn-soft-primary align-middle fs-12 fw-medium'
              id={`media-${media.attachment.id}`}
            >
              <i className={'ri-file-text-line me-1'}></i>
              {media.attachment.mimetype === AttachmentsMimeTypeEnum.PDF
                ? 'PDF'
                : 'PPT'}
            </Button>
          </Link>
        </>
      )
    case AttachmentsMimeTypeEnum.AUDIO_MP3:
    case AttachmentsMimeTypeEnum.VIDEO_MP4:
      return (
        <>
          <UncontrolledTooltip
            placement='top'
            target={`media-${media.attachment.id}`}
          >
            {media.attachment.name}
          </UncontrolledTooltip>
          <Link to={media.attachment.path} target='_blank'>
            <Button
              size='sm'
              className='btn-soft-primary align-middle fs-12 fw-medium'
              id={`media-${media.attachment.id}`}
            >
              <i className={'ri-play-line'}></i>
              {media.attachment.mimetype === AttachmentsMimeTypeEnum.AUDIO_MP3
                ? 'Audio'
                : 'Video'}
            </Button>
          </Link>
        </>
      )
    default:
      return ''
  }
}

export const formattedDuration = (duration: number) => {
  if (!duration) {
    return ''
  }

  const hours = Number(moment.duration(duration, 'seconds').hours())
  const minutes = Number(moment.duration(duration, 'seconds').minutes())

  return `${hours ? hours + ' h ' : ''}${minutes ? minutes + ' min' : ''}`
}

export const formattedDurationTime = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds')
  const hh = Math.floor(duration.asHours())
  const mm = duration.minutes()
  const ss = duration.seconds()

  return `${String(hh).padStart(2, '0')}:${String(mm).padStart(
    2,
    '0',
  )}:${String(ss).padStart(2, '0')}`
}
