import { TUser } from './user'
import { TGroup } from './groups'
import { TCompany } from './company'
import { TAttachment } from './attachments'
import { StatesEnum } from '../lib/locations'

export enum TFacilityStatus {
  'ACTIVE' = 'active',
  'INACTIVE' = 'inactive',
}

export type TFacility = {
  id: number
  email: string
  phone: string
  name: string
  address: string
  status: TFacilityStatus
  activationDate: Date | null
  signatoryPosition: string | null
  signatory?: TUser
  signatoryId?: number
  masteredManager?: TUser
  masteredManagerId?: number
  HRManager?: TUser
  HRManagerId?: number
  clinicalScheduler?: TUser
  clinicalSchedulerId?: number
  admins?: TUser[]
  state: StatesEnum
  users?: TUser[]
  groupId: number | null
  group: TGroup | null
  companyId: number
  signatureId: number | null
  signature: TAttachment | null
  company: TCompany | null
  createdAt: string
  updatedAt: string
}
