import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import Highlighter from 'react-highlight-words'
import { SettingsPermissions } from '../../../sharedTypes'
import { deleteFTag, getFTags } from '../../../helpers/api_helper'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteConfirmation from '../../../Components/Modals/DeleteConfirmation'
import { SearchInput } from '../../../Components/Common/SearchInput'
import { OrderType } from '../../../sharedTypes'
import { Pagination } from '../../../Components/Common/Pagination'
import { successToastOptions } from '../../../helpers/toast_helper'
import { toast } from 'react-toastify'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { usePermissions } from '../../../hooks/usePermissions'
import { FTagSortBy, GetFTagsDTO } from '../../../sharedTypes/api/ftags'
import { TFTag } from '../../../sharedTypes/models/ftag'
import FTagModal, { FTagModalForm } from '../../../Components/Modals/FTag.modal'
import CourseFTagModal from '../../../Components/Modals/CourseFTagModal'

const Columns = [
  {
    name: 'ID',
    sortBy: FTagSortBy.CODE,
    style: { width: '10%' },
  },
  {
    name: 'Name',
    sortBy: FTagSortBy.NAME,
    style: { width: '50%' },
  },
  {
    name: 'Courses',
    sortBy: FTagSortBy.COURSES,
    style: { width: '35%' },
  },
  {
    name: 'Actions',
  },
]

const FTags = () => {
  document.title = 'FTags | Mastered - Admin & Dashboard'

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<GetFTagsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    ftags: [],
  })
  const [query, setQuery] = useState<GetFTagsDTO.Request>({
    page: 1,
    limit: 10,
    key: null,
    sortBy: FTagSortBy.CODE,
    orderBy: OrderType.ASC,
    permission: SettingsPermissions.VIEW_FTAG_LISTING,
  })

  const [createModal, setCreateModal] = useState<FTagModalForm | null>(null)
  const [editModal, setEditModal] = useState<FTagModalForm | null>(null)
  const [showCourseFTagModal, setShowCourseFTagModal] = useState<
    | {
        ftagId: number | undefined
        courseId: number | undefined
      }
    | undefined
  >()

  const [deleteId, setDeleteId] = useState<null | number>()

  const permissions = {
    add: usePermissions(SettingsPermissions.ADD_COURSE_FTAG),
    edit: usePermissions(SettingsPermissions.EDIT_FTAG),
    delete: usePermissions(SettingsPermissions.DELETE_FTAG),
    assign: usePermissions(SettingsPermissions.ADD_COURSE_FTAG),
  }

  useEffect(() => {
    getFTags(query)
      .then(response => {
        setData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [query])

  const handleSort = useCallback((column: FTagSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const onDelete = useCallback(async () => {
    try {
      await deleteFTag(deleteId as number)
      setDeleteId(null)
      toast('Successfully deleted', successToastOptions)
      setQuery(prev => ({ ...prev, page: 1 }))
    } catch (e) {}
  }, [data.page, deleteId])

  const onSubmit = useCallback(() => {
    setQuery(prev => ({ ...prev, page: 1 }))
    setCreateModal(null)
    setEditModal(null)
  }, [data.page])

  const columns = useMemo(() => {
    if (!permissions.edit && !permissions.delete) {
      return Columns.filter(column => column.name !== 'Actions')
    }

    return Columns
  }, [permissions])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Ftags'
            items={[
              {
                title: 'Settings',
                linkTo: '/',
              },
              {
                title: 'Ftags',
                active: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      value={query.key || ''}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                    />
                    {permissions.add && (
                      <div className='d-flex flex-shrink-0 gap-3'>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setCreateModal({
                              id: -1,
                              name: '',
                              code: '',
                            })
                          }}
                          className='btn btn-primary align-middle'
                        >
                          <i className='ri-add-line me-1 fs-16'></i>Add FTag
                        </Button>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <Table className='align-middle table-nowrap mb-0'>
                      <thead className='table-light'>
                        <tr className='text-muted fs-14'>
                          {columns.map(column => (
                            <th
                              scope='col'
                              className='align-middle'
                              style={column.style}
                              key={column.name}
                            >
                              {column.name}
                              {!!column.sortBy && (
                                <ColumnSortIcon<FTagSortBy>
                                  sortOrder={query.orderBy}
                                  sortedColumn={query.sortBy}
                                  column={column.sortBy}
                                  handleSort={handleSort}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.ftags.map((item: TFTag, i) => {
                          return (
                            <tr key={i} className='fs-14'>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  className='d-inline-block'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={item.code}
                                />
                              </td>
                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  className='d-inline-block'
                                  searchWords={[query.key || '']}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={item.name}
                                />
                              </td>
                              <td>
                                <span className='badge badge-soft-grey text-muted fw-normal fs-12 ms-auto'>
                                  {item.tagCount || ''}
                                </span>
                              </td>
                              {(permissions.edit || permissions.delete) && (
                                <td>
                                  <span
                                    className='d-flex gap-2'
                                    onClick={e => {
                                      e.stopPropagation()
                                    }}
                                  >
                                    {permissions.assign && (
                                      <i
                                        onClick={() =>
                                          setShowCourseFTagModal({
                                            ftagId: item.id,
                                            courseId: undefined,
                                          })
                                        }
                                        className='bx bx-list-plus cursor-pointer	'
                                      ></i>
                                    )}
                                    {permissions.edit && (
                                      <i
                                        onClick={() => setEditModal(item)}
                                        className='bx bx-edit-alt cursor-pointer'
                                      ></i>
                                    )}
                                    {permissions.delete && (
                                      <i
                                        onClick={() => setDeleteId(item.id)}
                                        className='bx bx-trash text-danger cursor-pointer	'
                                      ></i>
                                    )}
                                  </span>
                                </td>
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    <div className='mx-3 mt-3'>
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
          <DeleteConfirmation
            isOpen={!!deleteId}
            title='Delete FTag'
            message={`You have ${data.ftags.find(item => item.id === deleteId)
              ?.tagCount} courses assigned to this FTag, are you sure you want to delete?`}
            onDelete={onDelete}
            optionalContent={
              <div
                className='d-flex gap-3 bg-light justify-content-between align-items-start px-3 py-2 text-muted'
                style={{ minHeight: '58px' }}
              >
                <span>
                  {data.ftags.find(item => item.id === deleteId)?.name}
                </span>
                <span>
                  {data.ftags.find(item => item.id === deleteId)?.code}
                </span>
              </div>
            }
            onClose={() => {
              setDeleteId(null)
            }}
          />
          <FTagModal
            title={editModal ? 'Edit FTag' : 'Add New FTag'}
            isOpen={!!createModal || !!editModal}
            initialValues={editModal || createModal}
            onSubmit={onSubmit}
            onClose={() => {
              setEditModal(null)
              setCreateModal(null)
            }}
          />
          <CourseFTagModal
            title={`Add Course to ${data.ftags.find(
              item => item.id === showCourseFTagModal?.ftagId,
            )?.code} - ${data.ftags.find(
              item => item.id === showCourseFTagModal?.ftagId,
            )?.name}`}
            isOpen={!!showCourseFTagModal}
            initialValues={showCourseFTagModal}
            onSuccess={() => setQuery(prev => ({ ...prev, page: 1 }))}
            onClose={() => setShowCourseFTagModal(undefined)}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FTags
