import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
} from 'reactstrap'
import React from 'react'
import {
  CoursesPermissions,
  GetUsersDTO,
  USER_STATUSES,
} from '../../sharedTypes'
import { Formik } from 'formik'
import moment from 'moment/moment'
import { getUsers } from '../../helpers/api/users'
import { AsyncSelectWithSearch } from '../Common/SelectWithSearch'
import { getUserDisplayName } from '../../helpers/user'
import { Options } from './AssignCourseModal/types'

interface AssignUserModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  entityName: string
  start: string
  location: string
  facilityId: number
  handleAdd: (userIds: number[]) => void
}

export interface FormValues {
  users: Options[]
}

const AssignUserModal = ({
  onClose,
  isOpen,
  entityName,
  start,
  location,
  facilityId,
  handleAdd,
}: AssignUserModalProps) => {
  const fetchUsers = (inputValue?: string) => {
    const params: GetUsersDTO.Request = {
      page: 1,
      limit: 20,
      key: inputValue,
      permission: CoursesPermissions.ASSIGN_COURSE,
      facilityIds: [facilityId],
      status: [USER_STATUSES.ACTIVE, USER_STATUSES.UNVERIFIED],
    }

    return getUsers(params)
      .then(res => {
        return res.data.users.map(user => ({
          value: user.id,
          label: getUserDisplayName(user),
        }))
      })
      .catch(() => [])
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      className='media-library-modal'
      centered
    >
      <ModalHeader toggle={onClose}>
        <h5 className='fw-normal text-muted'>Assign {entityName}</h5>
      </ModalHeader>
      <ModalBody className='p-4'>
        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={{
            users: [],
          }}
          onSubmit={values => {
            const userIds = values.users.map(user => user.value)
            handleAdd(userIds)
          }}
        >
          {({
            values,
            handleBlur,
            setFieldValue,
            isValid,
            dirty,
            isSubmitting,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} action='#'>
              <ul className='mx-auto list-unstyled overflow-auto general-info'>
                <li className='mb-4 gap-4 d-flex'>
                  <span className='title fw-medium text-body fs-14'>
                    Date and Time:
                  </span>
                  <span className='text-muted fs-14'>
                    {moment(start).format('MM/DD/YYYY, hh:mm')}
                  </span>
                </li>
                <li className='mb-4 gap-4 d-flex'>
                  <span className='title fw-medium text-body fs-14'>
                    Location:
                  </span>
                  <span className='text-muted fs-14'>{location || '-'}</span>
                </li>
                <div>
                  <label htmlFor='departments' className='form-label'>
                    Users*
                  </label>
                  <AsyncSelectWithSearch
                    name='users'
                    id='users'
                    onChange={option => {
                      setFieldValue(
                        'users',
                        Array.isArray(option) ? option : [option],
                      )
                    }}
                    onBlur={handleBlur}
                    value={values.users}
                    isMulti={true}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={'Enter name'}
                    defaultOptions
                    loadOptions={fetchUsers}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </ul>

              <div className='hstack gap-3 justify-content-end'>
                <Button
                  className='btn-soft-primary align-middle'
                  color='secondary'
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className='align-middle'
                  color='primary'
                  type='submit'
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Assign
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default AssignUserModal
