import React, { Fragment } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setOpenModalId } from '../../slices/detailsInfo/reducer'

interface DetailsInfoModalProps {
  id: number | string
  name: string
  info?: string
  onHover?: boolean
  className?: string
}

const DetailsInfoModal = ({
  id,
  name,
  info,
  onHover,
  className = '',
}: DetailsInfoModalProps) => {
  const { openModalId } = useAppSelector(state => state.DetailsInfo)
  const dispatch = useAppDispatch()
  const elementId = `${name}-${id}`
  const handleModalToggle = () => {
    if (openModalId === id) {
      dispatch(setOpenModalId(null))
    } else {
      dispatch(setOpenModalId(id))
    }
  }

  return (
    <Fragment>
      {info && (
        <i
          style={{
            marginLeft: 4,
            color: openModalId === id ? '#0039C7' : 'text-muted',
          }}
          className={`ri-information-line cursor-pointer fs-16 act ${className} ${
            openModalId === id ? 'active-i' : 'text-muted'
          }`}
          id={elementId}
          onClick={handleModalToggle}
          onMouseEnter={() => onHover && dispatch(setOpenModalId(id))}
          onMouseLeave={() => onHover && dispatch(setOpenModalId(null))}
        />
      )}

      {info && (
        <UncontrolledTooltip
          container='body'
          className='cursor-pointer info-body--tooltip'
          placement='top'
          target={elementId}
          trigger='hover focus'
          onClick={handleModalToggle}
          isOpen={openModalId === id}
        >
          {info}
        </UncontrolledTooltip>
      )}
    </Fragment>
  )
}

export default DetailsInfoModal
