import { ModalProps } from 'reactstrap'
import { FormikHandlers, FormikHelpers } from 'formik'
import React, { Dispatch, SetStateAction } from 'react'
import {
  COURSE_FILTER_FORMATS,
  COURSE_TYPES,
  CoursesTabs,
  OnlineCourseItem,
  SelectedCourse,
  TUser,
  UserPermissions,
} from '../../../sharedTypes'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'
import { ItemsToAssign } from '../../../pages/Courses/OnlineCourses/types'

export interface AssignCourseModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: () => void
  user?: TUser
  selectedEntities?: ItemsToAssign
}

export interface Step1Props {
  values: FormValues['assignTo']
  setFieldValue: FormikHelpers<FormValues>['setFieldValue']
  handleBlur: FormikHandlers['handleBlur']
  onCancel: () => void
  onNext: () => void
  permission: UserPermissions
}

export interface Step2Filters {
  activeTab: CoursesTabs
  limit: number
  key?: string
  formats?: COURSE_FILTER_FORMATS[]
  types?: COURSE_TYPES[]
}

export interface ICoursesData {
  page: number
  data: OnlineCourseItem[]
}

export interface IStep2Data {
  [CoursesTabs.COURSES]: ICoursesData
  [CoursesTabs.PACKAGES]: {
    page: number
    data: TCoursePackage[]
  }
  [CoursesTabs.TRAINING_DAYS]: {
    page: number
    data: TCoursePackage[]
  }
}

export interface Step2Props extends Omit<Step1Props, 'values' | 'permission'> {
  values: FormValues['enrollment']
  filters: Step2Filters
  setFilters: Dispatch<SetStateAction<Step2Filters>>
  isLoading: boolean
  itemsToEnroll: IStep2Data
  isValidating: boolean
  hideBackButton: boolean
}

export interface Step3Props
  extends Omit<
    Step1Props,
    'values' | 'handleBlur' | 'setFieldValue' | 'permission'
  > {
  assignOptions: AssignToOptions
  onDelete: (item: DigitalAssign | LiveAssign) => void
  onSetDate: (
    type: 'live' | 'digital',
    item: DigitalAssign | LiveAssign,
  ) => void
}

export interface Step4Props
  extends Omit<
    Step1Props,
    'values' | 'handleBlur' | 'setFieldValue' | 'permission'
  > {
  isLoading: boolean
}

export interface Options {
  value: number
  label: string
}

export interface SelectGroup {
  label: string
  value: number[]
}

export interface FormValues {
  assignTo: {
    facilities?: Options[] | { value: 'all'; label: 'All' }
    departments?: SelectGroup[] | { value: 'all'; label: 'All' }
    positions?: SelectGroup[] | { value: 'all'; label: 'All' }
    users?: Options[] | { value: 'all'; label: 'All' }
  }
  enrollment: SelectedCourse
}

export const AllValue = { value: 'All', label: 'All' }

export interface DigitalAssign {
  course: OnlineCourseItem
  format: COURSE_FILTER_FORMATS
  dateAvailable?: Date
  dueDate?: Date
  isValid: boolean
}

export interface LiveAssign extends Omit<DigitalAssign, 'course'> {
  course: OnlineCourseItem | TCoursePackage
  scheduleId?: number
}

export interface AssignToOptions {
  digital: DigitalAssign[]
  live: LiveAssign[]
  facilities: number[]
  assignTo: number[]
}

export interface CollapseHeaderProps {
  label: string
  icon: string
  stepPassed: boolean
  changeActiveStep: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export interface Step1PassedProps {
  assignTo: FormValues['assignTo']
}

export interface Step2PassedProps {
  enrollment: FormValues['enrollment']
}

export interface Step3PassedProps {
  assignOptions: AssignToOptions
}
