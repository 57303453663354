import React from 'react'
import { Label, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { CoursesPermissions, ONLINE_COURSE_STATUS } from '../../sharedTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { filter, get, map } from 'lodash'
import { usePermissions } from '../../hooks/usePermissions'
import { useCloneCourse, useDeleteCourse } from '../../hooks/course'
import { useCourseRevision } from '../../hooks/course/useCourseRevision'
import { ReactComponent as RevisionIcon } from '../../assets/images/svg/revisionIcon.svg'

export enum CourseTabItemEnum {
  GENERAL = 'GENERAL',
  DIGITAL = 'DIGITAL',
  VIDEO = 'VIDEO',
  LIVE = 'LIVE',
  KNOWLEDGE_CHECK = 'KNOWLEDGE_CHECK',
  COMPETENCY = 'COMPETENCY',
}

export interface CourseTabItem {
  path: string
  label: string
  active: boolean
  count?: number
  error: boolean
}

export interface CourseTabs {
  [CourseTabItemEnum.GENERAL]: CourseTabItem
  [CourseTabItemEnum.DIGITAL]: CourseTabItem
  [CourseTabItemEnum.VIDEO]: CourseTabItem
  [CourseTabItemEnum.LIVE]: CourseTabItem
  [CourseTabItemEnum.KNOWLEDGE_CHECK]: CourseTabItem
  [CourseTabItemEnum.COMPETENCY]: CourseTabItem
}

export const getNextStep = (path: string, tabs: CourseTabs): string | null => {
  return getStepBase(path, tabs, 'next')
}

export const getStepBase = (
  path: string,
  tabs: CourseTabs,
  mode: 'prev' | 'next',
): string | null => {
  const filtered = map(tabs, t => t).filter(s => s.active)
  const currentStep = filtered.findIndex(s => path.includes(s.path))
  return get(
    filtered,
    `[${mode === 'prev' ? currentStep - 1 : currentStep + 1}].path`,
    null,
  )
}

export const getPrevStep = (path: string, tabs: CourseTabs): string | null => {
  return getStepBase(path, tabs, 'prev')
}

export interface TabsHeaderProps {
  courseId: number | null | undefined
  deletable: boolean
  disabled?: boolean
  status: ONLINE_COURSE_STATUS | undefined
  hasRevision: boolean
  tabs: CourseTabs
  onNavigate: (path: string) => void
}
export const TabsHeader = ({
  courseId,
  disabled,
  deletable,
  tabs,
  status,
  hasRevision,
  onNavigate,
}: TabsHeaderProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const hasPermissionToDeleteCourse = usePermissions(
    CoursesPermissions.DELETE_COURSE,
  )
  const hasPermissionToCloneCourse = usePermissions(
    CoursesPermissions.CLONE_COURSE,
  )
  const { renderCloneModal, setClonedCourseId } = useCloneCourse()
  const { renderDeleteModal, setDeleteCourseId } = useDeleteCourse(() => {
    navigate(`/courses`)
  })
  const { renderCourseRevisionModal, setRevisionCourseId } = useCourseRevision(
    courseId => {
      navigate(`/courses/general/${courseId}`)
    },
  )

  return (
    <>
      <Nav
        tabs
        className='nav justify-content-start mx-0 nav-tabs-custom rounded card-header-tabs border-bottom-1 mb-1'
      >
        {map(
          filter(
            map(tabs, v => v),
            { active: true },
          ),
          s => (
            <NavItem key={s.path}>
              <NavLink
                style={{
                  cursor: 'pointer',
                  color: s.error
                    ? '#F25959'
                    : !location.pathname.includes(s.path)
                    ? '#6C7393'
                    : '#0039C7',
                }}
                className={classnames({
                  active: location.pathname.includes(s.path),
                })}
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        onNavigate(`${s.path}/${courseId}`)
                      }
                }
              >
                <div className='gap-2 d-flex'>
                  <Label
                    className='form-check-label'
                    chekc
                    htmlFor='flexSwitchCheckDefault'
                  >
                    {s.label}
                  </Label>
                  {Number.isInteger(s.count) && (
                    <span className='badge badge-soft-grey fs-12 fw-normal text-capitalize'>
                      {s.count}
                    </span>
                  )}
                </div>
              </NavLink>
            </NavItem>
          ),
        )}

        <NavItem className='flex-fill d-flex justify-content-end p-3 gap-3'>
          {hasPermissionToCloneCourse && (
            <span
              className='text-primary cursor-pointer gap-2 d-flex'
              onClick={() => {
                setClonedCourseId(Number(courseId))
              }}
            >
              <i className='bx bx-duplicate cursor-pointer'></i>
              <b>Clone</b>{' '}
            </span>
          )}

          {status === ONLINE_COURSE_STATUS.PUBLISHED && !hasRevision && (
            <span
              className='text-primary cursor-pointer gap-2 d-flex align-items-center'
              onClick={() => {
                setRevisionCourseId(Number(courseId))
              }}
            >
              <RevisionIcon />
              <b>Add Revision</b>{' '}
            </span>
          )}

          {hasPermissionToDeleteCourse && deletable && (
            <span
              className='text-danger cursor-pointer gap-2 d-flex'
              onClick={() => {
                setDeleteCourseId(Number(courseId))
              }}
            >
              <i className='bx bx-trash text-danger cursor-pointer'></i>
              <b>Delete</b>{' '}
            </span>
          )}
        </NavItem>
      </Nav>

      {hasPermissionToCloneCourse && renderCloneModal()}
      {hasPermissionToDeleteCourse && renderDeleteModal()}
      {renderCourseRevisionModal()}
    </>
  )
}
