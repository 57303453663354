import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { showMessages } from '@intercom/messenger-js-sdk'

//import images
import logoWithoutLabel from '../assets/images/logo_without_label.png'
import logoLight from '../assets/images/logo-header.png'

//import Components
import ProfileDropdown from '../Components/Common/ProfileDropdown'
import FacilitiesDropdown from '../Components/Common/FacilitiesDropdown'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { CREDENTIALS_TYPE } from '../sharedTypes'
import { handleError } from '../helpers/toast_helper'
import { switchBackToMasteredPortal } from '../helpers/company'

interface HeaderProps {
  headerClass?: string
}

const Header = ({ headerClass }: HeaderProps) => {
  const { user, switchedToPortal } = useAppSelector(state => ({
    user: state.User.user,
    switchedToPortal: state.User.switchedToPortal,
  }))

  const email = useMemo(
    () =>
      user?.credentials?.find(i => i.credentialType === CREDENTIALS_TYPE.EMAIL)
        ?.credential || '',
    [user],
  )

  const dispatch = useAppDispatch()

  const onSwitchBack = () => {
    switchBackToMasteredPortal(email, dispatch).catch(handleError)
  }

  const toggleMenuBtn = () => {
    const windowSize = document.documentElement.clientWidth

    if (windowSize > 767) {
      document.querySelector('.hamburger-icon')?.classList.toggle('open')
    }

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu')
    }

    //For collapse vertical and semibox menu
    if (
      document.documentElement.getAttribute('data-layout') === 'vertical' ||
      document.documentElement.getAttribute('data-layout') === 'semibox'
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm')
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable')
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel')
    }
  }

  return (
    <React.Fragment>
      <header id='page-topbar' className={headerClass}>
        <div className='layout-width'>
          <div className='navbar-header'>
            <div className='d-flex'>
              <div className='navbar-brand-box horizontal-logo'>
                <Link to='/' className='logo logo-light'>
                  {user?.companyId ? (
                    <span>
                      <img src={logoWithoutLabel} alt='' height='26' />
                    </span>
                  ) : (
                    <span className='logo-lg'>
                      <img src={logoLight} alt='' height='26' />
                    </span>
                  )}

                  {user?.companyId && (
                    <>
                      {user?.company?.logo ? (
                        <span className='company-logo mx-3 pe-3 py-3 rounded'>
                          <img
                            src={user.company.logo.path}
                            alt=''
                            height='26'
                            className='ms-3'
                          />
                        </span>
                      ) : (
                        <span>
                          <span className='company-logo text-white fs-18 mx-3 px-3 py-2 rounded'>
                            {user?.company?.name}
                          </span>
                        </span>
                      )}
                    </>
                  )}
                </Link>
              </div>
              {switchedToPortal && (
                <div
                  className='d-flex align-items-center text-white fs-14 cursor-pointer'
                  onClick={onSwitchBack}
                >
                  <i className='ri-arrow-left-line label-icon align-middle me-2 fs-5' />
                  <span>Back to Mastered Portal</span>
                </div>
              )}

              <button
                onClick={toggleMenuBtn}
                type='button'
                className='btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger'
                id='topnav-hamburger-icon'
              >
                <span className='hamburger-icon'>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className='d-flex align-items-center'>
              {user?.companyId && <FacilitiesDropdown showLinks />}
              <i
                onClick={() => showMessages()}
                className='ri-question-mark text-white ms-sm-3 fs-24 cursor-pointer'
              />
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
