import { Input, InputProps } from 'reactstrap'
import React, { useState } from 'react'

interface DateOfBirthInputProps extends InputProps {
  handleChange: (value: string) => void
}

const DateOfBirthInput = ({ ...props }: DateOfBirthInputProps) => {
  const [dob, setDob] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target
    const inputValue = inputElement.value
    const cursorPosition = inputElement.selectionStart

    const formattedValue = formatDate(inputValue)

    inputElement.value = formattedValue

    // adjust cursor
    let newCursorPosition = cursorPosition as number
    if (formattedValue.length > inputValue.length) {
      newCursorPosition += 1
    } else if (formattedValue.length < inputValue.length) {
      newCursorPosition = Math.max(0, newCursorPosition - 1)
    }

    inputElement.setSelectionRange(newCursorPosition, newCursorPosition)

    setDob(formattedValue)
    props.handleChange(formattedValue)
  }

  const formatDate = (value: string) => {
    const numericValue = value.replace(/\D/g, '')

    const truncatedValue = numericValue.substring(0, 10)

    let formattedValue = ''
    for (let i = 0; i < truncatedValue.length; i++) {
      formattedValue += truncatedValue[i]
      if (i === 1 || i === 3) {
        formattedValue += '/'
      }
    }

    return formattedValue
  }

  return <Input type='text' onChange={handleChange} value={dob} {...props} />
}

export default DateOfBirthInput
