import React from 'react'
import { Input, Button } from 'reactstrap'
import { FormikErrors, FormikTouched } from 'formik'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import { TextAnswer } from '../../../sharedTypes'

export const TextAnswers = ({
  index,
  answers,
  onDelete,
  handleAnswerChange,
  handleCorrectChange,
  handleBlur,
  onReOrder,
  errors,
  hideCorrectAnswer,
  touched,
  maxMultiChoiceQuestions,
}: {
  index: number
  answers: TextAnswer[]
  errors: FormikErrors<TextAnswer[]>
  touched: FormikTouched<TextAnswer[]>
  onDelete: (id: number) => void
  handleCorrectChange: (index: number) => void
  handleAnswerChange: (answerIndex: number, value: string | boolean) => void
  handleBlur: (e: React.FocusEvent<any, Element>) => void
  onReOrder: (result: DropResult) => void
  hideCorrectAnswer?: boolean
  maxMultiChoiceQuestions: number
}) => {
  function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      transitionDuration: `0.001s`,
    }
  }

  return (
    <div className='d-flex flex-column gap-2'>
      <section>
        <label htmlFor='date' className='form-label mb-0'>
          Answers*
        </label>
        <p className='text-muted p-0 mb-0'>
          You can enter up to {maxMultiChoiceQuestions} answer
          {hideCorrectAnswer
            ? ' options.'
            : ' choices and select one correct answer.'}
        </p>
      </section>
      <DragDropContext onDragEnd={result => onReOrder(result)}>
        <Droppable
          droppableId='droppable'
          type='questions'
          direction='vertical'
        >
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {provided.placeholder}
              {answers.map((a, i) => (
                <Draggable
                  draggableId={`text-${i}-${index}`}
                  index={i}
                  key={`text-${i}-${index}`}
                >
                  {(provided, snapshot) => (
                    <div
                      key={i}
                      ref={provided.innerRef}
                      className='mb-2'
                      {...provided.draggableProps}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                      <section className='d-flex gap-1 is-invalid'>
                        <div {...provided.dragHandleProps}>
                          <div
                            style={{ width: 37, height: 37 }}
                            className='btn-label px-1 btn btn-light btn-sm'
                          >
                            <i
                              className='ri-draggable text-muted fs-5 drag-icon'
                              {...provided.draggableProps}
                            ></i>
                          </div>
                        </div>

                        <Input
                          className='form-control'
                          id={i.toString()}
                          name={`questions[${index}].answers[${i}].answer`}
                          placeholder=''
                          type='text'
                          invalid={
                            (errors as any)[i]?.answer &&
                            ((touched as any)[i]?.answer as any)
                          }
                          value={a.answer}
                          onChange={e => {
                            handleAnswerChange(i, e.target.value)
                          }}
                          onBlur={handleBlur}
                        />
                        <Button
                          style={{ width: 37, height: 37 }}
                          color='light'
                          className='btn-label px-1'
                          size='sm'
                          disabled={answers.length === 1}
                          onClick={() => onDelete(i)}
                        >
                          <i className='bx  ri-delete-bin-2-line text-muted fs-20'></i>
                        </Button>
                        {!hideCorrectAnswer && (
                          <Button
                            style={{ width: 37, height: 37 }}
                            color='light'
                            className={`btn-label px-1 ${
                              a.correct ? 'bg-green-100 border-green-500' : ''
                            }`}
                            size='sm'
                            onClick={() => {
                              handleCorrectChange(i)
                            }}
                          >
                            <i
                              className={`bx ri-check-fill fs-22 ${
                                a.correct ? 'text-green-500' : ''
                              }`}
                            ></i>
                          </Button>
                        )}
                      </section>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
