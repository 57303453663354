import { Col, Row } from 'reactstrap'
import { Step1PassedProps } from './types'
import React from 'react'
import moment from 'moment/moment'

const Step1Passed = ({ userData }: Step1PassedProps) => {
  return (
    <div>
      <Row className='me-1 my-2'>
        <Col>
          <div className='vstack gap-3 fs-14'>
            <div>
              <div className='text-muted'>Name</div>
              <span className='text-body'>
                {`${userData.firstName} ${userData.lastName}`}
              </span>
            </div>
            <div>
              <div className='text-muted'>Facility</div>
              <span className='text-body'>{userData.facility?.label}</span>
            </div>
          </div>
        </Col>

        <Col>
          <div className='vstack gap-3 fs-14'>
            <div>
              <div className='text-muted'>Birthdate</div>
              <span className='text-body'>
                {userData.dob ? moment(userData.dob).format('MM/DD/YYYY') : ' '}
              </span>
            </div>
            <div>
              <div className='text-muted'>Phone number</div>
              <span className='text-body'>{userData.phone}</span>
            </div>
          </div>
        </Col>

        <Col>
          <div className='vstack gap-3 fs-14'>
            <div>
              <div className='text-muted'>Departments</div>
              <span className='text-body'>{userData.department?.label}</span>
            </div>
            <div>
              <div className='text-muted'>Email address</div>
              <span className='text-body'>{userData.email}</span>
            </div>
          </div>
        </Col>

        <Col>
          <div className='vstack gap-3 fs-14'>
            <div>
              <div className='text-muted'>Position</div>
              <span className='text-body'>{userData.position?.label}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Step1Passed
