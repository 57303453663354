import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import React, { useState } from 'react'
import { TRole } from '../../../sharedTypes'

interface SortableColumnItemProps {
  role: TRole
  isEdit: boolean
  editRole: TRole | null
  onChange: (key: string, value: any) => void
  onSaveChanges: () => void
  onCancelEdit: () => void
  setEditRole: (role: TRole) => void
  setAssignRole: (role: TRole) => void
  onClone: (role: TRole) => void
  onDeleteRole: (role: TRole) => void
  permissions: {
    add: boolean
    editName: boolean
    editPermissions: boolean
    assign: boolean
    delete: boolean
  }
}

export const SortableColumnItem = ({
  role,
  isEdit,
  editRole,
  onChange,
  onSaveChanges,
  onCancelEdit,
  setEditRole,
  setAssignRole,
  onClone,
  onDeleteRole,
  permissions,
}: SortableColumnItemProps) => {
  const [actionDropdownOpen, setActionDropdownOpen] = useState<boolean>(false)
  return (
    <th
      key={role.id}
      className='align-middle fw-light'
      style={{
        padding: '0.5rem 0.6rem',
        minWidth: 100,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {isEdit ? (
        <span className='hstack'>
          <input
            className='form-control p-1 d-inline-block me-2'
            value={editRole?.name}
            onChange={event => {
              onChange('name', event.target.value)
            }}
          />
          <i
            className='fs-16 ri-check-line text-success cursor-pointer'
            onClick={onSaveChanges}
          ></i>
          <i
            className='fs-16 ri-close-line text-danger cursor-pointer'
            onClick={onCancelEdit}
          ></i>
        </span>
      ) : (
        <div className='d-flex align-items-center justify-content-between'>
          <span
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflowWrap: 'anywhere',
              maxWidth: '100%',
            }}
          >
            {role.name}
          </span>
          <Dropdown
            isOpen={actionDropdownOpen}
            toggle={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              setActionDropdownOpen(prevState => !prevState)
            }}
          >
            <DropdownToggle
              className={`badge btn ${
                actionDropdownOpen
                  ? 'btn-primary'
                  : 'text-light-purple btn-soft-primary'
              }`}
              tag='button'
            >
              <i className='mdi mdi-dots-horizontal align-middle' />
            </DropdownToggle>
            <DropdownMenu container={'body'}>
              {permissions.editName && (
                <DropdownItem
                  className={`d-flex align-items-center gap-3`}
                  onClick={() => setEditRole(role)}
                >
                  <i
                    className='ri-pencil-line cursor-pointer'
                    id={'editRole' + role.id}
                  ></i>
                  Edit Role
                </DropdownItem>
              )}
              {permissions.assign && (
                <DropdownItem
                  className={`d-flex align-items-center gap-3`}
                  onClick={() => setAssignRole(role)}
                >
                  <i
                    className='ri-group-line cursor-pointer'
                    id={'assignRole' + role.id}
                  ></i>
                  Assign Role
                </DropdownItem>
              )}
              {permissions.add && (
                <DropdownItem
                  className={`d-flex align-items-center gap-3`}
                  onClick={() => onClone(role)}
                >
                  <i
                    className='bx bx-duplicate cursor-pointer'
                    id={'cloneRole' + role.id}
                  ></i>
                  Clone Role
                </DropdownItem>
              )}
              {permissions.delete && (
                <DropdownItem
                  className={`d-flex align-items-center gap-3`}
                  onClick={() => onDeleteRole(role)}
                >
                  <i
                    className='ri-delete-bin-2-line text-danger cursor-pointer'
                    id={'deleteRole' + role.id}
                  ></i>
                  Delete Role
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
    </th>
  )
}

interface SortableColumnContainerProps {
  children: React.ReactNode
}
export const SortableColumnContainer = ({
  children,
}: SortableColumnContainerProps) => (
  <thead className='table-light'>
    <tr className='text-muted fs-14'>
      <th className='align-middle' style={{ minWidth: 200 }}>
        Actions
      </th>
      {children}
    </tr>
  </thead>
)
