import React, { useCallback, useRef } from 'react'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap'
import { postDefaultOnlineCourseFile } from '../../../../helpers/api_helper'
import BooksAndStethoscope from '../../../../assets/images/svg/Books_and_stethoscope.svg'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { useFormik } from 'formik'
import { defaultCourseSchema } from '../../../../schemas'
import { OnlineCourseFormatsEnum, COURSE_TYPES } from '../../../../sharedTypes'
import _ from 'lodash'
import MultiSelect from '../../../../Components/Common/MultiSelect'

interface IForm {
  name: string
  type: COURSE_TYPES[]
  formats: OnlineCourseFormatsEnum[]
}

const FormatCheckBox = ({
  label,
  name,
  value,
  onChange,
  disabled,
}: {
  label: string
  name: string
  value: string | number
  onChange: any
  disabled?: boolean
}) => {
  const inputRef = useRef<any>(null)

  function handleClick() {
    inputRef.current.click()
  }

  return (
    <div
      className={`${
        disabled ? '' : 'bg-white'
      } p-2 d-flex gap-2 cursor-pointer`}
      onClick={handleClick}
      style={{ border: '1px solid #CACCD8' }}
    >
      <input
        className='form-check-input pe-none'
        type='checkbox'
        ref={inputRef}
        disabled={disabled}
        onChange={onChange}
        name={name}
        value={value}
      />
      <div>{label}</div>
    </div>
  )
}

const Default = ({ router }: WithRouterProps) => {
  document.title = 'Create Course | Mastered - Admin & Dashboard'

  const onSubmit = useCallback(async (values: IForm) => {
    try {
      const course = await postDefaultOnlineCourseFile({
        name: values.name,
        formats: values.formats,
        type: values.type,
      })

      router.navigate(`/courses/general/${course.id}`)
    } catch (e) {}
  }, [])

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      formats: [],
      type: [],
    },
    validationSchema: defaultCourseSchema,
    onSubmit,
  })

  const courseTypeOptions = Object.values(COURSE_TYPES).map((t: string) => ({
    value: t as string,
    label: t as string,
  }))

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Create New Course'
            items={[
              {
                title: 'Courses',
                linkTo: '/',
              },
              {
                title: 'Create New Course',
                active: true,
              },
            ]}
          />
          <Row>
            <Col sm='12'>
              <Card className='py-5'>
                <section className='d-flex align-items-center gap-2 flex-column mb-2'>
                  <img
                    src={BooksAndStethoscope}
                    className='img-fluid mx-auto d-flex'
                    alt=''
                  />
                  <h3>Create a course!</h3>
                </section>

                <Row
                  className='gap-3 w-100 accent-blue-500 m-auto mb-3'
                  style={{
                    background: '#F6F9FE',
                    maxWidth: 485,
                    borderRadius: 8,
                    padding: 10,
                  }}
                >
                  <Col sm='12'>
                    <div>
                      <label htmlFor='title' className='form-label'>
                        Course Name*
                      </label>
                      <Input
                        className='form-control'
                        id='title'
                        name={`name`}
                        placeholder='Name this course'
                        type='text'
                        invalid={!!form.errors.name}
                        value={form.values.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                      />
                      {form.touched.name && form.errors.name ? (
                        <FormFeedback type='invalid'>
                          {form.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col sm='12'>
                    <div>
                      <label className='form-label'>Type*</label>
                      <div className='d-flex'>
                        <div className='flex-grow-1'>
                          <MultiSelect
                            name='type'
                            id='type'
                            placeholder='Select a Type'
                            options={courseTypeOptions}
                            onChange={(selectedOptions: any[]) => {
                              const selectedValues = selectedOptions.map(
                                item => item.value,
                              )
                              form.setFieldValue('type', selectedValues)
                            }}
                            isMulti
                            isClearable={false}
                            isSearchable
                          />
                          {form.touched.type && form.errors.type ? (
                            <FormFeedback type='invalid'>
                              {form.errors.type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row
                  className='w-100 accent-blue-500 m-auto text-center  mb-3'
                  style={{
                    background: '#F6F9FE',
                    maxWidth: 485,
                    borderRadius: 8,
                    padding: 10,
                    border:
                      form.touched.formats && form.errors.formats
                        ? '1px solid #f06548'
                        : undefined,
                  }}
                >
                  <label className='mb-2'>
                    What format(s) would you like to offer?
                  </label>
                  <div className='d-flex gap-3 justify-content-center'>
                    <FormatCheckBox
                      value={OnlineCourseFormatsEnum.DIGITAL}
                      name='formats'
                      label='Digital'
                      disabled={
                        _.filter(form.values.formats, f =>
                          [
                            OnlineCourseFormatsEnum.VIDEO,
                            OnlineCourseFormatsEnum.COMPETENCY,
                          ].includes(f),
                        ).length !== 0
                      }
                      onChange={form.handleChange}
                    />
                    <FormatCheckBox
                      value={OnlineCourseFormatsEnum.VIDEO}
                      disabled={
                        _.filter(form.values.formats, f =>
                          [
                            OnlineCourseFormatsEnum.DIGITAL,
                            OnlineCourseFormatsEnum.COMPETENCY,
                          ].includes(f),
                        ).length !== 0
                      }
                      name='formats'
                      onChange={form.handleChange}
                      label='Video'
                    />
                    <FormatCheckBox
                      value={OnlineCourseFormatsEnum.LIVE}
                      name='formats'
                      disabled={form.values.formats.includes(
                        OnlineCourseFormatsEnum.COMPETENCY,
                      )}
                      onChange={form.handleChange}
                      label='Live'
                    />

                    <FormatCheckBox
                      value={OnlineCourseFormatsEnum.COMPETENCY}
                      disabled={
                        _.filter(form.values.formats, f =>
                          [
                            OnlineCourseFormatsEnum.DIGITAL,
                            OnlineCourseFormatsEnum.VIDEO,
                            OnlineCourseFormatsEnum.LIVE,
                          ].includes(f),
                        ).length !== 0
                      }
                      name='formats'
                      onChange={form.handleChange}
                      label='Competency Only'
                    />
                  </div>
                </Row>
              </Card>
            </Col>
            <Row>
              <Col sm='12'>
                <div className='d-flex justify-content-between'>
                  <Button
                    color='light'
                    onClick={() => {
                      router.navigate(`/courses/`)
                    }}
                  >
                    Cancel
                  </Button>
                  <div className='hstack gap-3'>
                    <Button
                      color='primary'
                      className='btn-label right'
                      onClick={() => form.handleSubmit()}
                    >
                      <i className='ri-arrow-right-line label-icon align-middle ms-2'></i>
                      Next
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(Default)
