import React, { useEffect, useState } from 'react'
import { getRawPositions } from '../../../helpers/api_helper'
import { FilterOption, GetAdminsDTO } from '../../../sharedTypes'
import _ from 'lodash'
import Filters from '../Filters'
import { Col, Row } from 'reactstrap'
import MultiSelect from '../../../Components/Common/MultiSelect'

interface AdminFiltersProps {
  showFilters: boolean
  setQuery: React.Dispatch<React.SetStateAction<GetAdminsDTO.Request>>
  query: GetAdminsDTO.Request
  setFetchData: React.Dispatch<React.SetStateAction<boolean>>
  fetchData: boolean
}

const AdminFilters = ({
  showFilters,
  setQuery,
  setFetchData,
  query,
  fetchData,
}: AdminFiltersProps) => {
  const [positions, setPositions] = useState<string[]>([])
  useEffect(() => {
    getRawPositions().then(res => setPositions(res.data))
  }, [])
  return (
    <Row className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}>
      <Filters
        visible={showFilters}
        setFilters={data => {
          setQuery(prev => ({
            ...prev,
            facilityIds: data.facility,
            groupIds: data.groups,
            departmentIds: data.department,
            roleIds: data.roles,
            page: 1,
          }))

          if (!fetchData) {
            setFetchData(true)
          }
        }}
        filterBy={{
          facilities: true,
          departments: true,
          positions: false,
          agencies: false,
          groups: true,
          status: false,
          registered: false,
          role: true,
        }}
      />
      {showFilters && (
        <Col className='mt-0 p-0'>
          <div>
            <label htmlFor='position' className='form-label'>
              Filter by Position
            </label>

            <MultiSelect
              name='position'
              id='position'
              isMulti
              options={positions.map(obj => ({
                value: obj,
                label: obj,
              }))}
              isSearchable={true}
              placeholder='Select position'
              isClearable={true}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selectedOptions: FilterOption[]) => {
                setQuery(prev => ({
                  ...prev,
                  page: 1,
                  position: selectedOptions.map(item => item.label),
                }))
              }}
              defaultValue={positions
                .filter(obj => query?.position?.includes(obj))
                .map(obj => ({
                  value: obj,
                  label: obj,
                }))}
            />
          </div>
        </Col>
      )}
    </Row>
  )
}

export default AdminFilters
