import { SettingsPermissions } from '../lib/types'
import { TFTag } from '../models/ftag'
import { PaginationRequest } from './pagination'

export enum FTagSortBy {
  CODE = 'code',
  NAME = 'name',
  COURSES = 'tagCount',
}

export namespace GetFTagsDTO {
  export interface Request extends PaginationRequest {
    permission: SettingsPermissions.VIEW_FTAG_LISTING
    sortBy: FTagSortBy
    withCourses?: boolean
  }
  export interface Response {
    count: number
    ftags: TFTag[]
    page: number
    pages: number
  }
}

export namespace CreateFTagDTO {
  export interface Request {
    name: string
    code: string
  }
  export type Response = TFTag
}

export namespace UpdateFTagDTO {
  export interface Request {
    name: string
    code: string
  }
  export type Response = TFTag
}
