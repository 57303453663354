import Filters from '../../UserManagment/Filters'
import { Col, Row } from 'reactstrap'
import React, { Dispatch, SetStateAction } from 'react'
import { GetUserReportDTO } from '../../../sharedTypes/api/userReport'
import { FilterOption, USER_VERIFICATION_STATUS } from '../../../sharedTypes'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { SelectedFilters } from './types'

interface FilterByOptions {
  departments: boolean
  facilities: boolean
  positions: boolean
  agencies: boolean
  groups: boolean
  status: boolean
  registered: boolean
  hireDate: boolean
  signupDate: boolean
}

export enum SCHEDULED_OPTIONS {
  SCHEDULED = 'scheduled',
  NOT_SCHEDULED = 'not scheduled',
}

interface ReportFiltersProps {
  showFilters: boolean
  setQuery: Dispatch<SetStateAction<GetUserReportDTO.Request>>
  query: GetUserReportDTO.Request
  setSelectedFilters: (filters: SelectedFilters) => void
  setFetchData: Dispatch<SetStateAction<boolean>>
  filterBy?: FilterByOptions
}

const ReportFilters = ({
  showFilters,
  setQuery,
  query,
  setSelectedFilters,
  setFetchData,
  filterBy = {
    departments: true,
    facilities: true,
    positions: true,
    agencies: true,
    groups: false,
    status: true,
    registered: false,
    hireDate: true,
    signupDate: true,
  },
}: ReportFiltersProps) => {
  return (
    <>
      <Row className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}>
        <Filters
          visible={showFilters}
          initialValues={{
            status: query.status,
          }}
          setFilters={data => {
            setQuery(prev => ({
              ...prev,
              groupIds: data.groups,
              facilityIds: data.facility,
              agencyIds: data.agencies,
              departmentIds: data.department,
              positionIds: data.position,
              status: data.status,
              hireDate: data.hireDate,
              signupDate: data.signupDate,
              page: 1,
            }))
            setFetchData(true)
          }}
          filterBy={filterBy}
          setSelectedFilters={setSelectedFilters}
        />
        {showFilters && (
          <Row className='mx-0 p-0 gap-4'>
            <Col className='mt-0 p-0'>
              <div>
                <label htmlFor='verification-status' className='form-label'>
                  Filter by Status
                </label>

                <MultiSelect
                  name='verification-status'
                  id='verification-status'
                  isMulti={false}
                  options={Object.values(USER_VERIFICATION_STATUS).map(obj => {
                    const capitalized =
                      obj.charAt(0).toUpperCase() + obj.slice(1)
                    return {
                      value: obj,
                      label: capitalized,
                    }
                  })}
                  isSearchable={true}
                  placeholder='Select status'
                  isClearable
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={
                    query.verificationStatus
                      ? {
                          value: query.verificationStatus,
                          label:
                            query.verificationStatus.charAt(0).toUpperCase() +
                            query.verificationStatus.slice(1),
                        }
                      : null
                  }
                  onChange={(selectedOption: FilterOption | null) =>
                    setQuery(prev => ({
                      ...prev,
                      page: 1,
                      verificationStatus:
                        selectedOption?.value === prev.verificationStatus
                          ? undefined
                          : (selectedOption?.value as
                              | USER_VERIFICATION_STATUS
                              | undefined),
                    }))
                  }
                />
              </div>
            </Col>
            <Col className='mt-0 p-0'>
              <div>
                <label htmlFor='verification-status' className='form-label'>
                  Filter by Onboarding
                </label>

                <MultiSelect
                  name='verification-status'
                  id='verification-status'
                  isMulti={false}
                  options={Object.values(SCHEDULED_OPTIONS).map(obj => {
                    const capitalized =
                      obj.charAt(0).toUpperCase() + obj.slice(1)
                    return {
                      value: obj,
                      label: capitalized,
                    }
                  })}
                  isSearchable={false}
                  placeholder='Select onboarding'
                  isClearable
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={
                    query.onboarding
                      ? {
                          value: query.onboarding,
                          label:
                            query.onboarding.charAt(0).toUpperCase() +
                            query.onboarding.slice(1),
                        }
                      : null
                  }
                  onChange={(selectedOption: FilterOption | null) =>
                    setQuery(prev => ({
                      ...prev,
                      page: 1,
                      onboarding:
                        selectedOption?.value === prev.onboarding
                          ? undefined
                          : (selectedOption?.value as
                              | SCHEDULED_OPTIONS
                              | undefined),
                    }))
                  }
                />
              </div>
            </Col>
            <Col className='mt-0 p-0'>
              <div>
                <label htmlFor='verification-status' className='form-label'>
                  Filter by Facility Tour
                </label>

                <MultiSelect
                  name='verification-status'
                  id='verification-status'
                  isMulti={false}
                  options={Object.values(SCHEDULED_OPTIONS).map(obj => {
                    const capitalized =
                      obj.charAt(0).toUpperCase() + obj.slice(1)
                    return {
                      value: obj,
                      label: capitalized,
                    }
                  })}
                  isSearchable={false}
                  placeholder='Select facility tour'
                  isClearable
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={
                    query.facilityTour
                      ? {
                          value: query.facilityTour,
                          label:
                            query.facilityTour.charAt(0).toUpperCase() +
                            query.facilityTour.slice(1),
                        }
                      : null
                  }
                  onChange={(selectedOption: FilterOption | null) =>
                    setQuery(prev => ({
                      ...prev,
                      page: 1,
                      facilityTour:
                        selectedOption?.value === prev.facilityTour
                          ? undefined
                          : (selectedOption?.value as
                              | SCHEDULED_OPTIONS
                              | undefined),
                    }))
                  }
                />
              </div>
            </Col>
          </Row>
        )}
      </Row>
    </>
  )
}

export default ReportFilters
