import React, { useEffect } from 'react'

import './assets/scss/themes.scss'
import Route from './Routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useProfile } from './Components/Hooks/UserHooks'
import { setAuthorization } from './helpers/api_helper'
import * as Sentry from '@sentry/react'
import Intercom, { shutdown } from '@intercom/messenger-js-sdk'
import { CREDENTIALS_TYPE, TUserCredentials } from './sharedTypes'

const getCredentials = (credentials: TUserCredentials[]) => {
  const email = credentials.find(
    i => i.credentialType === CREDENTIALS_TYPE.EMAIL,
  )?.credential
  const phone = credentials.find(
    i => i.credentialType === CREDENTIALS_TYPE.PHONE,
  )?.credential
  return { email, phone }
}

function App() {
  const { userProfile, token, masteredToken } = useProfile()
  if (userProfile && token) {
    const userData = {
      user: userProfile,
      accessToken: token,
    }
    setAuthorization(token)
    sessionStorage.setItem('authUser', JSON.stringify(userData))
    if (masteredToken) {
      sessionStorage.setItem('masteredToken', masteredToken)
    }

    Sentry.setUser({ id: userProfile.id })
  }

  useEffect(() => {
    if (userProfile) {
      const { email, phone } = getCredentials(userProfile?.credentials || [])
      shutdown()
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
        user_id: userProfile.id.toString(),
        name: `${userProfile.firstName} ${userProfile.lastName}`,
        email: email,
        phone: phone,
        facility: userProfile.facility?.name,
        avatar: userProfile.photo,
      })
    } else {
      shutdown()
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
      })
    }
  }, [userProfile])

  return (
    <React.Fragment>
      <Route />
      <ToastContainer />
    </React.Fragment>
  )
}

export default App
