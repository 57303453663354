import React, { useMemo } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { USER_CREATION_METHOD } from '../../../sharedTypes'
import { useNavigate } from 'react-router-dom'
import { useUrlParams } from '../../../utils/urlParamsParser'
import { FILTERS } from '../UserReport/types'
import { v4 as uuidv4 } from 'uuid'

interface FilterTabsProps {
  navTab?: USER_CREATION_METHOD
  navToggle: (tab?: USER_CREATION_METHOD) => void
  style?: React.CSSProperties
}
const tabs = [
  { label: 'All', value: undefined },
  {
    label: 'Hosted',
    value: USER_CREATION_METHOD.HOSTED,
  },
  {
    label: 'Manual',
    value: USER_CREATION_METHOD.MANUAL,
  },
]

const FilterTabs = ({ navTab, navToggle, style }: FilterTabsProps) => {
  const navigate = useNavigate()
  const { updateUrlParams } = useUrlParams()
  const memoizedTabs = useMemo(() => {
    return tabs.map(item => ({
      ...item,
      id: uuidv4(),
    }))
  }, [])
  return (
    <Nav className='nav-customs-bordered' style={style}>
      {memoizedTabs.map(tab => (
        <NavItem key={tab.label}>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
              navigate(
                `?${updateUrlParams(FILTERS.TABS, tab.value ? tab.value : '')}`,
              )
            }}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
