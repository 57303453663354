import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactPlayer from 'react-player'
import { formattedDurationTime } from '../../../../helpers/course'
import { DocumentFile } from '../../../../sharedTypes'

interface VideoPlayerProps {
  file: DocumentFile
  handleDuration: (duration: number) => void
  onPlayedChange: (played: string) => void
}

const VideoPlayer = memo(
  ({ file, handleDuration, onPlayedChange }: VideoPlayerProps) => {
    const playerRef = useRef<ReactPlayer | null>(null)
    const [playing, setPlaying] = useState(false)
    const [played, setPlayed] = useState(0)
    const [duration, setDuration] = useState<number>(0)

    const handlePlayPause = useCallback(() => {
      setPlaying(prev => !prev)
    }, [])

    const videoUrl = useMemo(() => file.preview, [file])

    const onDuration = useCallback((duration: number) => {
      setDuration(duration)
    }, [])

    useEffect(() => {
      handleDuration(duration)
    }, [duration])

    return (
      <div>
        <ReactPlayer
          className='player'
          url={videoUrl}
          ref={playerRef}
          playing={playing}
          onDuration={onDuration}
          onProgress={({ played }) => {
            setPlayed(played)
            if (playerRef.current) {
              onPlayedChange(
                formattedDurationTime(played * playerRef.current.getDuration()),
              )
            }
          }}
          controls={false}
          width='100%'
          height='auto'
        />
        <div className='d-flex gap-3 align-items-center fs-4 mt-2'>
          <div onClick={handlePlayPause}>
            {playing ? (
              <i className='ri-pause-line cursor-pointer' />
            ) : (
              <i className='ri-play-line cursor-pointer' />
            )}
          </div>

          <div>
            <input
              id='progress'
              type='range'
              min='0'
              max='1'
              step='0.01'
              value={played}
              onChange={e => {
                const newValue = parseFloat(e.target.value)
                setPlayed(newValue)
                playerRef.current?.seekTo(newValue)
              }}
            />
          </div>
          {playerRef.current && (
            <div className='d-flex fs-13'>
              <span className='text-muted'>
                {formattedDurationTime(
                  playerRef.current.getDuration() * played,
                )}
              </span>
              <span className='text-muted'> / </span>
              <span className='text-muted'>
                {formattedDurationTime(playerRef.current.getDuration())}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  },
)

VideoPlayer.displayName = 'VideoPlayer'

export default VideoPlayer
