import React, { useCallback, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Input,
  Form,
  FormFeedback,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'

import { addFTag, updateFTag } from '../../helpers/api_helper'

import { successToastOptions } from '../../helpers/toast_helper'
import { TFTag } from '../../sharedTypes/models/ftag'

interface InstructorModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onSubmit: (instructor: TFTag) => void
  title: string
  initialValues: FTagModalForm | null
}

export type FTagModalForm = {
  id: number
  name: string
  code: string
}

const FTagModal = ({
  onClose,
  onSubmit,
  isOpen,
  title,
  initialValues,
}: InstructorModalProps) => {
  const [error, setError] = useState('')

  const onCreate = useCallback(async (values: FTagModalForm) => {
    const { name, code } = values
    try {
      const ftag = await addFTag({ name, code })
      form.resetForm()
      toast('Successfully added', successToastOptions)
      onSubmit(ftag)
    } catch (e: any) {
      setError(e.response.data.message)
    }
  }, [])

  const onEdit = useCallback(async (values: FTagModalForm) => {
    const { id, name, code } = values
    try {
      const ftag = await updateFTag({ name, code }, id)
      form.resetForm()
      onSubmit(ftag)
    } catch (e: any) {
      setError(e.response.data.message)
    }
  }, [])

  const _onSubmit = async (values: FTagModalForm) => {
    if (values.id > 0) {
      await onEdit(values)
    } else {
      await onCreate(values)
    }
  }

  const form = useFormik<FTagModalForm>({
    enableReinitialize: true,
    initialValues: initialValues || {
      id: -1,
      name: '',
      code: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter ftag name').max(100),
      code: Yup.string()
        .matches(/^F\d+$/, 'Code must start with "F" followed by digits')
        .max(20)
        .required('Please enter ftag code'),
    }),
    onSubmit: _onSubmit,
  })

  const beforeClose = useCallback(() => {
    if (!form.isSubmitting) {
      form.resetForm()
      onClose()
    }
  }, [form])

  return (
    <Modal isOpen={isOpen} toggle={beforeClose} centered size='lg'>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>{title}</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={beforeClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='d-flex flex-row vstack gap-3'>
            <div style={{ minWidth: '167px' }}>
              <label htmlFor='code' className='form-label'>
                Code
              </label>
              <Input
                name='code'
                className='form-control'
                id='code'
                placeholder='000 000'
                type='text'
                onChange={form.handleChange}
                disabled={form.isSubmitting || form.values.id !== -1}
                onBlur={form.handleBlur}
                value={form.values.code || ''}
                invalid={!!(form.touched.code && form.errors.code)}
              />
              {form.touched.code && form.errors.code ? (
                <FormFeedback type='invalid'>{form.errors.code}</FormFeedback>
              ) : null}
              {error ? <span className='text-danger'>{error}</span> : null}
            </div>
            <div className='w-100'>
              <label htmlFor='name' className='form-label'>
                Name
              </label>
              <Input
                name='name'
                className='form-control'
                id='name'
                placeholder='Enter ftag name'
                type='text'
                onChange={form.handleChange}
                disabled={form.isSubmitting}
                onBlur={form.handleBlur}
                value={form.values.name || ''}
                invalid={!!(form.touched.name && form.errors.name)}
              />
              {form.touched.name && form.errors.name ? (
                <FormFeedback type='invalid'>{form.errors.name}</FormFeedback>
              ) : null}
            </div>
          </div>
          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={beforeClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={form.isSubmitting || !form.dirty}
            >
              {form.isSubmitting ? (
                <Spinner size={'sm'} />
              ) : form.values.id !== -1 ? (
                'Save'
              ) : (
                'Add'
              )}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default FTagModal
