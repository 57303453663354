//FACILITIES
import {
  CreateFacilityDTO,
  GetFacilitiesDTO,
  TFacility,
  PostFacilitySignatoryDTO,
  UploadVideoDTO,
  TAttachment,
  GetFacilitySettingsDTO,
  UpdateFacilitySettingsDTO,
} from '../../sharedTypes'
import {
  DELETE_FACILITY_LOGO,
  DELETE_FACILITY_VIDEOS,
  GET_FACILITIES,
  GET_FACILITY_VIDEOS,
  PATCH_FACILITIES,
  POST_FACILITIES,
  POST_FACILITY_SIGNATORY,
  UPDATE_FACILITY_SETTINGS,
  UPLOAD_FACILITY_LOGO,
  UPLOAD_FACILITY_VIDEO,
} from '../url_helper'
import { API } from '../api_helper'
import { keys } from 'lodash'

export const getFacilities = (params: GetFacilitiesDTO.Request) => {
  return API.get<GetFacilitiesDTO.Response>(GET_FACILITIES, { params })
}

export const getFacilityById = (id: number) => {
  return API.get<TFacility>(GET_FACILITIES + `/${id}`)
}

export const postFacility = (data: CreateFacilityDTO.Request) => {
  return API.post<CreateFacilityDTO.Response>(POST_FACILITIES, data)
}

export const postFacilitySignatory = (
  data: PostFacilitySignatoryDTO.Request,
) => {
  const body = new FormData()

  keys(data).map(k => {
    body.append(k, (data as any)[k])
  })

  return API.post<PostFacilitySignatoryDTO.Response>(
    POST_FACILITY_SIGNATORY,
    body,
  ).then(d => d.data)
}

export const patchFacility = (
  id: number,
  data: Partial<CreateFacilityDTO.Request>,
) => {
  return API.patch<CreateFacilityDTO.Response>(
    PATCH_FACILITIES + `/${id}`,
    data,
  )
}

export const uploadFacilityVideo = (data: UploadVideoDTO.Request) => {
  const body = new FormData()

  keys(data).map(k => {
    body.append(k, (data as any)[k])
  })
  return API.post(UPLOAD_FACILITY_VIDEO, body)
}

export const uploadFacilityLogo = (data: UploadVideoDTO.Request) => {
  const body = new FormData()

  keys(data).map(k => {
    body.append(k, (data as any)[k])
  })
  return API.post(UPLOAD_FACILITY_LOGO, body)
}

export const deleteFacilityVideo = (id: number, facilityId?: number) => {
  return API.delete(`${DELETE_FACILITY_VIDEOS}/${id}/${facilityId}`)
}

export const deleteFacilityLogo = (id: number, facilityId?: number) => {
  return API.delete(`${DELETE_FACILITY_LOGO}/${id}/${facilityId}`)
}

export const getFacilitySettings = (facilityId?: number) => {
  return API.get<GetFacilitySettingsDTO.Response>(
    `${GET_FACILITY_VIDEOS}/${facilityId}`,
  )
}

export const updateFacilitySettings = (
  data: UpdateFacilitySettingsDTO.Request,
  facilityId?: number,
) => {
  const urlToRequest = facilityId
    ? UPDATE_FACILITY_SETTINGS + `/${facilityId}`
    : UPDATE_FACILITY_SETTINGS

  return API.patch<GetFacilitySettingsDTO.Response>(urlToRequest, data)
}

export const activateFacility = (id: number) => {
  return API.post<CreateFacilityDTO.Response>(
    POST_FACILITIES + `/${id}/activate`,
  )
}

export const deactivateFacility = (id: number) => {
  return API.post<CreateFacilityDTO.Response>(
    POST_FACILITIES + `/${id}/deactivate`,
  )
}
