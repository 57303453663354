import React, { useEffect, useRef, useState } from 'react'
import Select, {
  components,
  MenuListProps,
  Props,
  NoticeProps,
  OptionProps,
} from 'react-select'
import AsyncSelect, { AsyncProps } from 'react-select/async'

import { SearchInput } from './SearchInput'
import { GroupBase } from 'react-select/dist/declarations/src/types'
import _ from 'lodash'
import { MultiValue } from './MultiSelect'

const MenuList: <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: MenuListProps<Option, IsMulti, Group>,
) => React.JSX.Element = props => {
  const { selectProps } = props
  // Copied from source
  const ariaAttributes = {
    'aria-autocomplete': 'list',
    'aria-label': selectProps['aria-label'],
    'aria-labelledby': selectProps['aria-labelledby'],
  }
  return (
    <components.MenuList {...props}>
      <div className='m-3'>
        <SearchInput
          style={{ border: '1px solid #CED4DA', borderRadius: '4px' }}
          background='white'
          placeholder='Search for...'
          value={selectProps.inputValue}
          showClearIcon={false}
          inputProps={{
            onChange: e => {
              selectProps.onInputChange(e.currentTarget.value, {
                action: 'input-change',
                prevInputValue: selectProps.inputValue,
              })
            },
            onMouseDown: e => {
              e.stopPropagation()
              const input = e.target as HTMLInputElement
              input.focus()
            },
            onTouchEnd: e => {
              e.stopPropagation()
              const input = e.target as HTMLInputElement
              input.focus()
            },
            onFocus: selectProps.onFocus,
            ...(ariaAttributes as any),
          }}
        />
      </div>
      {props.children}
    </components.MenuList>
  )
}

const NoOptionsMessage: <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: NoticeProps<Option, IsMulti, Group>,
) => React.JSX.Element = props => {
  const { selectProps } = props
  if (!selectProps.inputValue) {
    return <components.NoOptionsMessage {...props} />
  }
  return (
    <components.NoOptionsMessage {...props}>
      <div className='fw-light text-black text-start'>
        No results for <b>"{selectProps.inputValue}"</b>
      </div>
    </components.NoOptionsMessage>
  )
}

interface AsyncSelectWithSearchProps extends AsyncProps<any, any, any> {
  error?: string
}

export const AsyncSelectWithSearch: React.FC<AsyncSelectWithSearchProps> = (
  props: AsyncSelectWithSearchProps,
) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const onDomClick = (e: any) => {
    const menu = containerRef.current?.querySelector('.select__menu')

    if (
      !containerRef.current?.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick)

    return () => {
      document.removeEventListener('mousedown', onDomClick)
    }
  }, [])

  return (
    <div ref={containerRef}>
      <AsyncSelect
        components={
          props.isSearchable
            ? {
                MenuList: MenuList,
                NoOptionsMessage: NoOptionsMessage,
              }
            : {
                MultiValue: multiValueProps => (
                  <MultiValue
                    {...multiValueProps}
                    className={_.get(props, 'id')}
                    containerWidth={
                      containerRef.current?.offsetWidth
                        ? containerRef.current?.offsetWidth - 38
                        : 312
                    }
                    lightColor={_.get(props, 'id') !== 'positionIds'}
                  />
                ),
              }
        }
        className='select2-container'
        classNamePrefix='select2-selection form-select'
        {...props}
        isSearchable={false}
        menuIsOpen={isFocused || undefined}
        onFocus={() => setIsFocused(true)}
      />
      {props.error ? (
        <div className='invalid-feedback'>{props.error}</div>
      ) : null}
    </div>
  )
}

export interface IGroupOptions {
  label: string
  value: string
  level: number
  hasChildren: boolean
  parentValue?: string
  disabled?: boolean
}

export interface GroupSelectProps<Option> extends Props<Option, boolean, any> {
  showLinks?: boolean
}

export const GroupSelectWithSearch: <Option extends IGroupOptions>(
  props: GroupSelectProps<Option>,
) => React.ReactElement = props => {
  const { showLinks } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')
  const [collapsed, setCollapsed] = useState(new Set<string>())

  const recursivelyCollapseChildren = (
    value: string,
    options: IGroupOptions[],
  ) => {
    const children = options.filter(option => option.parentValue === value)
    children.forEach(child => {
      collapsed.add(child.value)
      recursivelyCollapseChildren(child.value, options)
    })
  }

  const recursivelyExpandChildren = (
    value: string,
    options: IGroupOptions[],
  ) => {
    const children = options.filter(option => option.parentValue === value)
    children.forEach(child => {
      collapsed.delete(child.value)
      recursivelyExpandChildren(child.value, options)
    })
  }

  const onCollapse = (value: string) => {
    if (collapsed.has(value)) {
      collapsed.delete(value)
      recursivelyExpandChildren(value, props.options as IGroupOptions[])
    } else {
      collapsed.add(value)
      recursivelyCollapseChildren(value, props.options as IGroupOptions[])
    }
    setCollapsed(new Set(collapsed))
  }

  const onDomClick = (e: any) => {
    const menu = containerRef.current?.querySelector('.select__menu')

    if (
      !containerRef.current?.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false)
      setInputValue('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick)

    return () => {
      document.removeEventListener('mousedown', onDomClick)
    }
  }, [])

  return (
    <div ref={containerRef}>
      <Select
        components={
          props.isSearchable
            ? {
                MenuList: MenuList,
                NoOptionsMessage: NoOptionsMessage,
                Option: _CustomOption(collapsed, onCollapse, showLinks),
                MultiValue: multiValueProps => (
                  <MultiValue
                    {...(multiValueProps as any)}
                    className={_.get(props, 'id')}
                    containerWidth={
                      containerRef.current?.offsetWidth
                        ? containerRef.current?.offsetWidth - 38
                        : 312
                    }
                  />
                ),
              }
            : {
                Option: _CustomOption(collapsed, onCollapse),
              }
        }
        className='select2-container'
        classNamePrefix='select2-selection form-select'
        {...props}
        isSearchable={false}
        menuIsOpen={isFocused || undefined}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onFocus={() => setIsFocused(true)}
      />
    </div>
  )
}

export const GroupMultiSelectWithSearch: <Option extends IGroupOptions>(
  props: Props<Option, any, any>,
) => React.ReactElement = props => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')
  const [collapsed, setCollapsed] = useState(new Set<string>())

  const onCollapse = (value: string) => {
    collapsed.has(value) ? collapsed.delete(value) : collapsed.add(value)
    setCollapsed(new Set(collapsed))
  }

  const onDomClick = (e: any) => {
    const menu = containerRef.current?.querySelector('.select2-selection')

    if (
      !containerRef.current?.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false)
      setInputValue('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick)

    return () => {
      document.removeEventListener('mousedown', onDomClick)
    }
  }, [])

  return (
    <div ref={containerRef}>
      <Select
        components={
          props.isSearchable
            ? {
                MenuList: MenuList,
                NoOptionsMessage: NoOptionsMessage,
                Option: _CustomOption(collapsed, onCollapse),
                MultiValue: multiValueProps => (
                  <MultiValue
                    {...(multiValueProps as any)}
                    className={_.get(props, 'id')}
                    containerWidth={
                      containerRef.current?.offsetWidth
                        ? containerRef.current?.offsetWidth - 38
                        : 312
                    }
                  />
                ),
              }
            : {
                Option: _CustomOption(collapsed, onCollapse),
              }
        }
        className='select2-container group-multi-select'
        classNamePrefix='select2-selection form-select'
        {...props}
        isSearchable={false}
        menuIsOpen={isFocused || undefined}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onFocus={() => setIsFocused(true)}
      />
    </div>
  )
}

function _CustomOption(
  collapsed: Set<string>,
  onCollapse: (value: string) => void,
  showLinks?: boolean,
): <
  Option extends IGroupOptions,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => React.JSX.Element {
  return function CustomOption<
    Option extends IGroupOptions,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  >(props: OptionProps<Option, IsMulti, Group>): React.JSX.Element {
    const { level, hasChildren, label, value, parentValue, disabled } =
      props.data
    const isSearch = !!props.selectProps.inputValue

    if (parentValue && collapsed.has(parentValue)) {
      return <></>
    }

    return (
      <components.Option {...props}>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <span
            onClick={event => {
              if (disabled) {
                event.stopPropagation()
              }
            }}
            className='form-select__item-container'
          >
            <span
              style={{
                paddingLeft: level > 0 && !isSearch ? (level - 1) * 12 : 0,
                display: 'flex',
                flexDirection: 'row',
              }}
              className='form-select__item'
            >
              {level !== 0 && hasChildren && !isSearch && (
                <i
                  className={`ri-arrow-${
                    !collapsed.has(value) ? 'down' : 'right'
                  }-s-line me-2 fs-16 cursor-pointer ${
                    !collapsed.has(value) ? 'text-primary' : 'text-muted'
                  } ${disabled ? 'text-disabled pe-none' : ''}`}
                  onClick={event => {
                    event.stopPropagation()
                    onCollapse(value)
                  }}
                ></i>
              )}
              <span
                style={{
                  paddingLeft:
                    level !== 0 && !isSearch && !hasChildren ? 24 : 0,
                  display: 'flex',
                }}
                className={`form-select__label ${
                  disabled ? 'text-disabled pe-none' : ''
                }`}
              >
                {label}
              </span>
            </span>
          </span>
          {value.split(':')[0] === 'facility' && showLinks && (
            <i
              className='ri-external-link-fill cursor-pointer'
              onClick={event => {
                event.stopPropagation()
                window.open(
                  `${
                    window.location.origin
                  }/facility-management/facility/${parseInt(
                    value.split(':')[1],
                  )}`,
                  '_blank',
                  'noopener,noreferrer',
                )
              }}
            />
          )}
        </div>
      </components.Option>
    )
  }
}

export const AsyncSelectWithCheckBox: React.FC<AsyncSelectWithSearchProps> = (
  props: AsyncSelectWithSearchProps,
) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const onDomClick = (e: any) => {
    const menu = containerRef.current?.querySelector('.select__menu')

    if (
      !containerRef.current?.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onDomClick)

    return () => {
      document.removeEventListener('mousedown', onDomClick)
    }
  }, [])

  return (
    <div ref={containerRef}>
      <AsyncSelect
        components={
          props.isSearchable
            ? {
                MenuList: MenuList,
                NoOptionsMessage: NoOptionsMessage,
              }
            : {}
        }
        className='select2-container'
        classNamePrefix='select2-selection form-select'
        {...props}
        isSearchable={false}
        menuIsOpen={isFocused || undefined}
        onFocus={() => setIsFocused(true)}
      />
      {props.error ? (
        <div className='invalid-feedback'>{props.error}</div>
      ) : null}
    </div>
  )
}
