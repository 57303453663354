import { ASSIGNMENT_STATUS } from '../sharedTypes'

export const BadgeColorByStatus = {
  [ASSIGNMENT_STATUS.ASSIGNED]: 'soft-grey',
  [ASSIGNMENT_STATUS.EXPIRED]: 'soft-grey',
  [ASSIGNMENT_STATUS.IN_PROGRESS]: 'purple',
  [ASSIGNMENT_STATUS.COMPLETED]: 'green',
  [ASSIGNMENT_STATUS.TEST_PENDING]: 'yellow',
  [ASSIGNMENT_STATUS.ATTENDED]: 'green',
  [ASSIGNMENT_STATUS.ABSENT]: 'red',
}
