import React from 'react'
import { Button, Modal, ModalBody, ModalProps } from 'reactstrap'
import { Form, Formik } from 'formik'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

interface OverrideConfirmationModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  title: string
  message: string | JSX.Element
  onOverride: (date: Date) => void
  confirmLabel?: string
  confirmIcon?: string
}

export interface FormValues {
  date: Date
}

const CourseOverrideConfirmationModal = ({
  onClose,
  isOpen,
  title,
  message,
  onOverride,
  confirmLabel = 'Complete',
  confirmIcon = 'ri-alert-line',
}: OverrideConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered style={{ width: 420 }}>
      <ModalBody className='p-4'>
        <div className='text-center'>
          <div
            className='avatar-xl mx-auto'
            style={{ width: 116, height: 116 }}
          >
            <div className='avatar-title rounded bg-soft-danger text-danger'>
              <i className={confirmIcon} style={{ fontSize: 62 }}></i>
            </div>
          </div>

          <h3 className='mt-4'>{title}</h3>
          <span className='text-muted fw-light'>{message}</span>
        </div>

        <Formik<FormValues>
          enableReinitialize={true}
          initialValues={{ date: new Date() }}
          onSubmit={() => {}}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
          }) => (
            <Form>
              <div className='mt-4'>
                <div className='form-icon right is-invalid'>
                  <Flatpickr
                    className={`form-control form-control-icon ${
                      touched.date && errors.date ? ' is-invalid' : ''
                    }`}
                    id='date'
                    name='date'
                    placeholder='__-__-____'
                    onChange={option => {
                      setFieldValue('date', option[0])
                    }}
                    onBlur={() => {
                      setFieldTouched('date', true)
                    }}
                    value={values.date}
                    options={{
                      dateFormat: 'MM/DD/YYYY',
                      formatDate: date => moment(date).format('MM/DD/YYYY'),
                      maxDate: new Date(),
                      allowInvalidPreload: true,
                    }}
                  />
                  <i className='ri-calendar-event-line text-primary fs-20'></i>
                </div>
              </div>
              <div className='hstack gap-2 justify-content-end mt-4'>
                <Button
                  className='btn-soft-primary w-100'
                  onClick={onClose}
                  active={false}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  className='w-100'
                  onClick={() => onOverride(values.date)}
                  disabled={isSubmitting}
                >
                  {confirmLabel}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default CourseOverrideConfirmationModal
