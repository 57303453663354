import { Dispatch, SetStateAction } from 'react'
import { DocumentFile, TextAnswer } from '../../../../sharedTypes'
import { Socket } from 'socket.io-client'

export interface LessonsProps {
  handleUploadLessons: (files: File[]) => void
  courseId: number
  lessons: ILesson[]
  onReOrder: (result: any) => void
  handleDelete: Dispatch<SetStateAction<ILesson | null>>
}

export interface ILesson {
  id?: number
  title: string
  file:
    | DocumentFile
    | (File & {
        preview: string
        formattedSize: string
      })
    | null
  fileId?: number
  isOpen: boolean
  duration?: number
  progress?: number
  order: number | null
  uniqueId?: string
  cover?: DocumentFile | null
  coverId?: number | null
  knowledgeChecks: IKnowledgeCheck[]
}

export interface LessonProps {
  i: number
  lesson: ILesson
  onDelete: Dispatch<SetStateAction<ILesson | null>>
}

export interface LessonItemData {
  [lessonId: number]: number
}

export interface LessonData {
  id: number
  fileId: number | null
}

export interface Pause {
  buttonText: string
}

export interface ReflectionPoint {
  description: string
  buttonText: string
}

export interface MultipleChoice {
  question: string
  explanation: string
  answers: TextAnswer[]
}

export interface BooleanQuestion {
  question: string
  explanation: string
  answer: { correct: boolean }
}

export interface UpdateLessonDto {
  duration?: number
  fileId?: number
  title?: string
  order?: number
}

export interface GetPresignedUrl {
  fileType: string
  fileSize: number
  fileName: string
}

export interface IKnowledgeCheck {
  id?: number
  isOpen?: boolean
  timestamp: string | number
  type: KnowledgeCheckTypes
  data: Pause | MultipleChoice | ReflectionPoint | BooleanQuestion
}

export enum KnowledgeCheckTypes {
  MULTIPLE_CHOICE = 'Multiple Choice',
  TRUE_FALSE = 'True/False',
  REFLECTION_POINT = 'Reflection Point',
  PAUSE = 'Pause',
}

export interface KnowledgeCheckToDelete {
  index: number
  item: IKnowledgeCheck | null
}

export interface KnowledgeChecksProps {
  item: IKnowledgeCheck
  i: number
  onDelete: Dispatch<SetStateAction<KnowledgeCheckToDelete>>
  onEdit: (data: IKnowledgeCheck) => void
}

export const options = [
  {
    label: KnowledgeCheckTypes.MULTIPLE_CHOICE,
    value: KnowledgeCheckTypes.MULTIPLE_CHOICE,
  },
  {
    label: KnowledgeCheckTypes.TRUE_FALSE,
    value: KnowledgeCheckTypes.TRUE_FALSE,
  },
  {
    label: KnowledgeCheckTypes.PAUSE,
    value: KnowledgeCheckTypes.PAUSE,
  },
  {
    label: KnowledgeCheckTypes.REFLECTION_POINT,
    value: KnowledgeCheckTypes.REFLECTION_POINT,
  },
]

export const KNOWLEDGE_CHECK_HEADERS = {
  [KnowledgeCheckTypes.PAUSE]: 'P',
  [KnowledgeCheckTypes.MULTIPLE_CHOICE]: 'MC',
  [KnowledgeCheckTypes.TRUE_FALSE]: 'T/F',
  [KnowledgeCheckTypes.REFLECTION_POINT]: 'RP',
}
