import React from 'react'
import {
  CoursesCount,
  IColumn,
  UserCourseStatus,
  UserDetails,
  UserId,
  UserRegistered,
  UserStatus,
} from '../../../Components/Common/UsersTable'
import { UsersSortBy } from '../../../sharedTypes'
import moment from 'moment'
import { ReactComponent as NotStartedIcon } from '../../../assets/images/svg/not-started.svg'
import { ReactComponent as InProgressIcon } from '../../../assets/images/svg/in-progress.svg'
import { ReactComponent as CoursesCompletedIcon } from '../../../assets/images/svg/completed.svg'
import { ReactComponent as OverdueIcon } from '../../../assets/images/svg/overdue.svg'
import { ReactComponent as RegisteredIcon } from '../../../assets/images/svg/registered.svg'

export enum COURSES_NOT_STARTED {
  ZERO = '0',
  BETWEEN_ONE_AND_THREE = '1-3',
  BETWEEN_FOUR_FIX = '4-6',
  BETWEEN_SEVEN_AND_NINE = '7-9',
  MORE_THAN_TEN = '10+',
}

export enum COURSES_IN_PROGRESS {
  ZERO = '0',
  BETWEEN_ONE_AND_THREE = '1-3',
  MORE_THAN_FOUR = '4+',
}

export enum COURSES_COMPLETED {
  ZERO = '0',
  BETWEEN_ONE_AND_FIVE = '1-5',
  BETWEEN_SIX_AND_TEN = '6-10',
  MORE_THAN_ELEVEN = '11+',
}

export enum COURSES_OVERDUE {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  MORE_THAN_FOUR = '4+',
}

export const coursesNotStarted = [
  { value: COURSES_NOT_STARTED.ZERO, label: '0' },
  { value: COURSES_NOT_STARTED.BETWEEN_ONE_AND_THREE, label: '1-3' },
  { value: COURSES_NOT_STARTED.BETWEEN_FOUR_FIX, label: '4-6' },
  { value: COURSES_NOT_STARTED.BETWEEN_SEVEN_AND_NINE, label: '7-9' },
  { value: COURSES_NOT_STARTED.MORE_THAN_TEN, label: '10 or more' },
]

export const coursesInProgress = [
  { value: COURSES_IN_PROGRESS.ZERO, label: '0' },
  { value: COURSES_IN_PROGRESS.BETWEEN_ONE_AND_THREE, label: '1-3' },
  { value: COURSES_IN_PROGRESS.MORE_THAN_FOUR, label: '4 or more' },
]

export const coursesCompleted = [
  { value: COURSES_COMPLETED.ZERO, label: '0' },
  { value: COURSES_COMPLETED.BETWEEN_ONE_AND_FIVE, label: '1-5' },
  { value: COURSES_COMPLETED.BETWEEN_SIX_AND_TEN, label: '6-10' },
  { value: COURSES_COMPLETED.MORE_THAN_ELEVEN, label: '11 or more' },
]

export const coursesOverdue = [
  { value: COURSES_OVERDUE.ZERO, label: '0' },
  { value: COURSES_OVERDUE.ONE, label: '1' },
  { value: COURSES_OVERDUE.TWO, label: '2' },
  { value: COURSES_OVERDUE.THREE, label: '3' },
  { value: COURSES_OVERDUE.MORE_THAN_FOUR, label: '4 or more' },
]

export const Columns: IColumn[] = [
  {
    title: 'ID',
    id: 'id',
    sortBy: UsersSortBy.ID,
    component: UserId,
    style: { width: 80 },
  },
  {
    title: 'User',
    id: 'user',
    sortBy: UsersSortBy.LASTNAME,
    component: UserDetails,
    style: { width: 220 },
  },
  {
    title: 'Department',
    id: 'department',
    selector: 'departments.[0].name',
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  {
    title: 'Agency',
    id: 'agency',
    selector: 'agency.name',
    sortBy: UsersSortBy.AGENCY_NAME,
    style: { width: 130, whiteSpace: 'pre-line' },
  },
  // {
  //   title: 'Hire Date',
  //   id:'hire-date',
  //   sortBy: UsersSortBy.HIRE_DATE,
  //   selector: 'hireDate',
  //   style: { width: 100 },
  //   format: (value: string) =>
  //     value ? moment(value).format('MM/DD/YYYY') : '',
  // },
  {
    title: 'Registration Date',
    id: 'registration-date',
    sortBy: UsersSortBy.REGISTRATION_DATE,
    selector: 'signupDate',
    style: { width: 100 },
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    component: CoursesCount,
    title: <NotStartedIcon />,
    tooltip: 'Courses Not Started',
    id: 'courses-not-started',
    style: { width: 24, whiteSpace: 'pre-line', minWidth: 24 },
    sortBy: UsersSortBy.COURSES_NOT_STARTED,
    selector: 'reportData.coursesNotStarted',
  },
  {
    component: CoursesCount,
    title: <InProgressIcon />,
    tooltip: 'Courses In Progress',
    id: 'courses-in-progress',
    style: { width: 24, whiteSpace: 'pre-line', minWidth: 24 },
    sortBy: UsersSortBy.COURSES_IN_PROGRESS,
    selector: 'reportData.coursesInProgress',
  },
  {
    component: CoursesCount,
    title: <CoursesCompletedIcon />,
    tooltip: 'Courses Completed in Past 12 Months',
    id: 'courses-past-year',
    style: { width: 24, whiteSpace: 'pre-line', minWidth: 24 },
    sortBy: UsersSortBy.COURSES_COMPLETED_IN_PAST_12_MONTHS,
    selector: 'reportData.coursesCompletedInPast12Months',
  },
  {
    component: CoursesCount,
    title: <OverdueIcon />,
    tooltip: 'Courses Overdue',
    id: 'courses-overdue',
    style: { width: 24, whiteSpace: 'pre-line' },
    sortBy: UsersSortBy.COURSES_OVERDUE,
    selector: 'reportData.coursesOverdue',
  },
  {
    component: UserCourseStatus,
    title: 'Status',
    id: 'courses-status',
    style: { width: 150 },
    sortBy: UsersSortBy.COURSE_STATUS,
  },
  // {
  //   component: UserStatus,
  //   title: 'Active',
  //   style: { width: 120 },
  //   sortBy: UsersSortBy.STATUS,
  // },
  {
    component: UserRegistered,
    title: <RegisteredIcon />,
    tooltip: 'Filter by Registered Status',
    id: 'registered',
    style: { width: 24, whiteSpace: 'pre-line', minWidth: 24 },
    sortBy: UsersSortBy.REGISTERED,
  },
]

export interface SelectedFilters {
  positions: string[]
  departments: string[]
  facilities?: string[]
  agencies: string[]
}

export enum FILTERS {
  COURSES_NOT_STARTED = 'coursesNotStarted',
  COURSES_IN_PROGRESS = 'coursesInProgress',
  FACILITY = 'facility',
  COURSES_COMPLETED = 'coursesCompleted',
  COURSES_OVERDUE = 'coursesOverdue',
  REGISTERED_STATUS = 'registeredStatuses',
  REGISTRATION_DATE = 'signupDate',
  HIRE_DATE = 'hireDate',
  TABS = 'tabs',
}
