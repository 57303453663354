import { PaginationRequest, PaginatedBaseResponse } from './pagination'
import { LiveCourseSettings, TFacility, TUser } from '../models'
import { TCoursePackage } from '../models/coursePackage'

export enum ScheduledCourseTypes {
  IN_PERSON = 'in_person',
  WEBINAR = 'webinar',
}

export enum RecurringTypes {
  ONE_TIME = 'one_time',
  WEEKLY = 'weekly',
}

export enum ScheduledEntitiesTypes {
  COURSE = 'course',
  TRAINING_DAY = 'trainingDay',
}

export enum ScheduledCourseSortBy {
  ID = 'id',
  TYPE = 'type',
  DATE = 'startDate',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  REPORT_TYPE = 'recurring',
  FACILITY = 'facility',
  MEETING_LINK = 'meetingLink',
}

export type ScheduledCourseInitialValues = {
  id?: number
  startDate: string | undefined
  startTime: string | undefined | Date
  endTime: string | undefined | Date
  type: ScheduledCourseTypes
  recurring: RecurringTypes
  facilityId: number | undefined | null
  location: string
  facilitatorId: number | undefined | null
  recurringEnd: string | undefined
}

export interface ScheduledCourseItem {
  id: number
  type: ScheduledCourseTypes
  recurring: RecurringTypes
  entityId: number
  entityType: ScheduledEntitiesTypes
  location: string
  facilityId: number
  facility: TFacility
  facilitatorId: number
  facilitator?: TUser
  startDate: string
  startTime: string
  endTime: string
  recurringEnd: string
  meetingLink: string
  isAssigned?: boolean
  liveCourse: LiveCourseSettings
  trainingDay: TCoursePackage
}

export namespace GetScheduledCourseDTO {
  export interface Request extends PaginationRequest {
    sortBy?: ScheduledCourseSortBy
    entityId: number
    entityType: ScheduledEntitiesTypes
  }

  export interface Response extends PaginatedBaseResponse {
    scheduledEntities: ScheduledCourseItem[]
  }
}

export namespace PostScheduledCourseDTO {
  export type Response = ScheduledCourseItem
  export type Request = ScheduledCourseItem
}

export namespace GetScheduledCourseOptionsDTO {
  export interface Request {
    type: ScheduledCourseTypes
    entityType: ScheduledEntitiesTypes
    facilityId?: number
    daysLimit?: number
  }

  export type Response = ScheduleItem[]
}

export interface ScheduleItem {
  id: number
  date: string
}
