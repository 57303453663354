import BreadCrumb from '../../../Components/Common/BreadCrumb'
import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import { SearchInput } from '../../../Components/Common/SearchInput'
import {
  ASSIGNMENT_STATUS,
  OrderType,
  ReportingPermissions,
  TAssignment,
  UserManagementPermissions,
} from '../../../sharedTypes'
import FilterTabs from '../../../Components/Common/FilterTabs'
import {
  CoursesAssignmentsSortBy,
  GetCoursesAssignmentsDTO,
} from '../../../sharedTypes/api/courseHistory'
import ColumnSortIcon from '../../../Components/Common/ColumnSortIcon'
import NoResultTableWrapper from '../../../Components/Common/NoResultTableWrapper'
import { getCoursesAssignments } from '../../../helpers/api/assignments'
import { handleError } from '../../../helpers/toast_helper'
import Highlighter from 'react-highlight-words'
import { BadgeColorByStatus } from '../../../helpers/course_history'
import moment from 'moment'
import { Pagination } from '../../../Components/Common/Pagination'
import UserCell from '../../../Components/Common/UserCellWithDetails'
import Filters from './Filters'
import {
  ASSIGNMENT_FILTER_TYPES,
  EXPORT_TYPES,
  FILTERS,
  SelectedFilters,
} from './types'
import ExportAssignmentsModal, {
  REPORT_TYPES,
} from '../../../Components/Modals/ExportAssignmentsModal'
import {
  getAssignmentStatus,
  getTestAttempts,
} from '../../../helpers/assignments'
import { onPrint } from '../../../helpers/common'
import _ from 'lodash'
import { useAppSelector } from '../../../hooks/redux'
import UserAssociations from '../../../Components/Common/UserAssociations'
import { useSearchParams } from 'react-router-dom'
import ItemsPerPageDropdown from '../../../Components/Common/ItemsPerPageDropdown'
import { ReactComponent as InProgressIcon } from '../../../assets/images/svg/in-progress.svg'
import { ReactComponent as TestAttemptsIcon } from '../../../assets/images/svg/test-attempts.svg'
import { ReactComponent as OverdueIcon } from '../../../assets/images/svg/overdue.svg'
import { usePermissions } from '../../../hooks/usePermissions'

const tabs = [
  { label: 'All', value: undefined },
  { label: 'Overdue', value: ASSIGNMENT_FILTER_TYPES.OVERDUE },
  {
    label: 'No More Attempts',
    value: ASSIGNMENT_FILTER_TYPES.NO_MORE_ATTEMPTS,
  },
]

const Columns = [
  {
    id: 'user',
    name: 'User',
    sortBy: CoursesAssignmentsSortBy.USER,
    style: { width: 220 },
  },
  {
    id: 'department',
    name: 'Department',
    style: { flex: 1, width: window.innerWidth > 1440 ? 245 : 'auto' },
  },
  {
    id: 'agency',
    name: 'Agency',
    sortBy: CoursesAssignmentsSortBy.AGENCY_NAME,
    style: { flex: 1, width: window.innerWidth > 1440 ? 245 : 'auto' },
  },
  {
    id: 'code',
    name: 'Code',
    sortBy: CoursesAssignmentsSortBy.CODE,
  },
  {
    id: 'package',
    name: 'Package',
    sortBy: CoursesAssignmentsSortBy.PACKAGE,
    style: {
      display: window.innerWidth > 1440 ? 'table-cell' : 'none',
    },
  },
  {
    id: 'course',
    name: 'Course',
    sortBy: CoursesAssignmentsSortBy.COURSE,
  },
  {
    id: 'progress',
    name: <InProgressIcon />,
    tooltip: 'Progress',
    sortBy: CoursesAssignmentsSortBy.PROGRESS,
    style: {
      width: 24,
      whiteSpace: 'pre-line',
      minWidth: 24,
    } as CSSProperties,
  },
  {
    id: 'status',
    name: 'Status',
    sortBy: CoursesAssignmentsSortBy.STATUS,
  },
  {
    id: 'completed',
    name: 'Completed',
    sortBy: CoursesAssignmentsSortBy.COMPLETED,
    style: { width: 70 },
  },
  {
    id: 'course-overdue',
    name: <OverdueIcon />,
    tooltip: 'Courses Overdue',
    sortBy: CoursesAssignmentsSortBy.DAYS_OVERDUE,
    style: {
      width: 24,
      whiteSpace: 'pre-line',
      minWidth: 24,
    } as CSSProperties,
  },
  {
    id: 'test-attempts',
    name: <TestAttemptsIcon />,
    tooltip: 'Test Attempts',
    sortBy: CoursesAssignmentsSortBy.TEST_ATTEMPTS,
    style: {
      width: 24,
      whiteSpace: 'pre-line',
      minWidth: 24,
    } as CSSProperties,
  },
]

const CourseHistory = () => {
  document.title = 'Course History | Mastered - Admin & Dashboard'

  const admin = useAppSelector(state => state.User.user)
  const { groupOptions } = useAppSelector(state => state.FacilityOptions)

  const [showFilters, setShowFilters] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState(false)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const [exportModal, setExportModal] = useState<EXPORT_TYPES | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    positions: [],
    facilities: [],
    groups: [],
    departments: [],
    courses: [],
  })
  const [searchParams] = useSearchParams()

  const tab = searchParams.get(FILTERS.TAB) ? searchParams.get(FILTERS.TAB) : ''
  const sortParam = searchParams.get(FILTERS.SORT_BY)
    ? searchParams.get(FILTERS.SORT_BY)
    : ''

  const type: ASSIGNMENT_FILTER_TYPES | undefined =
    tab &&
    Object.values(ASSIGNMENT_FILTER_TYPES).includes(
      tab as ASSIGNMENT_FILTER_TYPES,
    )
      ? (tab as ASSIGNMENT_FILTER_TYPES)
      : undefined

  const sortBy: CoursesAssignmentsSortBy = Object.values(
    CoursesAssignmentsSortBy,
  ).includes(sortParam as CoursesAssignmentsSortBy)
    ? (sortParam as CoursesAssignmentsSortBy)
    : CoursesAssignmentsSortBy.USER

  const hasPermission = usePermissions(
    UserManagementPermissions.VIEW_USER_PROFILE,
  )

  const orderBy: OrderType =
    searchParams.get(FILTERS.ORDER_BY) === OrderType.DESC
      ? OrderType.DESC
      : OrderType.ASC

  const facilitiesParam = searchParams.get(FILTERS.FACILITY)
  const facilities = facilitiesParam ? JSON.parse(facilitiesParam) : ''
  const facilityIds = groupOptions
    .filter(item => {
      return (
        facilities.includes(item.label) &&
        item.value.split(':')[0] === 'facility'
      )
    })
    .map(item => +item.value.split(':')[1])

  const [query, setQuery] = useState<GetCoursesAssignmentsDTO.Request>({
    page: 1,
    limit: 10,
    sortBy,
    orderBy,
    permission: ReportingPermissions.VIEW_COURSE_HISTORY_REPORT,
    facilityIds: [],
    type,
  })

  const [data, setData] = useState<GetCoursesAssignmentsDTO.Response>({
    page: 0,
    count: 0,
    pages: 0,
    assignments: [],
  })

  useEffect(() => {
    if (fetchData) {
      setIsLoading(true)
      const _query = _.omitBy(query, _.isNil)
      if (!_.isEmpty(_query.completedDate)) {
        _query.completedDate = [
          moment(_query.completedDate[0]).format('YYYY-MM-DD'),
          moment(_query.completedDate[1]).format('YYYY-MM-DD'),
        ]
      }
      if (query.facilityIds?.length !== facilityIds.length) {
        _query.facilityIds = facilityIds
      }
      getCoursesAssignments(_query as GetCoursesAssignmentsDTO.Request)
        .then(d => d.data)
        .then(res => {
          setData(res)
        })
        .catch(e => {
          handleError(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [query, fetchData])

  const handleSort = useCallback((column: CoursesAssignmentsSortBy) => {
    setQuery(prev => ({
      ...prev,
      sortBy: column,
      orderBy: prev.orderBy === OrderType.ASC ? OrderType.DESC : OrderType.ASC,
    }))
  }, [])

  const handleRowClick = (id: number) => {
    if (hasPermission) {
      window.open(`/user-listing/profile/${id}`, '_blank')
    }
  }

  const getDaysOverdueBadgeColor = (daysOverdue: number) =>
    daysOverdue > 1 ? 'red' : 'soft-grey'

  const getTestAttemptsBadgeColor = (assignment: TAssignment) =>
    assignment.totalCompetencyTestTries >= assignment.competencyTestMaxTries &&
    assignment.status !== ASSIGNMENT_STATUS.COMPLETED
      ? 'red'
      : 'soft-grey'
  const widthCondition = window.innerWidth > 1440

  return (
    <Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title={'Course History Report'}
            items={[
              {
                active: true,
                title: 'Reporting',
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className='hstack gap-3 px-3 mx-n3 justify-content-between flex-wrap'>
                    <SearchInput
                      style={{ maxWidth: 400 }}
                      onChange={key => {
                        setQuery(prev => ({ ...prev, key, page: 1 }))
                      }}
                      value={query.key}
                    />
                    <FilterTabs
                      tabs={tabs}
                      navTab={query.type}
                      navToggle={type => {
                        setQuery(prev => ({ ...prev, type, page: 1 }))
                      }}
                    />
                    <div className='d-flex flex-shrink-0 gap-3'>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => setShowFilters(v => !v)}
                      >
                        <i className='ri-equalizer-fill me-1 fs-16'></i>
                        {showFilters ? 'Hide ' : 'Show '}
                        Filters
                      </Button>
                      <Dropdown
                        isOpen={exportDropdownOpen}
                        toggle={() => {
                          setExportDropdownOpen(prevState => !prevState)
                        }}
                      >
                        <DropdownToggle
                          className=' btn btn-secondary align-middle'
                          tag='button'
                        >
                          <i className='mdi mdi-file-export-outline me-1 fs-16'></i>
                          Export
                        </DropdownToggle>
                        <DropdownMenu end>
                          {Object.values(EXPORT_TYPES).map(type => (
                            <DropdownItem key={type}>
                              <span
                                className={`mx-2 ${
                                  type === EXPORT_TYPES.PDF
                                    ? 'text-green-500'
                                    : ''
                                }`}
                                onClick={() => setExportModal(type)}
                              >
                                Export as {type}
                              </span>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className='btn-soft-primary align-middle'
                        onClick={() => onPrint('course-history-section')}
                      >
                        <i className='ri-printer-line me-1 fs-16'></i>
                      </Button>
                    </div>
                  </div>

                  <Row
                    className={`filters-row gap-4 ${
                      showFilters ? 'my-3' : 'my-0'
                    } mx-0`}
                  >
                    <Filters
                      visible={showFilters}
                      setFilters={data => {
                        setQuery(prev => ({
                          ...prev,
                          facilityIds: data.facility,
                          agencyIds: data.agencies,
                          groupIds: data.group,
                          positionIds: data.position,
                          departmentIds: data.department,
                          courseIds: data.course,
                          completedDate: data.completedDate,
                          status: data.status,
                          page: 1,
                        }))
                        if (!fetchData) {
                          setFetchData(true)
                        }
                      }}
                      filterBy={{
                        status: true,
                        courseType: false,
                      }}
                      setSelectedFilters={setSelectedFilters}
                    />
                  </Row>
                </CardHeader>

                <NoResultTableWrapper
                  isLoading={isLoading}
                  isFiltering={!!query.key}
                  pages={data.pages}
                >
                  <div className='table-card'>
                    <div className='overflow-auto'>
                      <Table
                        className='align-middle mb-0'
                        id='course-history-section'
                      >
                        <thead className='table-light'>
                          <tr className='text-muted fs-14'>
                            {Columns.map(column => (
                              <th
                                scope='col'
                                className='align-middle'
                                style={column.style}
                                key={column.id}
                              >
                                {!!column.tooltip && (
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'user-table-column' + column.id}
                                    style={{ minWidth: 300 }}
                                    autohide={false}
                                  >
                                    {column.tooltip}
                                  </UncontrolledTooltip>
                                )}
                                <div
                                  className='d-flex align-items-center'
                                  id={'user-table-column' + column.id}
                                >
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <span className='header-name'>
                                      {column.name}
                                    </span>
                                    {!!column.sortBy && (
                                      <ColumnSortIcon<CoursesAssignmentsSortBy>
                                        sortOrder={query.orderBy}
                                        sortedColumn={query.sortBy}
                                        column={column.sortBy}
                                        handleSort={handleSort}
                                      />
                                    )}{' '}
                                  </div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.assignments.map((assignment, i) => (
                            <tr
                              key={i}
                              onClick={() => handleRowClick(assignment.user.id)}
                              className={`fs-14 ${
                                hasPermission ? 'cursor-pointer' : ''
                              }`}
                            >
                              <UserCell
                                hidePhoto={!widthCondition}
                                user={assignment.user}
                                globalSearch={query.key || ''}
                                displayFacility={
                                  !admin?.isFacilityAdmin ||
                                  admin?.hasAccessToMultipleFacilities
                                }
                              />

                              <UserAssociations
                                data={assignment.user.departments || []}
                                key={query.key || ''}
                              />

                              <td
                                className='text-break'
                                style={{
                                  whiteSpace: widthCondition
                                    ? 'normal'
                                    : 'nowrap',
                                }}
                              >
                                {assignment.user.agency
                                  ? assignment.user.agency.name
                                  : ''}
                              </td>

                              <td>
                                <Highlighter
                                  highlightClassName='text-highlight'
                                  searchWords={[query.key || '']}
                                  className={'text-muted'}
                                  highlightTag={'span'}
                                  autoEscape={true}
                                  textToHighlight={assignment.code}
                                />
                              </td>
                              {widthCondition && (
                                <td>
                                  <span className='text-muted'>
                                    {assignment.package ? (
                                      <Highlighter
                                        highlightClassName='text-highlight'
                                        searchWords={[query.key || '']}
                                        highlightTag={'span'}
                                        autoEscape={true}
                                        textToHighlight={
                                          assignment.package.code
                                        }
                                      />
                                    ) : (
                                      '-'
                                    )}
                                  </span>
                                </td>
                              )}
                              <td style={{ maxHeight: 70 }}>
                                <span className={'two-line-limit'}>
                                  <Highlighter
                                    highlightClassName='text-highlight'
                                    searchWords={[query.key || '']}
                                    highlightTag={'span'}
                                    autoEscape={true}
                                    textToHighlight={
                                      assignment.course?.translations[0].content
                                        .name ||
                                      assignment.package?.name ||
                                      '-'
                                    }
                                  />
                                </span>
                              </td>
                              <td>
                                <div className='course-progress d-flex gap-1 align-items-center'>
                                  <span className='text-muted fs-13'>
                                    {assignment.progress + '%'}
                                  </span>
                                  {widthCondition && (
                                    <Progress
                                      style={{ height: 5, width: 60 }}
                                      color='primary'
                                      value={assignment.progress}
                                    />
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-${
                                    BadgeColorByStatus[assignment.status]
                                  } fs-12 fw-normal text-capitalize`}
                                >
                                  {getAssignmentStatus(assignment.status)}
                                </span>
                              </td>
                              <td>
                                <span className='text-muted'>
                                  {assignment.status ===
                                  ASSIGNMENT_STATUS.COMPLETED
                                    ? moment(assignment.endDate).format(
                                        'MM/DD/YYYY, hh:MM A',
                                      )
                                    : '-'}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-${getDaysOverdueBadgeColor(
                                    assignment.daysOverdue,
                                  )} fs-12 text-capitalize`}
                                >
                                  {assignment.daysOverdue}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-${getTestAttemptsBadgeColor(
                                    assignment,
                                  )} fs-12 text-capitalize`}
                                >
                                  {getTestAttempts(assignment)}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className='mx-3 my-3'>
                      <ItemsPerPageDropdown
                        limit={query.limit ? query.limit : 10}
                        onChange={limit =>
                          setQuery(prev => ({ ...prev, limit: +limit.label }))
                        }
                      />
                      <Pagination
                        currentPage={data.page - 1}
                        totalPages={data.pages}
                        totalRecords={data.count}
                        setPage={page => {
                          setQuery(prev => ({ ...prev, page: ++page }))
                        }}
                        limit={query.limit}
                      />
                    </div>
                  </div>
                </NoResultTableWrapper>
              </Card>
            </Col>
          </Row>
        </Container>
        {exportModal && (
          <ExportAssignmentsModal
            onClose={() => setExportModal(null)}
            isOpen={!!exportModal}
            exportType={exportModal}
            query={query}
            selectedFilters={selectedFilters}
            reportType={REPORT_TYPES.COURSE_HISTORY}
          />
        )}
      </div>
    </Fragment>
  )
}

export default CourseHistory
