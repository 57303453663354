import React, { useEffect, useState } from 'react'
import { Button, Card, Col, CardBody, CardHeader, Row } from 'reactstrap'

import {
  FacilityManagementPermissions,
  GetUsersDTO,
  OrderType,
  TFacility,
  TUser,
  USER_STATUSES,
  UsersSearchSortBy,
  UsersSortBy,
} from '../../../sharedTypes'
import UsersTable, {
  Departments,
  IColumn,
  UserCourseStatus,
  UserInfo,
} from '../../../Components/Common/UsersTable'
import { getUsers } from '../../../helpers/api/users'
import { SearchInput } from '../../../Components/Common/SearchInput'
import moment from 'moment'
import { handleError } from '../../../helpers/toast_helper'
import Filters from '../../UserManagment/Filters'

const Columns: IColumn[] = [
  {
    title: 'User',
    id: 'user',
    sortBy: UsersSortBy.USER,
    component: UserInfo,
  },
  {
    title: 'Department',
    id: 'department',
    component: Departments,
  },
  {
    title: 'Position',
    id: 'position',
    sortBy: UsersSortBy.POSITION_NAME,
    selector: 'position.name',
  },
  {
    title: 'Hire Date',
    id: 'hire-date',
    sortBy: UsersSortBy.HIRE_DATE,
    selector: 'hireDate',
    format: (value: string) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    component: UserCourseStatus,
    title: 'Status',
    id: 'status',
    style: { width: 150 },
    sortBy: UsersSortBy.COURSE_STATUS,
  },
]

interface UserInfoProps {
  facility: TFacility
}
const FacilityEmployees = ({ facility }: UserInfoProps) => {
  const [users, setUsers] = useState<TUser[]>([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [globalSearch, setSearch] = useState<string>('')

  const [showFilters, setShowFilters] = useState(false)
  const [sortedColumn, setSortedColumn] = useState<UsersSortBy | null>(null)
  const [sortOrder, setSortOrder] = useState<OrderType>(OrderType.ASC)

  const handleSort = (column: UsersSortBy) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === OrderType.ASC ? OrderType.DESC : OrderType.ASC)
    } else {
      setSortedColumn(column)
      setSortOrder(OrderType.ASC)
    }
  }

  //Changed order, need to re-fetch users, keep same page
  useEffect(() => {
    fetchUsers()
  }, [sortedColumn, sortOrder])

  useEffect(() => {
    fetchUsers(0)
  }, [globalSearch])

  const onPageChanged = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage)
    }
  }

  const [query, setQuery] = useState<GetUsersDTO.Request>({
    departmentIds: [],
    positionIds: [],
    status: [USER_STATUSES.ACTIVE],
    registeredStatuses: undefined,
  })

  useEffect(() => {
    fetchUsers(page)
  }, [page, query])

  const fetchUsers = (_page?: number) => {
    const params: GetUsersDTO.Request = {
      page: typeof _page === 'number' ? _page + 1 : page + 1,
      limit,
      key: globalSearch ? globalSearch : undefined,
      facilityIds: [facility.id],
      permission: FacilityManagementPermissions.VIEW_FACILITY_EMPLOYEES,
      ...query,
    }

    if (sortedColumn) {
      params.sortBy = sortedColumn
      params.orderBy = sortOrder
    }

    getUsers(params)
      .then(res => {
        setUsers(res.data.users)
        setTotalPages(res.data.pages)
        setTotalUsers(res.data.count)
        onPageChanged(res.data.page - 1)
      })
      .catch(handleError)
  }

  return (
    <Col>
      <Card>
        <CardHeader>
          <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
            <div className='flex-grow-1'>
              <p className='fs-16 mb-0' style={{ color: '#7C8193' }}>
                Employees ({totalUsers})
              </p>
            </div>
            <SearchInput
              style={{ maxWidth: 400 }}
              onChange={setSearch}
              value={globalSearch}
            />
            <div className='d-flex flex-shrink-0 gap-3'>
              <Button
                className='btn-soft-primary align-middle'
                onClick={() => setShowFilters(v => !v)}
              >
                <i className='ri-equalizer-fill me-1 fs-16'></i>
                {showFilters ? 'Hide ' : 'Show '}
                Filters
              </Button>
            </div>
          </div>
          <Row className={`gap-4 ${showFilters ? 'my-3' : 'my-0'} mx-0`}>
            <Filters
              visible={showFilters}
              setFilters={data => {
                setQuery(prev => ({
                  ...prev,
                  page: 1,
                  departmentIds: data.department,
                  positionIds: data.position,
                  status: data.status,
                  registeredStatuses: data.registeredStatuses,
                }))
              }}
              filterBy={{
                facilities: false,
                departments: true,
                positions: true,
                agencies: false,
                groups: false,
                status: true,
                registered: true,
              }}
            />
          </Row>
        </CardHeader>
        <CardBody>
          <UsersTable
            columns={Columns}
            users={users}
            page={page}
            totalPages={totalPages}
            totalUsers={totalUsers}
            onPageChanged={onPageChanged}
            onLimitChange={limit => {
              setLimit(limit)
            }}
            itemsPerPage={limit}
            limit={limit}
            sortOrder={sortOrder}
            sortedColumn={sortedColumn}
            handleSort={
              handleSort as (column: UsersSortBy | UsersSearchSortBy) => void
            }
            globalSearch={globalSearch}
          />
        </CardBody>
      </Card>
    </Col>
  )
}

export default FacilityEmployees
