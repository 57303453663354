import {
  CoursePackageFormatsEnum,
  CoursePackageStatus,
  PackageCourse,
  TCoursePackage,
} from '../models/coursePackage'
import { SelectedCoursesData } from '../../pages/Courses/Packages/types'
import { PaginatedBaseResponse, PaginationRequest } from './pagination'
import { COURSE_TYPES } from '../lib/types'

export interface CoursePackageRequest {
  name: string
  description: string
  type: COURSE_TYPES[]
  disciplineIds: number[]
  format: CoursePackageFormatsEnum
}

export namespace CreateCoursePackageDTO {
  export interface Request extends CoursePackageRequest {}
  export interface Response extends TCoursePackage {}
}

export namespace UpdateCoursePackageDTO {
  export interface Request extends Partial<CoursePackageRequest> {
    isDraft?: boolean
    courses?: SelectedCoursesData[]
  }
  export interface Response extends TCoursePackage {}
}

export namespace GetPackageCoursesDTO {
  export interface Request extends PaginationRequest {
    page: number
    limit: number
    sortBy?: PackageCoursesSortBy
    key?: string
  }
  export interface Response {
    count: number
    packageCourses: PackageCourse[]
    page: number
    pages: number
  }
}

export namespace GetCoursePackagesDTO {
  export interface Request extends PaginationRequest {
    key?: string
    status?: CoursePackageStatus[]
    format?: CoursePackageFormatsEnum
    sortBy?: PackagesSortBy
    type?: COURSE_TYPES[]
    disciplineIds?: number[]
    publishedDate?: Date[]
  }
  export interface Response extends PaginatedBaseResponse {
    count: number
    content: TCoursePackage[]
    page: number
    pages: number
  }
}

export namespace DuplicatePackageDTO {
  export interface Response extends TCoursePackage {}
}

export enum PackageCoursesSortBy {
  CODE = 'courseCode',
  NAME = 'name',
  TYPE = 'type',
  DESCRIPTION = 'description',
  CREDIT_HOURS = 'duration',
  DATE_PUBLISHED = 'publishedDate',
}

export enum PackagesSortBy {
  CODE = 'code',
  NAME = 'name',
  DESCRIPTION = 'description',
  DATE_PUBLISHED = 'publishedDate',
  NUM_OF_COURSES = 'coursesCount',
  STATUS = 'status',
  UPDATED_AT = 'updatedAt',
}
